const getMappedValuesByField = formStateForTable => {
  const fields = ['name', 'abbreviation', 'value', 'item', 'siteName', 'packagingTypes', 'number'];
  return fields.reduce((acc, field) => {
    let mappedTruthyValues = [];

    if (formStateForTable && Array.isArray(formStateForTable)) {
      mappedTruthyValues = formStateForTable && formStateForTable.reduce((arr, entry) => {
        if (entry[field] && entry[field].trim() !== '') {
          arr.push(entry[field].toLowerCase().trim());
        }
        return arr;
      }, []);
    }

    acc[field] = mappedTruthyValues || [];
    return acc;
  }, {});
};

export default getMappedValuesByField;
