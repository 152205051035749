import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CircleLogo = props => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 288 288"
    >
      <rect
        fill="#939598"
        x="11.17"
        y="11.25"
        width="264.83"
        height="264.83"
        rx="132.41"
        ry="132.41"
      />
      <rect
        fill="#fff"
        x="152.38"
        y="194.9"
        width="64.86"
        height="16.9"
      />
      <path
        fill="#231f20"
        d="M143.59,140.85c0,1.54,0,4.61-.17,7.68H83c1.54,7.17,9.9,16.38,22.69,16.38,14.16,0,22.18-8.19,22.18-8.19l10.24,15.36a51.79,51.79,0,0,1-32.42,11.6c-24.57,0-43.51-18.77-43.51-42.48s17.23-42.48,40.61-42.48C125,98.71,143.59,115.94,143.59,140.85Zm-20.47-7.16c-.34-7-6.82-16.38-20.3-16.38-13.14,0-19.11,9.21-19.79,16.38Z"
      />
      <polygon
        fill="#fff"
        points="188.4 100.75 214.85 100.75 179.36 138.97 217.24 181.63 190.28 181.63 152.38 138.57 188.4 100.75"
      />
    </SvgIcon>
  );
};

export default CircleLogo;
