import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Grid,
  Hidden,
  Link,
  MobileStepper,
  Step,
  Stepper,
  StepButton,
  StepLabel,
  CircularProgress,
  withStyles,
} from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';

import { STEP_KEYS, formPropType } from '../constants';

import EkosStep from '../Components/StepComponents/EkosStep';
import FacilityInformationStep from '../Components/StepComponents/FacilityInformationStep';
import Review from './Review';
import SuccessPage from './SuccessPage';
import * as selectors from './selectors';
import * as actions from './actions';

const Steps = ({
  classes,
  form,
  steps,
  completedSteps,
  startOver,
  emailSending,
  emailSent,
  sendEmail,
  setRetailHasBeenTouched,
  updateEmailSent,
}) => {
  const [activeStep, setStep] = useState(0);
  const [stepWithError, setStepWithError] = useState(false);

  // HOTJAR USABILITY
  const activeStepKey = steps[activeStep].stepKey;
  useEffect(() => {
    const { hj } = window || {};
    hj('stateChange', `${activeStepKey}`);
  }, [activeStepKey]);

  const next = () => setStep(activeStep + 1);
  const back = () => {
    // setStep(activeStep - 1 > 0 ? activeStep - 1 : 0); TO DO: ADD THIS BACK
    /**
     * This if/else was added on OB-120, this fix is NOT intended to be permanent
     * but to only allow a few customers to 'resubmit' their forms
     * without having to fill out again
     */
    const userPressesBackOnSuccesPage = emailSent;
    if (userPressesBackOnSuccesPage) {
      updateEmailSent(false);
      setStep(8); // take user to review page
    } else {
      setStep(activeStep - 1 > 0 ? activeStep - 1 : 0);
    }
  };

  if (emailSent) {
    return <SuccessPage back={back} />;
  }

  const handleStep = idx => () => setStep(idx);

  const goToStepFromReviewLink = stepKey => {
    const newStepIdx = steps.findIndex(step => step.stepKey === stepKey);
    if (newStepIdx > -1) {
      setStep(newStepIdx);
    }
  };
  /**
     * since some form properties are not populated until user enters data
     * if no retail form property exists, we know the retail step has not been touched
     * therefore it should be updated
     */
  if (steps[activeStep].stepKey === STEP_KEYS.RETAIL && !form[STEP_KEYS.RETAIL]) {
    setRetailHasBeenTouched();
  }

  // if there are steps with errors disable submit button
  const getIncompleteStepsFromReviewComponent = incompleteSteps => {
    setStepWithError(incompleteSteps && incompleteSteps.length > 0);
  };

  const getStepDisplay = stepKey => {
    const incompleteSteps = steps.filter(step => (
      !completedSteps.includes(step.stepKey) && step.stepKey !== STEP_KEYS.SUBMIT
    ));

    switch (stepKey) {
      case STEP_KEYS.FACILITY:
        return <FacilityInformationStep step={steps[activeStep]} />;
      case STEP_KEYS.SUBMIT:
        return (
          <Review
            handleStep={goToStepFromReviewLink}
            getIncompleteSteps={getIncompleteStepsFromReviewComponent}
            incompleteSteps={incompleteSteps}
          />
        );
      default:
        return <EkosStep step={steps[activeStep]} />;
    }
  };


  const getNextOrSubmitDisplay = isMobile => {
    const isReviewStep = steps[activeStep].stepKey === STEP_KEYS.SUBMIT;
    const includesFacility = completedSteps.includes(STEP_KEYS.FACILITY);
    const includesImplementation = completedSteps.includes(STEP_KEYS.IMPLEMENTATION);
    const includesRetail = completedSteps.includes(STEP_KEYS.RETAIL);
    const isSubmitDisabled = !includesFacility || !includesImplementation || !includesRetail;

    if (isMobile) {
      if (isReviewStep) {
        return (
          <Button
            size="small"
            onClick={sendEmail}
            disabled={isSubmitDisabled || emailSending || stepWithError}
          >
            {emailSending && !emailSent && (
            <div style={{ marginRight: '6px' }}>
              <CircularProgress size={24} />
            </div>
            )}
            {emailSending ? 'Sending' : 'Submit'}
          </Button>
        );
      }

      return (
        <Button
          size="small"
          onClick={next}
        >
          Next
          <KeyboardArrowRight />
        </Button>
      );
    }

    if (isReviewStep) {
      return (
        <Button
          variant="contained"
          color="primary"
          onClick={sendEmail}
          disabled={isSubmitDisabled || emailSending || stepWithError}
        >
          {emailSending && !emailSent && (
          <div style={{ marginRight: '6px' }}>
            <CircularProgress size={24} />
          </div>
          )}
          {emailSending ? 'Sending' : 'Submit'}
        </Button>
      );
    }

    return (
      <Button
        variant="contained"
        color="primary"
        onClick={next}
      >
        {'Next >'}
      </Button>
    );
  };

  return (
    <Grid
      container
      justify="center"
      className={classes.container}
    >
      <Hidden smDown>
        <Grid
          item
          xs={12}
          sm={10}
        >
          <Stepper
            nonLinear
            activeStep={activeStep}
            alternativeLabel
          >
            {steps.map((step, index) => {
              const isStepComplete = activeStep !== index && completedSteps.includes(step.stepKey);

              return (
                <Step
                  key={step.stepKey}
                >
                  <StepButton
                    onClick={handleStep(index)}
                    completed={isStepComplete}
                    active={index === activeStep}
                  >
                    <StepLabel
                      StepIconProps={{
                        classes: {
                          root: classes.root,
                          active: classes.active,
                          completed: classes.completed,
                        },
                      }}
                    >
                      {step.heading}
                    </StepLabel>
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
        </Grid>
      </Hidden>

      <Grid
        item
        xs={12}
        sm={10}
      >
        {getStepDisplay(steps[activeStep].stepKey)}
      </Grid>

      <Grid
        item
        xs={12}
        md={10}
      >
        <Hidden smDown>
          <Grid
            item
            xs={12}
            className={classes.buttonContainer}
          >
            <Grid
              container
              spacing={1}
              justify="space-between"
            >
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={back}
                  disabled={activeStep === 0 || emailSending}
                >
                  {'< Back'}
                </Button>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  component="button"
                  variant="body2"
                  onClick={startOver}
                  className={classes.startOverLink}
                  disabled={emailSending}
                >
                  Start over
                </Link>
              </Grid>
              <Grid item>
                {getNextOrSubmitDisplay()}
              </Grid>
            </Grid>
          </Grid>
        </Hidden>

        <Hidden mdUp>
          <MobileStepper
            variant="dots"
            steps={steps.length}
            position="bottom"
            activeStep={activeStep}
            className={classes.mobileStepper}
            nextButton={getNextOrSubmitDisplay(true)}
            backButton={(
              <Button
                size="small"
                onClick={back}
                disabled={activeStep === 0 || emailSending}
              >
                <KeyboardArrowLeft />
                Back
              </Button>
                          )}
          />
        </Hidden>
      </Grid>

    </Grid>
  );
};

const styles = theme => ({
  container: {
    padding: '2rem',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
    },
  },
  mobileStepper: {
    flexGrow: 1,
    maxWidth: '800px',
    background: '#fff',
  },
  buttonContainer: {
    marginTop: '2rem',
  },
  // using the in-progress, and completed hex codes from ekos application
  root: {
    '&$active': {
      color: '#1E88E5',
    },
    '&$completed': {
      color: '#8BC34A',
    },
  },
  active: {},
  completed: {},
  startOverLink: {
    color: '#008EFF',
    marginLeft: 25,
  },
});

Steps.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  steps: PropTypes.arrayOf(PropTypes.shape({
    stepKey: PropTypes.string,
    heading: PropTypes.string,
    subheading: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      fieldKey: PropTypes.string,
      type: PropTypes.string,
      required: PropTypes.bool,
    })),
    tables: PropTypes.arrayOf(PropTypes.shape({
      heading: PropTypes.string,
      subheading: PropTypes.string,
      tableKey: PropTypes.string,
      columns: PropTypes.arrayOf(PropTypes.shape({
        header: PropTypes.string,
        fieldKey: PropTypes.string,
        type: PropTypes.string,
        placeholder: PropTypes.string,
      })),
    })),
  })).isRequired,
  startOver: PropTypes.func.isRequired,
  form: PropTypes.shape(formPropType).isRequired,
  completedSteps: PropTypes.arrayOf(PropTypes.string),
  emailSending: PropTypes.bool,
  emailSent: PropTypes.bool,
  sendEmail: PropTypes.func,
  setRetailHasBeenTouched: PropTypes.func,
  updateEmailSent: PropTypes.func,

};

Steps.defaultProps = {
  classes: {},
  completedSteps: [],
  emailSending: false,
  emailSent: false,
  sendEmail: () => {},
  setRetailHasBeenTouched: () => {},
  updateEmailSent: () => {},
};

const mapStateToProps = (state, ownProps) => {
  const { form, emailSending, emailSent } = state;
  const completedSteps = selectors.getCompletedSteps(state, ownProps);

  return {
    form,
    completedSteps,
    emailSending,
    emailSent,
  };
};

const mapDispatchToProps = dispatch => ({
  setRetailHasBeenTouched: () => dispatch(
    actions.changeField(
      STEP_KEYS.RETAIL,
      'hasBeenTouched',
      true,
    ),
  ),
  sendEmail: () => dispatch(actions.sendSetupEmail()),
  updateEmailSent: hasEmailSent => dispatch(actions.updateEmailSent(hasEmailSent)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Steps));
