import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Button,
  ExpansionPanel,
  ExpansionPanelSummary,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  withStyles,
} from '@material-ui/core';
import { AddCircleOutline, ExpandMore } from '@material-ui/icons';

import * as actions from '../Form/actions';

import ComplexMenu from '../Components/MenuComponents/ComplexMenu';
import ReadOnlyPackagingComponents from './ReadOnlyPackagingComponents';
import EditModePackagingComponents from './EditModePackagingComponents';

const ProductsPackagingTypes = props => {
  const {
    classes,
    availablePackagingTypes,
    // props from redux
    selectedProduct,
    selectedProductPackaging,
    updateProductPackagingTypes,
    editModeCallback,
  } = props;

  // need to format the selected packaging for the product to be passed to the ComplexMenu component
  const formatSelectedPackaging = productPackagingTypes => {
    return productPackagingTypes.map(selected => ({
      label: selected.packagingType,
      value: selected.rowguid,
    }));
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [packagingTypeOptions, setPackagingTypeOptions] = useState(availablePackagingTypes);
  const [
    formattedSelectedPackaging,
    setFormattedSelectedPackaging,
  ] = useState(formatSelectedPackaging(selectedProductPackaging));
  const [expandAll, setExpandAll] = useState(false);
  const [expandedPanels, setExpandedPanels] = useState([]);
  const [packagingInEditMode, setPackagingInEditMode] = useState([]);

  useEffect(() => {
    setPackagingTypeOptions(availablePackagingTypes);
  }, [availablePackagingTypes]);

  useEffect(() => {
    const formattedSelected = formatSelectedPackaging(selectedProductPackaging);
    setFormattedSelectedPackaging(formattedSelected);
  }, [selectedProductPackaging]);

  const toggleIsPanelExpanded = panelIdx => {
    const updatedExpandedPanels = [...expandedPanels];

    if (updatedExpandedPanels.includes(panelIdx)) {
      // unset expandAll if it's currently set to true
      if (expandAll) {
        setExpandAll(false);
      }

      const idxToRemove = updatedExpandedPanels.findIndex(idx => idx === panelIdx);
      updatedExpandedPanels.splice(idxToRemove, 1);
    } else {
      updatedExpandedPanels.push(panelIdx);
      if (updatedExpandedPanels.length === selectedProductPackaging.length) {
        setExpandAll(true);
      }
    }

    setExpandedPanels(updatedExpandedPanels);
  };

  const toggleExpandAll = () => {
    if (expandAll) {
      setExpandAll(false);
      setExpandedPanels([]);
    } else {
      const allPanelsExpanded = selectedProductPackaging.map((packaging, idx) => idx);
      setExpandAll(true);
      setExpandedPanels(allPanelsExpanded);
    }
  };

  const selectPackagingType = selectedOption => {
    const packagingComponents = selectedOption.components
      ? selectedOption.components.map(component => ({
        ...component,
        isProductSpecific: false,
      })) : [];

    const selectedPackagingType = {
      packagingType: selectedOption.label,
      rowguid: selectedOption.value,
      packagingComponents,
    };

    const updatedProduct = { ...selectedProduct };

    const packagingTypeIdx = updatedProduct.productPackagingTypes
      .findIndex(type => type.rowguid === selectedPackagingType.rowguid);

    if (packagingTypeIdx < 0) {
      updatedProduct.productPackagingTypes.push(selectedPackagingType);
    } else {
      updatedProduct.productPackagingTypes.splice(packagingTypeIdx, 1);
    }

    const formattedSelected = formatSelectedPackaging(updatedProduct.productPackagingTypes);
    setFormattedSelectedPackaging(formattedSelected);

    updateProductPackagingTypes(updatedProduct);
  };

  // the reason we use filteredPackagingTypes here is because it won't necessarily be all packaging types
  // user may have filtered the packaging types using the search
  const selectAllPackagingTypes = (filteredPackagingTypes, isChecked) => {
    const updatedProduct = { ...selectedProduct };

    if (isChecked) {
      const updatedPackagingTypes = updatedProduct.productPackagingTypes.reduce(
        (acc, packagingType) => {
          if (!filteredPackagingTypes.some(
            filteredType => filteredType.value === packagingType.rowguid,
          )) {
            acc.push(packagingType);
          }

          return acc;
        }, [],
      );

      updatedProduct.productPackagingTypes = updatedPackagingTypes;
    } else {
      filteredPackagingTypes.forEach(selectedOption => {
        const selectedPackagingType = {
          packagingType: selectedOption.label,
          rowguid: selectedOption.value,
          packagingComponents: (selectedOption.components || []).map(component => ({
            ...component,
            isProductSpecific: false,
          })),
        };

        const isDuplicate = updatedProduct.productPackagingTypes
          .findIndex(type => type.rowguid === selectedPackagingType.rowguid) > -1;

        if (!isDuplicate) {
          updatedProduct.productPackagingTypes.push(selectedPackagingType);
        }
      });
    }

    const formattedSelected = formatSelectedPackaging(updatedProduct.productPackagingTypes);
    setFormattedSelectedPackaging(formattedSelected);

    updateProductPackagingTypes(updatedProduct);
  };

  const toggleProductSpecific = (packagingTypeRowguid, componentId, e) => {
    const { checked } = e.target;
    const updatedProduct = { ...selectedProduct };

    updatedProduct.productPackagingTypes.forEach(type => {
      if (type.rowguid === packagingTypeRowguid) {
        type.packagingComponents.forEach(component => {
          if (componentId === component.tempId) {
            // eslint-disable-next-line no-param-reassign
            component.isProductSpecific = checked;
          }
        });
      }
    });

    updateProductPackagingTypes(updatedProduct);
  };

  const toggleEditMode = packagingRowguid => {
    const updatedPackagingInEditMode = [...packagingInEditMode];
    const packagingTypeIdx = updatedPackagingInEditMode
      .findIndex(packaging => packaging === packagingRowguid);

    if (packagingTypeIdx > -1) {
      updatedPackagingInEditMode.splice(packagingTypeIdx, 1);
    } else {
      updatedPackagingInEditMode.push(packagingRowguid);
    }

    editModeCallback(selectedProduct.tempId, updatedPackagingInEditMode.length > 0);

    setPackagingInEditMode(updatedPackagingInEditMode);
  };

  const applyEditMode = updatedPackaging => {
    const updatedProduct = { ...selectedProduct };

    const packagingIdx = updatedProduct.productPackagingTypes
      .findIndex(type => type.rowguid === updatedPackaging.rowguid);
    updatedProduct.productPackagingTypes[packagingIdx] = updatedPackaging;


    updateProductPackagingTypes(updatedProduct);
    toggleEditMode(updatedPackaging.rowguid);
  };

  const packTypeListedForAtLeastProduct = selectedProductPackaging.some(packType => (
    packType.rowguid !== 'userPackTypeNotListed'
  ));

  const isExpandAllButtonDisabled = !packTypeListedForAtLeastProduct
        || (expandAll && packagingInEditMode.length > 0);

  return (
    <div className={classes.productsPackagingContainer}>
      <div className={classes.headerContainer}>
        <Typography
          variant="caption"
          className={classes.label}
        >Packaging Types
        </Typography>
        {selectedProductPackaging.length > 0 && (
        <Button
          variant="outlined"
          onClick={toggleExpandAll}
          disabled={isExpandAllButtonDisabled}
        >
          {expandAll ? 'Collapse All' : 'Expand All'}
        </Button>
        )}
      </div>

      {
                /* have a container div here so that the packaging types menu will anchor to the top of the packaging types display */
            }
      <div>
        {selectedProductPackaging.map((packaging, idx) => {
          const userPackagingTypeNotListed = packaging.rowguid === 'userPackTypeNotListed';
          return (
            <ExpansionPanel
              key={packaging.rowguid}
              classes={{
                root: classes.panelRoot,
                expanded: classes.expanded,
              }}
              expanded={expandAll || expandedPanels.includes(idx)}
              onChange={() => toggleIsPanelExpanded(idx)}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                disabled={(
                  packagingInEditMode.includes(packaging.rowguid)
                  || userPackagingTypeNotListed
                )}
                classes={{
                  root: classes.summaryRoot,
                  content: classes.summaryContent,
                  expanded: classes.expanded,
                }}
              >
                <Typography>{packaging.packagingType}</Typography>
              </ExpansionPanelSummary>

              {packagingInEditMode.includes(packaging.rowguid)
                ? (
                  <EditModePackagingComponents
                    selectedProduct={selectedProduct}
                    packaging={packaging}
                    toggleProductSpecific={toggleProductSpecific}
                    toggleEditMode={toggleEditMode}
                    applyEditMode={applyEditMode}
                  />
                ) : (
                  <ReadOnlyPackagingComponents
                    selectedProduct={selectedProduct}
                    packaging={packaging}
                    toggleProductSpecific={toggleProductSpecific}
                    toggleEditMode={toggleEditMode}
                  />
                )}
            </ExpansionPanel>
          );
        })}

        <ListItem
          button
          className={classes.addButton}
          disabled={
                        !selectedProduct.value
                        || !availablePackagingTypes
                        || availablePackagingTypes.length === 0
                        || packagingInEditMode.length > 0
                    }
          onClick={e => setAnchorEl(e.currentTarget.parentElement)}
        >
          <ListItemIcon className={classes.addIcon}>
            <AddCircleOutline />
          </ListItemIcon>
          <ListItemText primary={`${selectedProductPackaging.length ? 'Update' : 'Select'} Packaging Types`} />
        </ListItem>
      </div>

      <ComplexMenu
        anchorEl={anchorEl}
        allowSearch
        isMultiSelect
        allowSelectAll
        label="Packaging Components"
        selectedOptions={formattedSelectedPackaging}
        options={packagingTypeOptions}
        optionSelectedCallback={selectPackagingType}
        selectAllCallback={selectAllPackagingTypes}
        closeCallback={() => setAnchorEl(null)}
      />
    </div>
  );
};

const styles = theme => ({
  productsPackagingContainer: {
    marginTop: 16,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  label: {
    color: theme.palette.text.secondary,
  },
  panelRoot: {
    '&$expanded': {
      marginTop: 0,
      marginBottom: 8,
    },
  },
  summaryRoot: {
    '&$expanded': {
      minHeight: 48,
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
  },
  summaryContent: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
  packagingComponentsHeader: {
    margin: '8px 0',
  },
  tooltipIcon: {
    color: theme.palette.text.secondary,
    height: 18,
    width: 18,
    marginLeft: 8,
  },
  productSpecificGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
  addButton: {
    padding: '8px 0',
  },
  addIcon: {
    color: 'black',
    marginRight: 5,
    minWidth: 24,
  },
  editButtonContainer: {
    marginTop: 64,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  editButton: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'none',
  },
  editIcon: {
    marginRight: 8,
    height: 20,
    width: 20,
  },
});

/* eslint-disable react/require-default-props */
ProductsPackagingTypes.propTypes = {
  availablePackagingTypes: PropTypes.arrayOf(
    PropTypes.shape({
      packagingTypes: PropTypes.string,
      packagingComponents: PropTypes.arrayOf(PropTypes.shape()),
      packagingTypeRowguid: PropTypes.string,
    }),
  ),
  classes: PropTypes.objectOf(PropTypes.string),
  selectedProduct: PropTypes.shape(),
  selectedProductPackaging: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.shape({}),
  ]),
  updateProductPackagingTypes: PropTypes.func,
  editModeCallback: PropTypes.func,
};
/* eslint-enable react/require-default-props */

const mapStateToProps = (state, ownProps) => {
  const { arrayKey, arrayIndex } = ownProps;

  const selectedProduct = state.form[arrayKey][arrayIndex];

  return {
    selectedProduct,
    selectedProductPackaging: selectedProduct.productPackagingTypes,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateProductPackagingTypes: updatedProduct => dispatch(
    actions.updateRowInArray(
      ownProps.arrayKey,
      ownProps.arrayIndex,
      updatedProduct,
    ),
  ),
});

const styledProductsPackagingTypes = withStyles(styles)(ProductsPackagingTypes);

export { ProductsPackagingTypes as IsolatedProductsPackagingTypes };
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(styledProductsPackagingTypes);
