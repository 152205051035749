import React from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';

import StepHeading from './StepHeading';
import FacilityInformationFields from './FacilityInformationFields';
import FacilityInformationSites from './FacilityInformationSites';
import FacilityInformationUploader from './FacilityInformationUploader';

const FacilityInformationStep = ({ classes, step }) => {
  const { fields, tables } = step;

  return (
    <Grid
      container
      justify="center"
      spacing={2}
    >
      <Grid
        item
        xs={12}
        className={classes.sectionContainer}
      >
        <Grid
          container
          spacing={1}
        >
          <StepHeading step={step} />
          <FacilityInformationFields
            fields={fields}
            stepKey={step.stepKey}
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        className={classes.sectionContainer}
      >
        <FacilityInformationSites
          tableKey={tables[0].tableKey}
          table={tables[0]}
        />
      </Grid>
      <FacilityInformationUploader type="logo" />
      <FacilityInformationUploader type="floorplan" />
    </Grid>
  );
};

FacilityInformationStep.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  step: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape()),
    heading: PropTypes.string,
    stepKey: PropTypes.string,
    subheading: PropTypes.string,
    tables: PropTypes.arrayOf(PropTypes.shape()),
  }),
};

FacilityInformationStep.defaultProps = {
  classes: {},
  step: {},
};

const styles = {
  sectionContainer: {
    marginBottom: 56,
  },
};

export default withStyles(styles)(FacilityInformationStep);
