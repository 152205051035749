/**
 * This config will generate form fields and steps
 */
import { FIELD_TYPES, STEP_KEYS, UOM } from '../constants';

export const facilityStep = {
  heading: 'Facility Information',
  subheading: 'Tell us a little more about your business',
  stepKey: STEP_KEYS.FACILITY,
  fields: [
    {
      label: 'Facility Name',
      fieldKey: 'name',
      type: FIELD_TYPES.TEXT,
      required: true,
    },
    {
      label: 'Phone Number',
      fieldKey: 'phoneNumber',
      type: FIELD_TYPES.TEL,
      required: true,
    },
    {
      label: 'Street Address',
      fieldKey: 'streetAddress',
      type: FIELD_TYPES.TEXT,
      required: true,
    },
    {
      label: 'City',
      fieldKey: 'city',
      type: FIELD_TYPES.TEXT,
      required: true,
    },
    {
      label: 'State/Province',
      fieldKey: 'state',
      type: FIELD_TYPES.TEXT,
      required: true,
    },
    {
      label: 'Zip Code',
      fieldKey: 'zipCode',
      type: FIELD_TYPES.TEXT,
      required: true,
    },
    {
      label: 'Country',
      fieldKey: 'country',
      type: FIELD_TYPES.TEXT,
      required: true,
    },
    {
      label: 'Gravity Unit of Measure',
      fieldKey: 'gravityUom',
      options: 'gravityUom',
      type: FIELD_TYPES.SELECT,
      required: true,
    },
  ],
  tables: [
    {
      heading: 'Sites *',
      subheading: 'Add a site below for each physically separate location you use to track inventory, that is tied to the business above',
      tableKey: 'sites',
      columns: [
        {
          header: 'Site Name',
          fieldKey: 'siteName',
          type: FIELD_TYPES.TEXT,
          placeholder: 'Site Name',
        },
        {
          fieldKey: 'siteId',
          type: FIELD_TYPES.HIDDEN,
        },
      ],
    },
  ],
};

export const implementationStep = {
  heading: 'Implementation',
  subheading:
        'Who will be leading the implementation of ekos from your team?',
  stepKey: STEP_KEYS.IMPLEMENTATION,
  fields: [
    {
      label: 'Contact Name',
      fieldKey: 'contactName',
      type: FIELD_TYPES.TEXT,
      required: true,
    },
    {
      label: 'Preferred Email',
      fieldKey: 'contactEmail',
      type: FIELD_TYPES.TEXT,
      required: true,
      autoComplete: 'email',
    },
  ],
};

export const retailStep = {
  heading: 'Tasting Room',
  subheading: 'Tell us about your tasting room setup',
  stepKey: STEP_KEYS.RETAIL,
  hasBeenTouched: false,
  fields: [
    {
      label: '',
      fieldKey: 'hasBeenTouched',
      type: FIELD_TYPES.HIDDEN,
    },
    {
      label: 'Do you have a tasting room?',
      fieldKey: 'hasRetail',
      type: 'checkbox',
    },
    {
      label: 'Do you pour from conditioning/serving tanks?',
      fieldKey: 'hasBulk',
      type: 'checkbox',
    },
    {
      label: 'Keg deposit amount',
      fieldKey: 'kegDeposit',
      type: FIELD_TYPES.KEG_DEPOSIT,
    },
  ],
};

export const packagingStep = {
  heading: 'Packaging',
  subheading: 'Select the packaging types you use for each product',
  stepKey: STEP_KEYS.PACKAGING,
  tables: [
    {
      label: 'Packaging',
      tableKey: 'packaging',
      hasExample: true,
      columns: [
        {
          header: 'Packaging Type',
          fieldKey: 'packagingTypes',
          type: FIELD_TYPES.PACKAGING_TYPES,
          options: 'packagingTypes',
          allowCustom: true,
          example: [
            {
              value: 'Case - 6x - 750mL - Bottle',
            },
          ],
        },
        {
          header: 'Packaging Components',
          fieldKey: 'packagingComponents',
          type: FIELD_TYPES.PACKAGING_COMPONENTS,
          options: 'packagingComponents',
          allowCustom: true,
          example: [
            {
              value: '750mL Bottle',
            }, {
              value: 'Cork',
            },
          ],
        },
        {
          fieldKey: 'packagingTypeRowguid',
          type: FIELD_TYPES.HIDDEN,
        },
      ],
    },
  ],
};

export const productStep = {
  heading: 'Products',
  subheading: 'Add all of the products that you currently produce',
  stepKey: STEP_KEYS.PRODUCTS,
  tables: [
    {
      label: 'Product',
      tableKey: 'products',
      hasExample: true,
      columns: [
        {
          header: 'Product Name',
          label: 'Product Name',
          fieldKey: 'value',
          type: FIELD_TYPES.TEXT,
          example: 'Estate Cabernet Sauvignon',
          placeholder: 'Product name',
        },
        {
          header: 'Product Class',
          label: 'Product Class',
          fieldKey: 'productClass',
          type: FIELD_TYPES.SELECT,
          options: 'productClass',
          example: 'Wine',
        },
        {
          header: 'Unit of measure',
          label: 'Unit of Measure',
          fieldKey: 'uom',
          type: FIELD_TYPES.SELECT,
          example: 'Gallons',
          options: UOM,
          placeholder: 'UOM',
        },
        {
          header: 'Packaging Types',
          fieldKey: 'productPackaging',
          type: FIELD_TYPES.PRODUCT_PACKAGING,
          options: 'packagingTypes',
          example: [
            'Single - 750mL - Bottle',
            'Case - 12x - 750mL - Bottle',
          ],
        },
      ],
    },
  ],
};

export const equipmentStep = {
  heading: 'Equipment',
  subheading:
    'Enter all your equipment below. Each piece listed should also be on your facility sketch with the same name. Equipment abbreviations are a max of 5 characters - this abbreviation is what you will see on your facility view.',
  stepKey: STEP_KEYS.EQUIPMENT,
  tables: [
    {
      label: 'Equipment',
      tableKey: 'equipment',
      hasExample: true,
      columns: [
        {
          header: 'Name',
          fieldKey: 'name',
          type: FIELD_TYPES.TEXT,
          example: 'Blending Tank 1',
          placeholder: 'Name',
        },
        {
          header: 'Abbreviation',
          fieldKey: 'abbreviation',
          type: FIELD_TYPES.TEXT,
          example: 'BT1',
          placeholder: 'Abbr',
          inputProps: {
            maxLength: '5',
          },
        },
        {
          header: 'Type',
          fieldKey: 'equipmentType',
          type: FIELD_TYPES.SELECT,
          options: 'equipmentTypes',
          example: 'Blending Tank',
        },
        {
          header: 'Capacity',
          fieldKey: 'capacityFields',
          example: '660 gals',
          type: FIELD_TYPES.EQUIPMENT_CAPACITY_FIELD,
          fields: [
            {
              fieldKey: 'capacity',
              type: FIELD_TYPES.NUMBER,
              placeholder: 'Capacity',
            },
            {
              fieldKey: 'maxCapacity',
              type: FIELD_TYPES.NUMBER,
              placeholder: 'Max',
            },
            {
              fieldKey: 'capacityUOM',
              type: FIELD_TYPES.SELECT,
              options: UOM,
              defaultValue: 'gal',
            },
            {
              label: 'Max capacity is same',
              fieldKey: 'maxCapacityIsSame',
              type: FIELD_TYPES.CHECKBOX,
            },
          ],
        },
        {
          header: 'Site',
          fieldKey: 'site',
          example: 'Production Facility',
          type: FIELD_TYPES.SELECT,
        },
        {
          header: 'System',
          fieldKey: 'system',
          example: 'Primary',
          label: 'System',
          type: FIELD_TYPES.COMPLEX_SELECT,
          options: 'equipmentSystems',
          allowCustom: true,
          allowSearch: true,
        },
      ],
    },
    {
      label: 'Barrels',
      tableKey: 'barrels',
      hasExample: true,
      columns: [
        {
          header: 'Number',
          fieldKey: 'number',
          type: FIELD_TYPES.TEXT,
          example: '001',
          placeholder: 'Barrel #',
          inputProps: {
            maxLength: '5',
          },
        },
        {
          header: 'Type',
          fieldKey: 'type',
          type: FIELD_TYPES.TEXT,
          example: 'Oak',
          placeholder: 'Type',
        },
        {
          header: 'Capacity',
          fieldKey: 'capacityFields',
          example: '59 gal',
          type: FIELD_TYPES.EQUIPMENT_CAPACITY_FIELD,
          fields: [
            {
              fieldKey: 'capacity',
              type: FIELD_TYPES.NUMBER,
              placeholder: 'Capacity',
            },
            {
              fieldKey: 'maxCapacity',
              type: FIELD_TYPES.NUMBER,
              placeholder: 'Max Capacity',
            },
            {
              fieldKey: 'capacityUOM',
              type: FIELD_TYPES.SELECT,
              options: UOM,
              defaultValue: 'gal',
            },
            {
              label: 'Max capacity is same',
              fieldKey: 'maxCapacityIsSame',
              type: FIELD_TYPES.CHECKBOX,
            },
          ],
        },
        {
          header: 'Site',
          fieldKey: 'site',
          example: 'Production Facility',
          type: FIELD_TYPES.SELECT,
        },
      ],
    },
  ],
};

export const storageStep = {
  heading: 'Storage Locations',
  subheading:
        'These locations will be the virtual storage locations for your ingredients and finished goods.',
  stepKey: STEP_KEYS.STORAGELOCATIONS,
  tables: [
    {
      label: 'Storage Locations',
      tableKey: 'storageLocations',
      hasExample: true,
      columns: [
        {
          header: 'Name',
          fieldKey: 'name',
          type: FIELD_TYPES.TEXT,
          example: 'Cold Storage',
          placeholder: 'Storage location name',
        },
        {
          header: 'Site',
          fieldKey: 'site',
          type: FIELD_TYPES.SELECT,
          example: 'Production Facility',
        },
      ],
    },
  ],
};

export const inventoryStep = {
  heading: 'Ingredients',
  subheading:
        'List the raw materials you want to track in Ekos.',
  stepKey: STEP_KEYS.INVENTORY,
  tables: [
    {
      label: 'Inventory Items',
      tableKey: 'inventoryItems',
      hasExample: true,
      columns: [
        {
          header: 'Item Title',
          fieldKey: 'item',
          type: FIELD_TYPES.TEXT,
          example: 'Honey',
          placeholder: 'Item name',
        },
        {
          header: 'Stock Unit of Measure',
          fieldKey: 'uom',
          type: FIELD_TYPES.SELECT,
          options: UOM,
          example: 'Gallon(s)',
        },
        {
          header: 'Item Category',
          fieldKey: 'category',
          type: FIELD_TYPES.TEXT,
          example: 'Sugars',
          placeholder: 'Category',
        },
      ],
    },
  ],
};

export const submitStep = {
  heading: 'Review',
  stepKey: STEP_KEYS.SUBMIT,
};

export default [
  facilityStep,
  implementationStep,
  retailStep,
  packagingStep,
  productStep,
  equipmentStep,
  storageStep,
  inventoryStep,
  submitStep,
];
