import React from 'react';
import PropTypes from 'prop-types';

import {
  Typography,
  withStyles,
} from '@material-ui/core';

const DropzoneInstructions = ({
  classes,
  type,
  acceptedFileTypes,
  fileName,
}) => {
  if (fileName) {
    return (
      <div className={classes.instructionsContainer}>
        <Typography gutterBottom>{fileName}</Typography>
        {/* this is styled to look like a button, but the entire dropzone is actually clickable */}
        <div className={classes.browseFilesRectangle}>
          <Typography>Browse Files</Typography>
        </div>
        <Typography variant="body2"><span className={classes.typeInstructions}>{type}</span> can be a {acceptedFileTypes} format</Typography>
      </div>
    );
  }

  return (
    <div className={classes.instructionsContainer}>
      <Typography gutterBottom>Drag and drop {type} here</Typography>
      <Typography
        variant="body2"
        gutterBottom
      >
        or
      </Typography>
      {/* this is styled to look like a button, but the entire dropzone is actually clickable */}
      <div className={classes.browseFilesRectangle}>
        <Typography>Browse Files</Typography>
      </div>
      <Typography variant="body2"><span className={classes.typeInstructions}>{type}</span> can be a {acceptedFileTypes} format</Typography>
    </div>
  );
};

DropzoneInstructions.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  type: PropTypes.string,
  acceptedFileTypes: PropTypes.string,
  fileName: PropTypes.string,
};

DropzoneInstructions.defaultProps = {
  classes: {},
  type: '',
  acceptedFileTypes: '',
  fileName: '',
};

const styles = {
  instructionsContainer: {
    color: '#000000',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 200, // came up with this number because it's the largest height that the container will ever be with the largest possible image
  },
  browseFilesRectangle: {
    border: '1px solid #000000',
    padding: '8px 24px',
    textAlign: 'center',
    margin: '16px 0',
  },
  typeInstructions: {
    textTransform: 'capitalize',
  },
};

export default withStyles(styles)(DropzoneInstructions);
