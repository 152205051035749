import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Paper } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import SuccessIcon from '@material-ui/icons/Done';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/ErrorOutline';

const EkosMessage = ({
  type,
  children,
  className,
  classes,
  messageProps,
}) => {
  const getIconForMessageType = () => {
    switch (type) {
      case 'success':
        return SuccessIcon;
      case 'warning':
        return WarningIcon;
      case 'error':
        return ErrorIcon;
      case 'info':
      default:
        return InfoIcon;
    }
  };

  const Icon = getIconForMessageType();

  const typographyClasses = classNames(classes.messageBase, classes[type]);

  return (
    <Paper
      className={className}
      elevation={0}
      classes={{ root: typographyClasses }}
      {...messageProps}
    >
      <Icon className={classes.iconBase} />
      {children}
    </Paper>
  );
};

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  messageBase: {
    display: 'flex',
    alignItems: 'center',
    padding: '2px 5px',
    margin: '5px 0 10px',
    borderRadius: 4,
    color: 'rgba(0, 0, 0, 0.87)',
    lineHeight: '1.5',
  },
  iconBase: {
    fontSize: 20,
    marginRight: 5,
  },
  info: {
    border: '2px solid #1e88e5',
    backgroundColor: '#e1f3f8',
    '& > svg': {
      color: '#1e88e5',
    },
  },
  success: {
    border: '2px solid #2e8540',
    backgroundColor: '#e7f4e4',
    '& > svg': {
      color: '#2e8540',
    },
  },
  warning: {
    border: '2px solid #fdb81e',
    backgroundColor: '#fff1d2',
    '& > svg': {
      color: '#fdb81e',
    },
  },
  error: {
    border: '2px solid #e31c3d',
    backgroundColor: '#f9dede',
    '& > svg': {
      color: '#e31c3d',
    },
  },
});

EkosMessage.propTypes = {
  classes: PropTypes.shape(),
  type: PropTypes.oneOf(['success', 'info', 'warning', 'error']).isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  messageProps: PropTypes.shape(),
};

EkosMessage.defaultProps = {
  classes: {},
  children: undefined,
  className: '',
  messageProps: {},
};

export default withStyles(styles)(EkosMessage);
