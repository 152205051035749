import { createSelector } from 'reselect';
import { cloneDeep, isEmpty, pick } from 'lodash';

import { STEP_KEYS } from '../constants';

const getState = state => state;
const getFormState = createSelector(
  getState,
  state => state.form,
);

const filterStepsWithFields = props => {
  return props.steps.filter(step => step.fields);
};

const filterStepsWithRequiredFields = props => {
  const stepsWithFields = filterStepsWithFields(props);
  return stepsWithFields.filter(step => step.fields.some(field => field.required));
};

const filterStepsWithTables = ({ steps }) => {
  // need to make sure that we don't include the facility step here
  const stepsWithTables = steps.filter(step => step.tables && step.stepKey !== STEP_KEYS.FACILITY);

  return cloneDeep(stepsWithTables);
};

const filterCompletedStepsWithRequiredFields = (state, props) => {
  const formState = getFormState(state);

  const stepsWithRequiredFields = filterStepsWithRequiredFields(props);

  const completedSteps = [];

  // need to get errorState here and if errorState[stepKey][fieldKey] matches valuesForRequiredFieldsArr step is NOT complete
  stepsWithRequiredFields.forEach(step => {
    // get the required fields
    const requiredFields = step.fields.filter(field => field.required);
    const requiredFieldKeys = requiredFields.map(field => field.fieldKey);
    // get all of the step values
    const stepValues = formState[step.stepKey];
    // get the step values only for the required fields
    const valuesForRequiredFields = pick(stepValues, requiredFieldKeys);
    // check to make sure that the required fields are all filled out
    const valuesForRequiredFieldsArr = Object.values(valuesForRequiredFields);

    let isCompleted = valuesForRequiredFieldsArr.length === requiredFieldKeys.length && !valuesForRequiredFieldsArr.includes('');
    // need to check some additional things for the facility information step
    if (step.stepKey === STEP_KEYS.FACILITY) {
      // check to see if the sites table has at least one site
      const { sites } = formState || {};

      const rowsWithData = [];
      const completedRows = [];

      if (!sites) {
        isCompleted = false;
        return;
      }

      sites.forEach(site => {
        const rowValuesArr = Object.values(site);

        const validRowValues = rowValuesArr.filter(value => value !== '' && value.length !== 0);
        if (validRowValues.length > 0) {
          rowsWithData.push(site);
        }

        const isCompletedRow = validRowValues.length === rowValuesArr.length;
        if (isCompletedRow) {
          completedRows.push(site);
        }
      });

      if (completedRows.length === 0 || completedRows.length !== rowsWithData.length) {
        isCompleted = false;
        return;
      }

      // check to make sure that the logo and floorplan were uploaded
      const { logo } = state;
      const { floorplan } = state;
      if (!logo.fileName || !floorplan.fileName) {
        isCompleted = false;
      }
    }
    // if all required fields are filled out, the step is complete
    if (isCompleted) {
      completedSteps.push(step.stepKey);
    }
  });
  return completedSteps;
};

const getisCompletedPackagingRow = (state, rowValuesArr) => {
  const formState = getFormState(state);
  const allPackagingTypes = formState.packagingTypes;

  // need to figure out a way to know its the rowguid without hard coding array index - more packaging updates are coming in OB-93
  const packagingTypeRowguid = rowValuesArr[2];
  const selectedPackagingType = allPackagingTypes.find(type => (
    type.rowguid === packagingTypeRowguid
  ));

  // adding this in just in case, the packaging type name should never be blank though
  if (!selectedPackagingType) {
    return rowValuesArr;
  }

  return Boolean(selectedPackagingType);
};

const filterCompletedStepsWithTables = (state, props) => {
  const formState = getFormState(state);

  const stepsWithTables = filterStepsWithTables(props);

  const completedSteps = [];

  stepsWithTables.forEach(step => {
    const tablesForStep = step.tables;

    const validTablesForStep = [];

    tablesForStep.forEach(table => {
      const tableRows = formState[table.tableKey] || [];
      const rowsWithData = [];
      const completedRows = [];

      tableRows.forEach(row => {
        const rowValuesArr = Object.values(row);
        const validRowValues = rowValuesArr.filter(value => value && value !== '' && value.length !== 0);

        if (validRowValues.length > 0) {
          rowsWithData.push(row);
        }

        const isCompletedRow = step.stepKey === STEP_KEYS.PACKAGING
          ? getisCompletedPackagingRow(state, rowValuesArr)
          : validRowValues.length === rowValuesArr.length;

        if (isCompletedRow) {
          completedRows.push(row);
        }
      });

      if (completedRows.length > 0 && completedRows.length === rowsWithData.length) {
        validTablesForStep.push(table);
      }
    });

    if (validTablesForStep.length > 0) {
      completedSteps.push(step.stepKey);
    }
  });
  return completedSteps;
};

const getPackagingComponentsForArrayIndex = (state, props) => {
  if (props.isExample) {
    return [];
  }

  const { arrayKey, arrayIndex } = props;

  const formState = getFormState(state);
  const packagingArray = formState[arrayKey];

  if (packagingArray.length === 0) {
    return [];
  }

  const packagingArrayToUpdate = packagingArray[arrayIndex];
  if (!packagingArrayToUpdate || typeof packagingArrayToUpdate.packagingComponents === 'string') {
    return [];
  }

  return packagingArrayToUpdate.packagingComponents;
};

export const getFacilitySiteOptions = createSelector(
  getState,
  state => state.form.sites,
);

export const getBarrels = createSelector(
  getState,
  state => state.form.barrels,
);

export const getEquipment = createSelector(
  getState,
  state => state.form.equipment,
);

export const getStorageLocations = createSelector(
  getState,
  state => state.form.storageLocations,
);

export const getErrorState = createSelector(
  getState,
  state => state.error,
);

export const getEquipmentSystemOptions = createSelector(
  getState,
  state => state.form.equipmentSystems,
);

export const getEquipmentTypeOptions = createSelector(
  getState,
  state => state.form.equipmentTypes,
);

export const getPackagingComponentsState = createSelector(
  getState,
  state => {
    const packagingComponents = state.form.packagingComponents.map(component => ({
      ...component,
      value: component.tempId,
    }));

    return packagingComponents;
  },
);

export const getSelectedPackagingComponents = createSelector(
  getPackagingComponentsForArrayIndex,
  packagingComponents => {
    if (!packagingComponents) {
      return [];
    }

    const formattedComponents = packagingComponents.map(component => ({
      ...component,
      value: component.tempId,
    }));

    return formattedComponents;
  },
);

export const getInventoryItemsFromPackaging = createSelector(
  getState,
  state => state.form.inventoryItemsFromPackaging || [],
);

const filterStepsWithErrors = (state, props) => {
  const { steps } = props;
  const errorState = state.error;

  return steps.filter(step => (
    (errorState[step.stepKey] && !isEmpty(errorState[step.stepKey]))
    || (
      errorState.barrels && !isEmpty(errorState.barrels)
        ? step.stepKey === STEP_KEYS.EQUIPMENT
        : null
    )
  ));
};

export const getCompletedSteps = createSelector([
  getFormState,
  filterCompletedStepsWithRequiredFields,
  filterCompletedStepsWithTables,
  filterStepsWithErrors,
],
(
  formState,
  completedStepsWithRequiredFields,
  completedStepsWithTables,
  stepsWithErrors,
) => {
  let completedSteps = completedStepsWithRequiredFields.concat(completedStepsWithTables);
  if (formState[STEP_KEYS.RETAIL]) {
    completedSteps.push(STEP_KEYS.RETAIL);
  }

  if (stepsWithErrors) {
    const stepKeysWithErrors = stepsWithErrors.map(step => step.stepKey);
    completedSteps = completedSteps.filter(completedStep => (
      !stepKeysWithErrors.includes(completedStep)
    ));
  }
  return completedSteps;
});
