import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import Field from '../../Form/Field';

const FacilityInformationFields = ({ fields, stepKey }) => {
  // fields are defined in the config file
  const facilityNameField = fields[0];
  const phoneField = fields[1];
  const addressField = fields[2];
  const cityField = fields[3];
  const stateField = fields[4];
  const zipCodeField = fields[5];
  const countryField = fields[6];
  // IMPORTANT NOTE: this field is only for alcohol verticals
  const gravityUOMField = fields[7];

  return (
    <Grid
      container
      justify="center"
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <Field
          {...facilityNameField}
          stepKey={stepKey}
        />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Field
          {...addressField}
          stepKey={stepKey}
        />
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
      >
        <Field
          {...cityField}
          stepKey={stepKey}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
      >
        <Field
          {...stateField}
          stepKey={stepKey}
        />
      </Grid>

      <Grid
        item
        xs={6}
        md={4}
      >
        <Field
          {...zipCodeField}
          stepKey={stepKey}
        />
      </Grid>
      <Grid
        item
        xs={6}
        md={4}
      >
        <Field
          {...countryField}
          stepKey={stepKey}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
      >
        <Field
          {...phoneField}
          stepKey={stepKey}
        />
      </Grid>

      {gravityUOMField
            && (
            <Grid
              item
              xs={12}
            >
              <Field
                {...gravityUOMField}
                stepKey={stepKey}
              />
            </Grid>
            )}
    </Grid>
  );
};

FacilityInformationFields.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape()),
  stepKey: PropTypes.string,
};

FacilityInformationFields.defaultProps = {
  fields: {},
  stepKey: '',
};

export default FacilityInformationFields;
