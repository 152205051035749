import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { IconButton, withStyles } from '@material-ui/core';

const EkosIconButton = ({
  classes,
  children,
  color,
  ...rest
}) => {
  const iconWithProps = cloneElement(children, {
    className: children.props.classes && children.props.classes.root
      ? `${children.props.classes.root} ${classes.iconRoot}`
      : classes.iconRoot,
  });

  return (
    <IconButton
      disableRipple
      disableTouchRipple
      color={color || 'default'}
      classes={{
        root: classes.iconButtonRoot,
      }}
      {...rest}
    >
      {iconWithProps}
    </IconButton>
  );
};

const styles = {
  iconButtonRoot: {
    height: '30px',
    width: '30px',
    padding: 0,
    borderRadius: '4px',
    '&:hover': {
      borderRadius: '4px',
    },
  },
  iconRoot: {
    height: '20px',
    width: '20px',
  },
};

EkosIconButton.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  children: PropTypes.node,
  color: PropTypes.string,
};

EkosIconButton.defaultProps = {
  classes: {},
  children: undefined,
  color: '',
};

export default withStyles(styles)(EkosIconButton);
