import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';

import * as actions from '../Form/actions';
import getMappedValuesByField from '../Hooks/getMappedValuesByField';
import { packagingTypeDuplicates } from '../config/errorMessages';

import ComplexSelect from '../Components/MenuComponents/ComplexSelect';

const PackagingTypesField = props => {
  const {
    value,
    packagingTypes,
    packagingRow,
    formStateForStep,
    arrayIndex,
    updateRow,
    setErrorForStep,
    clearErrorForStep,
    fieldKey,
    packagingTypeRowguid,
  } = props;

  const [packagingTypeOptions, setPackagingTypeOptions] = useState([]);

  useEffect(() => {
    setPackagingTypeOptions(packagingTypes);
  }, [packagingTypes]);

  // ERROR HANDLING
  const mappedValuesByField = getMappedValuesByField(formStateForStep);

  const hasDuplicationErrorForField = () => {
    const valuesForField = mappedValuesByField.packagingTypes;
    return valuesForField
      && valuesForField.filter(v => v === value.toLowerCase().trim()).length > 1;
  };

  // TODO: Address exhaustive-deps issue
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (hasDuplicationErrorForField()) {
      setErrorForStep(packagingTypeDuplicates, fieldKey);
    } else {
      clearErrorForStep();
    }
  }, [value]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const handlePackagingTypeUpdate = rowguid => {
    if (packagingRow && packagingRow.packagingTypeRowguid === rowguid && packagingRow.packagingTypeRowguid !== 'userPackTypeNotListed') {
      return;
    }

    const selectedPackagingType = packagingTypeOptions
      .find(type => type.value === rowguid);
    const packagingComponents = selectedPackagingType.defaultComponents
      && selectedPackagingType.defaultComponents.map(component => ({
        label: component.label,
        tempId: component.tempId,
        value: component.tempId,
        quantity: component.defaultQuantity,
      }));

    let updatedRow = {};

    // userPackTypeNotListed utilizes a checkbox - below is as if user "unchecked" this packType
    if (rowguid === 'userPackTypeNotListed' && packagingRow.packagingTypeRowguid === 'userPackTypeNotListed') {
      updatedRow = {
        packagingTypes: '',
        packagingComponents: '',
        packagingTypeRowguid: '',
      };
    } else {
      updatedRow = {
        ...packagingRow,
        packagingTypes: selectedPackagingType.label,
        packagingComponents,
        packagingTypeRowguid: rowguid,
      };
    }
    updateRow(arrayIndex, updatedRow);
  };

  return (
    <ComplexSelect
      allowSearch
      label="Packaging Types"
      value={packagingTypeRowguid}
      options={packagingTypeOptions}
      persistentOption={packagingTypeOptions[0]}
      optionSelectedCallback={handlePackagingTypeUpdate}
      hasError={hasDuplicationErrorForField()}
    />
  );
};

const styles = theme => ({
  packagingType: {
    marginTop: 15,
  },
  packagingTypeInput: {
    [theme.breakpoints.up('xl')]: {
      width: 410,
      maxWidth: 410,
    },
  },
});

/* eslint-disable react/require-default-props */
PackagingTypesField.propTypes = {
  arrayIndex: PropTypes.number,
  fieldKey: PropTypes.string,
  packagingRow: PropTypes.shape(),
  packagingTypes: PropTypes.arrayOf(
    PropTypes.shape(),
  ),
  updateRow: PropTypes.func,
  value: PropTypes.string,
  formStateForStep: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.shape({}),
  ]),
  setErrorForStep: PropTypes.func,
  clearErrorForStep: PropTypes.func,
  packagingTypeRowguid: PropTypes.string,
};
/* eslint-enable */

const mapStateToProps = (state, ownProps) => {
  const { form } = state;

  const { options, arrayKey, arrayIndex } = ownProps;

  const packagingTypes = form[options].map(option => ({
    ...option,
    value: option.rowguid,
  }));

  const packagingState = form[arrayKey];
  const packagingRow = packagingState[arrayIndex];

  return {
    packagingTypes,
    formStateForStep: packagingState,
    packagingRow,
  };
};

const mapDispatchToProps = dispatch => ({
  updateRow: (index, updatedRow) => dispatch(
    actions.updatePackagingTypes(index, updatedRow),
  ),
});

const styledPackagingTypesField = withStyles(styles)(PackagingTypesField);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(styledPackagingTypesField);
