export const equipmentNameDuplicates = 'Equipment name already exists. Please use a different name.';

export const equipmentAbbrevDuplicates = 'Equipment abbreviation already exists. Please use a different abbreviation.';

export const barrelNumberDuplicates = 'Barrel number already exists. Please use a different number.';


export const barrelAndEquipmentNameDuplicate = 'Equipment name and Barrel name cannot match. Please use a different name.';

export const equipmentAndLocationNameDuplicate = 'Storage Location name cannot match equipment name. Please use a different name.';

export const barrelAndLocationNameDuplicate = 'Storage Location name cannot match barrel number. Please use a different name.';

export const storageNameDuplicates = 'Storage Location names must be unique. Please update';


export const invalidEmailAddress = 'Email address must be valid, emailAddress@domain.com. Please update';

export const productNameDuplicates = 'Product names must be unique. Please update';

export const inventoryItemNameDuplicates = 'Ingredient names must be unique. Please update';

export const siteNameDuplicates = 'Site names must be unique. Please update';

export const packagingTypeDuplicates = 'Duplicate name: Packaging Type names must be unique.';

export const fileIsTooLargeToUpload = 'The file you are trying to upload has exceeded 20MB. Please choose a smaller file or resize the one you want to upload.';
