import { store } from '../../store';
import * as errorMessages from '../../config/errorMessages';


const checkEquipmentBarrelLocationNames = (stepKey, arrayKey, fieldKey, arrayIndex) => {
  const key = stepKey || arrayKey;
  const state = store.getState();
  const { barrels, equipment, storageLocations } = state.form || {};

  // get the index of the values and ensure they match the current arrayIndex
  const barrelNumbers = barrels && barrels.map(barrel => barrel.number.trim());
  const equipmentNames = equipment && equipment.map(equipmentPiece => equipmentPiece.name.trim());
  const storageLocationNames = storageLocations
    && storageLocations.map(storageLocation => storageLocation.name.trim());

  // in order to be a match when comparing equipment => locations or barrels => locations the sites must also match
  const checkForSiteAndNameMatch = (matchValue, arrayTitle) => {
    const locationMatch = storageLocations.find(location => (
      location.name.trim() === matchValue.trim()
    ));
    if (arrayTitle === 'equipmentLocationMatch') {
      const equipmentMatch = equipment.find(equip => equip.name.trim() === matchValue.trim());
      return locationMatch && equipmentMatch && equipmentMatch.site === locationMatch.site;
    } if (arrayTitle === 'barrelLocationMatch') {
      const barrelMatch = barrels.find(barr => barr.number.trim() === matchValue.trim());
      return barrelMatch && locationMatch && barrelMatch.site === locationMatch.site;
    }
    return false;
  };

  // array of all matching values
  const barrelEquipmentMatch = barrelNumbers
    && equipmentNames
    && barrelNumbers.filter(barrelNumber => (
      barrelNumber !== '' && equipmentNames.includes(barrelNumber)
    ));

  // array of all matching values
  const barrelLocationMatch = barrelNumbers
    && storageLocationNames
    && barrelNumbers.filter(barrelNumber => (
      barrelNumber !== '' && checkForSiteAndNameMatch(barrelNumber, 'barrelLocationMatch')
    ));
    // array of all matching values
  const equipmentLocationMatch = equipmentNames
      && storageLocationNames
      && equipmentNames.filter(equipmentName => (
        equipmentName !== '' && checkForSiteAndNameMatch(equipmentName, 'equipmentLocationMatch')
      ));
  let indexOfBarrelMatch;
  let indexOfEquipmentMatch;
  let indexOfLocationMatch;

  /**
     *  arrayToCompareTo: barrelEquipmentMatch OR barrelLocationMatch OR equipmentLocationMatch
     *  function returns an array of indexes which allows us to ensure what row to show in error state
    */

  // returns an array of indexes - these indexes correspond to the equipment, barrel, or storageLocation row on the page
  const getIndexOfMatch = (arrayOfMatches, arrayToCompareTo) => {
    return arrayToCompareTo.reduce((acc, value) => {
      if (arrayOfMatches.includes(value)) {
        acc.push(arrayToCompareTo.indexOf(value));
      }
      return acc;
    }, []);
  };

  if (fieldKey === 'name' || fieldKey === 'number' || 'site') {
    if (key === 'barrels' || key === 'equipment') {
      if (barrelEquipmentMatch && barrelEquipmentMatch.length > 0) {
        indexOfBarrelMatch = barrelNumbers
          && getIndexOfMatch(barrelEquipmentMatch, barrelNumbers);
        indexOfEquipmentMatch = equipmentNames
          && getIndexOfMatch(barrelEquipmentMatch, equipmentNames);
        let indexMatch;
        if (key === 'barrels') {
          indexMatch = indexOfBarrelMatch && indexOfBarrelMatch.includes(arrayIndex);
        } else if (key === 'equipment') {
          indexMatch = indexOfEquipmentMatch && indexOfEquipmentMatch.includes(arrayIndex);
        }
        return indexMatch && errorMessages.barrelAndEquipmentNameDuplicate;
      }
    }

    if (key === 'storageLocations' || key === 'equipment') {
      if (equipmentLocationMatch && equipmentLocationMatch.length > 0) {
        // these are arrays since there can be more than one duplicate
        indexOfEquipmentMatch = equipmentNames
          && getIndexOfMatch(equipmentLocationMatch, equipmentNames);
        indexOfLocationMatch = storageLocationNames
          && getIndexOfMatch(equipmentLocationMatch, storageLocationNames);
        let indexMatch;
        if (key === 'equipment') {
          indexMatch = indexOfEquipmentMatch && indexOfEquipmentMatch.includes(arrayIndex);
        } else {
          indexMatch = indexOfLocationMatch && indexOfLocationMatch.includes(arrayIndex);
        }
        return indexMatch && errorMessages.equipmentAndLocationNameDuplicate;
      }
    }

    if (key === 'storageLocations' || key === 'barrels') {
      if (barrelLocationMatch && barrelLocationMatch.length > 0) {
        indexOfBarrelMatch = barrelNumbers
          && getIndexOfMatch(barrelLocationMatch, barrelNumbers);
        indexOfLocationMatch = storageLocationNames
          && getIndexOfMatch(barrelLocationMatch, storageLocationNames);
        let indexMatch;
        if (key === 'barrels') {
          indexMatch = indexOfBarrelMatch && indexOfBarrelMatch.includes(arrayIndex);
        } else {
          indexMatch = indexOfLocationMatch && indexOfLocationMatch.includes(arrayIndex);
        }
        return indexMatch && errorMessages.barrelAndLocationNameDuplicate;
      }
    }
  }
};

export default checkEquipmentBarrelLocationNames;
