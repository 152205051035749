const cidermakerPrimaryMain = '#F7CF00';
const cidermakerPrimaryLight = '#FFE45B';
const cidermakerPrimaryDark = '#A38800';
const cidermakerContrastText = '#000000';

const brewmasterPrimaryMain = '#008EFF';
const brewmasterPrimaryLight = '#84C9ff';
const brewmasterPrimaryDark = '#005EA8';
const brewmasterContrastText = '#FFFFFF';

const makerPrimaryMain = '#4C4CE5';
const makerPrimaryLight = '#7B7BE5';
const makerPrimaryDark = '#303093';
const makerContrastText = '#FFFFFF';

const winemakerPrimaryMain = '#D6336E';
const winemakerPrimaryLight = '#E07BA0';
const winemakerPrimaryDark = '#9B2550';
const winemakerContrastText = '#FFFFFF';

const getPrimaryPalette = brand => {
  switch (brand) {
    case 'cider':
      return {
        light: cidermakerPrimaryLight,
        main: cidermakerPrimaryMain,
        dark: cidermakerPrimaryDark,
        contrastText: cidermakerContrastText,
      };
    case 'beer':
      return {
        light: brewmasterPrimaryLight,
        main: brewmasterPrimaryMain,
        dark: brewmasterPrimaryDark,
        contrastText: brewmasterContrastText,
      };
    case 'wine':
      return {
        light: winemakerPrimaryLight,
        main: winemakerPrimaryMain,
        dark: winemakerPrimaryDark,
        contrastText: winemakerContrastText,
      };
    default:
      return {
        light: makerPrimaryLight,
        main: makerPrimaryMain,
        dark: makerPrimaryDark,
        contrastText: makerContrastText,
      };
  }
};

export default getPrimaryPalette;
