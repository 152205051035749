import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  MenuItem,
  TextField,
  withStyles,
  Typography,
} from '@material-ui/core';

import { AddCircleOutline, Done, Clear } from '@material-ui/icons';

import IconButton from '../IconButton';

const ComplexMenuCustomInput = ({
  classes,
  label,
  isCustomInputOpen,
  toggleCustomInput,
  addCustomOption,
}) => {
  const [customInputValue, setCustomInputValue] = useState('');

  const updateCustomInputValue = e => {
    e.stopPropagation();

    setCustomInputValue(e.target.value);
  };

  const clearCustomInputValue = () => {
    setCustomInputValue('');
  };

  const hideCustomInput = e => {
    e.stopPropagation();

    clearCustomInputValue();
    toggleCustomInput();
  };

  const submitCustomInput = e => {
    // need to do this check since this function could be called after the enter key is pressed
    if (e) {
      e.stopPropagation();
    }

    addCustomOption(customInputValue);
    clearCustomInputValue();
    toggleCustomInput();
  };

  const handleKeyPress = e => {
    e.stopPropagation();

    if (e.keyCode === 13) {
      submitCustomInput();
    }
  };

  const showCustomInput = e => {
    e.stopPropagation();
    toggleCustomInput();
  };

  if (!isCustomInputOpen) {
    return (
      <MenuItem
        className={classes.searchAndCustomContainer}
        onClick={showCustomInput}
      >
        <AddCircleOutline className={classes.addIcon} />
        <Typography>Add Custom {label}</Typography>
      </MenuItem>
    );
  }

  return (
    <MenuItem className={classes.searchAndCustomContainer}>
      <TextField
        fullWidth
        autoFocus
        value={customInputValue}
        onChange={updateCustomInputValue}
        onKeyDown={handleKeyPress}
      />
      <IconButton onClick={submitCustomInput}>
        <Done />
      </IconButton>
      <IconButton onClick={hideCustomInput}>
        <Clear />
      </IconButton>
    </MenuItem>
  );
};

const styles = theme => ({
  addIcon: {
    marginRight: 8,
  },
  searchAndCustomContainer: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    padding: '12px 16px',
  },
});

ComplexMenuCustomInput.propTypes = {
  classes: PropTypes.shape(),
  isCustomInputOpen: PropTypes.bool,
  label: PropTypes.string,
  toggleCustomInput: PropTypes.func.isRequired,
  addCustomOption: PropTypes.func.isRequired,
};

ComplexMenuCustomInput.defaultProps = {
  classes: {},
  isCustomInputOpen: false,
  label: '',
};

export default withStyles(styles)(ComplexMenuCustomInput);
