import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Checkbox,
  Menu,
  MenuItem,
  withStyles,
} from '@material-ui/core';

import ComplexMenuSearch from './ComplexMenuSearch';
import ComplexMenuCustomInput from './ComplexMenuCustomInput';

const ComplexMenu = ({
  classes,
  anchorEl,
  allowSearch,
  allowCustom,
  allowSelectAll,
  isMultiSelect,
  label,
  selectedOptions,
  options,
  customInputCallback,
  selectAllCallback,
  optionSelectedCallback,
  closeCallback,
}) => {
  const getAllSelectedValues = useCallback(() => {
    return selectedOptions.map(option => option.value);
  }, [selectedOptions]);

  const [allOptions, setAllOptions] = useState(options);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [selectedValues, setSelectedValues] = useState(getAllSelectedValues());
  const [isCustomInputOpen, setIsCustomInputOpen] = useState(false);

  useEffect(() => {
    setAllOptions(options);
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    const updatedSelectedValues = getAllSelectedValues();
    setSelectedValues(updatedSelectedValues);
  }, [getAllSelectedValues, selectedOptions]);

  const addCustomOption = customOption => {
    customInputCallback(customOption);
  };

  const closeComplexMenu = () => {
    setFilteredOptions(allOptions);
    setIsCustomInputOpen(false);
    closeCallback();
  };

  const isSelectAllChecked = () => {
    const selectedFilteredOptions = filteredOptions.reduce((acc, option) => {
      if (selectedValues.includes(option.value)) {
        acc.push(option);
      }

      return acc;
    }, []);

    return selectedFilteredOptions.length === filteredOptions.length;
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={anchorEl !== null}
      onClose={closeComplexMenu}
      classes={{
        paper: classes.menuPaper,
      }}
    >
      {
        allowSearch && (
          <ComplexMenuSearch
            allOptions={allOptions}
            queryFilterCallback={updatedOptions => setFilteredOptions(updatedOptions)}
            label={label}
            isCustomInputOpen={isCustomInputOpen}
          />
        )
      }

      {
        allowCustom && (
          <ComplexMenuCustomInput
            isCustomInputOpen={isCustomInputOpen}
            label={label}
            toggleCustomInput={() => setIsCustomInputOpen(!isCustomInputOpen)}
            addCustomOption={addCustomOption}
          />
        )
      }

      {
        allowSelectAll && (
          <MenuItem
            onClick={() => (
              selectAllCallback(filteredOptions, isSelectAllChecked())
            )}
          >
            <Checkbox
              checked={isSelectAllChecked()}
            />
            Select All
          </MenuItem>
        )
      }

      {filteredOptions.map(option => (
        <MenuItem
          key={option.value}
          value={option.value}
          onClick={() => optionSelectedCallback(option)}
        >
          {isMultiSelect && (
            <Checkbox checked={selectedValues.includes(option.value)} />
          )}
          {option.label}
        </MenuItem>
      ))}
    </Menu>
  );
};

const styles = {
  menuPaper: {
    minWidth: 350,
  },
};

ComplexMenu.propTypes = {
  anchorEl: PropTypes.shape(),
  allowSearch: PropTypes.bool,
  allowCustom: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  customInputCallback: PropTypes.func,
  optionSelectedCallback: PropTypes.func.isRequired,
  closeCallback: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string),
  allowSelectAll: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  selectedOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  selectAllCallback: PropTypes.func,
};

ComplexMenu.defaultProps = {
  anchorEl: {},
  allowSearch: false,
  allowCustom: false,
  label: '',
  classes: {},
  allowSelectAll: false,
  isMultiSelect: false,
  selectedOptions: [],
  customInputCallback: () => {},
  selectAllCallback: () => {},
};

export default withStyles(styles)(ComplexMenu);
