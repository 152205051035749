import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  Hidden,
  Grid,
  Typography,
  Button,
  Divider,
  withStyles,
} from '@material-ui/core';
import { Delete, AddCircleOutline } from '@material-ui/icons';
import { lighten } from '@material-ui/core/styles/colorManipulator';

import Field from '../Form/Field';

import IconButton from '../Components/IconButton';

import CopyIcon from '../icons/CopyIcon';

const PackagingGrid = props => {
  const {
    classes,
    hasExample,
    columns,
    rows,
    tableKey,
    onFieldChange,
    hasErrorMessage,
    errors,
    addNewRow,
    label,
    copyLast,
    removeTableRow,
  } = props;

  const packagingTypeColumn = columns[0];
  const packagingComponentsColumn = columns[1];

  const hasSecondaryExample = columns.findIndex(column => column.secondaryExample) > -1;

  const getExample = examples => {
    return (
      <>
        {examples.map(example => {
          if (example.label) {
            return (
              <Fragment key={example.value}>
                <Typography variant="caption">{example.label}</Typography>
                <Typography>{example.value}</Typography>
              </Fragment>
            );
          }


          return <Typography key={example.value}>{example.value}</Typography>;
        })}
      </>
    );
  };

  return (
    <>
      {/* LANDSCAPE MODE TABLET AND LARGER */}
      <Hidden smDown>
        {/* GRID HEADERS */}
        <Grid container>
          <Grid
            item
            xs={5}
            className={classes.gridItem}
          >
            <Typography variant="caption">Packaging Type</Typography>
          </Grid>
          <Grid
            item
            xs={6}
            className={classes.gridItem}
          >
            <Typography variant="caption">Packaging Components</Typography>
          </Grid>
        </Grid>

        {/* PACKAGING EXAMPLE */}
        {hasExample && columns
                && (
                <Grid
                  container
                  className={classes.example}
                >
                  <Grid
                    item
                    xs={12}
                    className={classes.exampleLabel}
                  >
                    <Typography variant="caption">Example</Typography>
                  </Grid>
                  {columns.map(column => {
                    if (column.type === 'hidden') {
                      return null;
                    }
                    return (
                      <Fragment key={`example-${column.fieldKey}`}>
                        <Grid
                          item
                          xs={5}
                          key={`example-${column.fieldKey}`}
                          className={classes.gridItem}
                        >
                          {getExample(column.example)}
                        </Grid>
                      </Fragment>
                    );
                  })}

                  {
                      /* There are cases where a step has a hasSecondaryExample but a specific column on that step does not */
                  }
                  {hasSecondaryExample && (
                  <>
                    <Divider
                      variant="fullWidth"
                      className={classes.divider}
                    />
                    {columns.map(column => {
                      return (
                        <Grid
                          item
                          xs={5}
                          key={`example-${column.fieldKey}`}
                          className={classes.gridItem}
                        >
                          {getExample(column.secondaryExample || [])}
                        </Grid>
                      );
                    })}
                  </>
                  )}
                </Grid>
                )}
        {rows.map(row => {
          const { packagingTypeRowguid } = row;
          return (
            <Grid
              container
              className={classes.packagingRow}
              key={row.arrayIndex}
            >
              {columns
                                && columns.map((column, idx) => {
                                  const {
                                    key: columnKey,
                                    fieldKey,
                                    ...restColumn
                                  } = column;

                                  if (column.type === 'hidden') {
                                    return null;
                                  }

                                  const hidePackagingComponentsList = fieldKey === 'packagingComponents' && packagingTypeRowguid === 'userPackTypeNotListed';
                                  return (
                                    <Grid
                                      key={fieldKey}
                                      item
                                      xs={idx === 0 ? 5 : 6}
                                      className={classes.gridItem}
                                    >
                                      {!hidePackagingComponentsList
                                                && (
                                                <Field
                                                  arrayKey={tableKey}
                                                  arrayIndex={
                                                        row.arrayIndex
                                                    }
                                                  fieldKey={fieldKey}
                                                  onChange={onFieldChange(
                                                    row.arrayIndex,
                                                    column.fieldKey,
                                                  )}
                                                  {...restColumn}
                                                />
                                                )}
                                    </Grid>
                                  );
                                })}

              <Grid
                item
                xs={1}
                className={classes.deleteIconContainer}
              >
                <IconButton
                  onClick={removeTableRow(row.arrayIndex)}
                >
                  <Delete />
                </IconButton>
              </Grid>
            </Grid>
          );
        })}
      </Hidden>

      {/* PORTRAIT MODE TABLET AND MOBILE */}
      <Hidden mdUp>
        {/* PACKAGING EXAMPLE */}
        {hasExample && columns
                && (
                <Grid
                  container
                  className={classes.example}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <Typography variant="caption">Example</Typography>
                  </Grid>

                  <Grid
                    container
                    justify="space-between"
                  >
                    <Grid
                      item
                      xs={12}
                      sm={5}
                    >
                      {getExample(packagingTypeColumn.example)}
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <Typography
                        variant="caption"
                        color="textSecondary"
                      >
                        {packagingComponentsColumn.header}
                      </Typography>
                      {getExample(packagingComponentsColumn.example)}
                    </Grid>
                  </Grid>
                </Grid>
                )}
        {rows.map(row => (
          <Grid
            container
            className={classes.packagingRow}
            alignItems="flex-start"
            justify="space-between"
            key={row.arrayIndex}
          >
            <Grid
              item
              xs={12}
              sm={5}
            >
              <Grid
                container
                direction="column"
              >
                <Grid
                  item
                  xs={12}
                >
                  <Field
                    label={packagingTypeColumn.header}
                    arrayKey={tableKey}
                    arrayIndex={row.arrayIndex}
                    fieldKey={packagingTypeColumn.fieldKey}
                    onChange={onFieldChange(
                      row.arrayIndex,
                      packagingTypeColumn.fieldKey,
                    )}
                    {...packagingTypeColumn}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              className={classes.packagingComponentsContainer}
            >
              <Field
                label={packagingComponentsColumn.header}
                arrayKey={tableKey}
                arrayIndex={row.arrayIndex}
                fieldKey={packagingComponentsColumn.fieldKey}
                onChange={onFieldChange(
                  row.arrayIndex,
                  packagingComponentsColumn.fieldKey,
                )}
                {...packagingComponentsColumn}
              />
            </Grid>

            <Grid
              item
              xs={12}
            >
              <Button
                variant="text"
                fullWidth
                onClick={removeTableRow(row.arrayIndex)}
              >
                <Delete className={classes.deleteIcon} />
                Remove
              </Button>
            </Grid>
          </Grid>
        ))}
      </Hidden>

      <Grid container>
        {hasErrorMessage && (
        <Grid
          container
          justify="center"
          className={classes.errorMessage}
        >
          <Grid item>
            {errors.map(msg => (
              <Typography
                color="error"
                key={msg}
              >
                {msg}
              </Typography>
            ))}
          </Grid>
        </Grid>
        )}
        <Grid
          item
          xs={12}
          className={classes.addRow}
        >
          <Button
            className={classes.addButton}
            onClick={addNewRow}
            disabled={hasErrorMessage}
          >
            <AddCircleOutline className={classes.addIcon} />
            Add {label}
          </Button>
          <Button
            className={classes.addButton}
            onClick={copyLast}
            disabled={hasErrorMessage}
          >
            <CopyIcon className={classes.copyIcon} />
            Copy Last
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const styles = theme => ({
  addRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: '#008EFF',
  },
  addButton: {
    textTransform: 'none',
    color: '#008EFF',
    marginRight: 25,
  },
  addIcon: {
    marginRight: '6px',
  },
  copyIcon: {
    height: '1em',
    width: '1em',
    fontSize: '1.5rem',
    marginRight: '6px',
  },
  deleteIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 5,
  },
  example: {
    padding: '0.4rem',
    borderRadius: '4px',
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
    width: 'calc(100% + 8px)',
    paddingLeft: 0,
  },
  exampleColumn: {
    marginTop: 15,
  },
  gridItem: {
    padding: '0 8px',
  },
  packagingRow: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    marginLeft: 0,
    marginBottom: 5,
  },
  packagingComponentsContainer: {
    [theme.breakpoints.up('sm')]: {
      marginTop: 15,
    },
  },
  divider: {
    marginLeft: 8,
    width: 'calc(100% - 8px)',
    marginBottom: 8,
    marginTop: 8,
  },
  exampleLabel: {
    padding: '0 8px 8px',
  },
});

/* eslint-disable react/require-default-props */
PackagingGrid.propTypes = {
  classes: PropTypes.shape(),
  hasExample: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape(),
  ),
  rows: PropTypes.arrayOf(
    PropTypes.shape(),
  ),
  tableKey: PropTypes.string,
  onFieldChange: PropTypes.func,
  removeTableRow: PropTypes.func,
  hasErrorMessage: PropTypes.bool,
  errors: PropTypes.arrayOf(
    PropTypes.shape(),
  ),
  addNewRow: PropTypes.func,
  label: PropTypes.string,
  copyLast: PropTypes.func,
};
/* eslint-enable react/require-default-props */

export default withStyles(styles)(PackagingGrid);
