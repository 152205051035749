import PropTypes from 'prop-types';

import beerCiderPackagingTypes from './config/packagingTypes/beerCiderPackaging';
import winePackagingTypes from './config/packagingTypes/winePackaging';
import foodAndRoasterPackagingTypes from './config/packagingTypes/foodAndRoasterPackaging';
import nonAlcoholicPackagingTypes from './config/packagingTypes/nonAlcoholicPackaging';
import {
  bottlePackagingComponents,
  canPackagingComponents,
  kegPackagingComponents,
  bagPackagingComponents,
  jarPackagingComponents,
  otherPackagingComponents,
} from './config/packagingTypes/allPackagingComponents';

export const BRANDS = [
  {
    variant: 'beer',
    label: 'Beer',
  },
  {
    variant: 'cider',
    label: 'Cider',
  },
  {
    variant: 'wine',
    label: 'Wine',
  },
  {
    variant: 'food',
    label: 'Food',
  },
  {
    variant: 'non-alcoholic',
    label: 'Non-alcoholic Beverage',
  },
  {
    variant: 'roaster',
    label: 'Coffee',
  },
];

export const STEP_KEYS = {
  FACILITY: 'facility',
  IMPLEMENTATION: 'implementation',
  RETAIL: 'retail',
  PRODUCTS: 'products',
  PACKAGING: 'packaging',
  BARRELS: 'barrels',
  EQUIPMENT: 'equipment',
  STORAGELOCATIONS: 'storageLocations',
  INVENTORY: 'inventory',
  SUBMIT: 'submit',
};

export const FIELD_TYPES = {
  TEXT: 'text',
  NUMBER: 'number',
  TEL: 'tel',
  TEXT_AREA: 'textArea',
  SELECT: 'select',
  MULTI_SELECT: 'multi-select',
  CHECKBOX: 'checkbox',
  HIDDEN: 'hidden',
  PACKAGING_COMPONENTS: 'packagingComponents',
  PACKAGING_TYPES: 'packagingTypes',
  PRODUCT_PACKAGING: 'productPackaging',
  KEG_DEPOSIT: 'kegDeposit',
  COMPLEX_SELECT: 'complexSelect',
};

export const INVENTORY_ITEM_CLASS = [
  {
    label: 'Ingredients',
    value: 'ingredients',
  },
  {
    label: 'Packaging',
    value: 'packaging',
  },
];

export const PRODUCT_CLASS = [
  {
    label: 'Beer',
    value: 'beer',
  },
  {
    label: 'Wine',
    value: 'wine',
  },
  {
    label: 'Cider',
    value: 'cider',
  },
  {
    label: 'Coffee',
    value: 'coffee',
  },
  {
    label: 'Kombucha',
    value: 'kombucha',
  },
  {
    label: 'Mead',
    value: 'mead',
  },
  {
    label: 'Malt',
    value: 'malt',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const EQUIPMENT_TYPES = [
  {
    value: 'Blending Tank',
    label: 'Blending Tank',
  },
  {
    value: 'Boil Kettle',
    label: 'Boil Kettle',
  },
  {
    value: 'Brite Tank',
    label: 'Brite Tank',
  },
  {
    value: 'Fermenter',
    label: 'Fermenter',
  },
  {
    value: 'Lauter Tun',
    label: 'Lauter Tun',
  },
  {
    value: 'Mash Tun',
    label: 'Mash Tun',
  },
  {
    value: 'Mix Tank',
    label: 'Mix Tank',
  },
  {
    value: 'Other',
    label: 'Other',
  },
  {
    value: 'Processing Tank',
    label: 'Processing Tank',
  },
  {
    value: 'Roaster',
    label: 'Roaster',
  },
  {
    value: 'Serving Tank',
    label: 'Serving Tank',
  },
  {
    value: 'Serving Vessel',
    label: 'Serving Vessel',
  },
  {
    value: 'Whirlpool',
    label: 'Whirlpool',
  },
];

export const EQUIPMENT_SYSTEMS = [
  {
    value: 'primary',
    label: 'Primary',
  },
  {
    value: 'pilot',
    label: 'Pilot',
  },
];

export const BEER_CIDER_PACKAGING_TYPES = beerCiderPackagingTypes;

export const WINE_PACKAGING_TYPES = winePackagingTypes;

export const NON_ALCOHOLIC_PACKAGING_TYPES = nonAlcoholicPackagingTypes;

export const FOOD_ROASTER_PACKAGING_TYPES = foodAndRoasterPackagingTypes;

export const ALL_PACKAGING_COMPONENTS = [
  ...bottlePackagingComponents,
  ...canPackagingComponents,
  ...kegPackagingComponents,
  ...bagPackagingComponents,
  ...jarPackagingComponents,
  ...otherPackagingComponents,
];

export const GRAVITY_UOM = [
  {
    value: 'specificGravity',
    label: 'Specific Gravity',
  },
  {
    value: 'brix',
    label: 'Brix',
  },
  {
    value: 'balling',
    label: 'Balling',
  },
  {
    value: 'plato',
    label: 'Plato',
  },
];

export const UOM = [
  {
    value: 'Barrel(s)',
    label: 'Barrel(s)',
  },
  {
    value: 'Liter(s)',
    label: 'Liter(s)',
  },
  {
    value: 'Gallon(s)',
    label: 'Gallon(s)',
  },
  {
    value: 'Hectoliter(s)',
    label: 'Hectoliter(s)',
  },
  {
    value: 'Milligram(s)',
    label: 'Milligram(s)',
  },
  {
    value: 'Milliliter(s)',
    label: 'Milliliter(s)',
  },
  {
    value: 'Fluid Ounce(s)',
    label: 'Fluid Ounce(s)',
  },
  {
    value: 'Each',
    label: 'Each',
  },
  {
    value: 'Ounce(s)',
    label: 'Ounce(s)',
  },
  {
    value: 'Gram(s)',
    label: 'Gram(s)',
  },
  {
    value: 'Pound(s)',
    label: 'Pound(s)',
  },
  {
    value: 'Kilogram(s)',
    label: 'Kilogram(s)',
  },
];

export const formPropType = {
  equipmentTypes: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  packagingTypes: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    rowguid: PropTypes.string,
    wipAmount: PropTypes.number,
    wipAmountUom: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
    category: PropTypes.string,
    defaultComponents: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      defaultQuantity: PropTypes.number,
      tempId: PropTypes.string,
    })),
  })),
  packagingComponents: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    category: PropTypes.string,
    tempId: PropTypes.string,
  })),
  productClass: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  gravityUom: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  equipmentSystems: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  sites: PropTypes.arrayOf(PropTypes.shape({
    siteName: PropTypes.string,
    siteId: PropTypes.string,
  })),
  packaging: PropTypes.arrayOf(PropTypes.shape({
    packagingTypes: PropTypes.string,
    packagingComponents: PropTypes.string,
    packagingTypeRowguid: PropTypes.string,
  })),
};
