import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  InputLabel,
  TextField,
  withStyles,
} from '@material-ui/core';

import * as actions from '../Form/actions';

const KegDepositInput = ({
  classes,
  kegDepositValues,
  updateKegDeposit,
}) => {
  const formatKegDepositForUpdate = (e, field) => {
    const updatedKegDeposit = {
      ...kegDepositValues,
      [field]: e.target.value,
    };

    updateKegDeposit(updatedKegDeposit);
  };

  return (
    <>
      <InputLabel
        shrink
        className={classes.label}
      >
        Keg Deposit Amount
      </InputLabel>

      <TextField
        className={classes.kegDepositCurrency}
        value={kegDepositValues.currency}
        onChange={e => formatKegDepositForUpdate(e, 'currency')}
      />

      <TextField
        className={classes.kegDepositAmount}
        fullWidth
        type="number"
        value={kegDepositValues.amount}
        onChange={e => formatKegDepositForUpdate(e, 'amount')}
        inputProps={{ min: '0' }}
      />
    </>
  );
};

const styles = {
  label: {
    marginTop: 16,
  },
  kegDepositCurrency: {
    width: 40,
    marginRight: 4,
  },
  kegDepositAmount: {
    maxWidth: 296,
  },
};

KegDepositInput.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  kegDepositValues: PropTypes.shape(),
  updateKegDeposit: PropTypes.func,
};

KegDepositInput.defaultProps = {
  classes: {},
  kegDepositValues: {},
  updateKegDeposit: () => {},
};

const mapStateToProps = (state, ownProps) => {
  const { form } = state;
  const { stepKey, fieldKey } = ownProps;

  const formStateForStep = form[stepKey] || null;

  const kegDepositValues = formStateForStep[fieldKey] || {
    currency: '$',
    amount: '',
  };

  return {
    kegDepositValues,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateKegDeposit: kegDepositValues => dispatch(
    actions.changeField(
      ownProps.stepKey,
      ownProps.fieldKey,
      kegDepositValues,
    ),
  ),
});

const styledKegDepositInput = withStyles(styles)(KegDepositInput);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(styledKegDepositInput);
