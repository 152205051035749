import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Typography, withStyles } from '@material-ui/core';

const Dropzone = ({
  accept,
  handleFile,
  handleRejectedFile,
  instructions,
  label,
  maxSize,
  multiple,
  theme,
  hasError,
}) => {
  const onDrop = useCallback(acceptedFiles => {
    if (multiple && acceptedFiles.length > 0) {
      handleFile(acceptedFiles);
    } else if (acceptedFiles[0]) {
      handleFile(acceptedFiles[0]);
    }
  }, [handleFile, multiple]);

  const onDropRejected = useCallback(rejectedFiles => {
    const isFileTooLarge = rejectedFiles.length > 0
    // need operating system specific check rejectedFiles[0].size is undefined on Mac OS
    && ((rejectedFiles[0].size > maxSize) || (rejectedFiles[0].file.size > maxSize));
    handleRejectedFile(isFileTooLarge);
  }, [handleRejectedFile, maxSize]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    onDropRejected,
    accept,
    multiple,
    maxSize,
  });


  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '24px 16px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: hasError ? '#e31c3d' : '#000000',
    borderStyle: 'dashed',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer',
  };

  const activeStyle = {
    borderColor: theme.palette.primary.main,
  };

  const acceptStyle = {
    borderColor: theme.palette.primary.main,
  };

  const rejectStyle = {
    borderColor: '#e31c3d',
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [
      isDragActive,
      isDragReject,
      isDragAccept,
      acceptStyle,
      activeStyle,
      rejectStyle,
      baseStyle,
    ],
  );

  return (
    <>
      {label && (
        <Typography
          variant="subtitle1"
          gutterBottom
        >
          {label}
        </Typography>
      )}
      <section>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          {instructions}
        </div>
      </section>
    </>
  );
};

// eslint-disable-next-line no-unused-vars
const styles = theme => ({});

Dropzone.propTypes = {
  accept: PropTypes.string,
  instructions: PropTypes.node,
  multiple: PropTypes.bool,
  handleFile: PropTypes.func,
  handleRejectedFile: PropTypes.func,
  label: PropTypes.string,
  maxSize: PropTypes.number,
  theme: PropTypes.shape(),
  hasError: PropTypes.bool,
};

Dropzone.defaultProps = {
  accept: 'image/*',
  instructions: undefined,
  multiple: false,
  handleFile: () => {},
  handleRejectedFile: () => {},
  label: '',
  maxSize: undefined,
  theme: {},
  hasError: false,
};

export default withStyles(styles, { withTheme: true })(Dropzone);
