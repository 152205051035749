import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

const InlineErrorMessage = ({ classes, children, variant }) => (
  <div className={classes.inlineErrorContainer}>
    <ErrorIcon className={classes.inlineErrorIcon} />
    <Typography variant={variant}>{children}</Typography>
  </div>
);


const styles = theme => ({
  inlineErrorContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  inlineErrorIcon: {
    color: theme.palette.error.main,
    height: 20,
    width: 20,
    marginRight: 4,
  },
});

InlineErrorMessage.propTypes = {
  classes: PropTypes.shape(),
  variant: PropTypes.string,
  children: PropTypes.node,
};

InlineErrorMessage.defaultProps = {
  variant: 'caption',
  classes: {},
  children: undefined,
};

export default withStyles(styles)(InlineErrorMessage);
