import React from 'react';
import { ReactComponent as Copy } from './copy-icon.svg';

const CopyIcon = props => {
  return (
    <Copy {...props} />
  );
};

export default CopyIcon;
