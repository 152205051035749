import React from 'react';
import PropTypes from 'prop-types';

import {
  Typography,
  withStyles,
  Button,
} from '@material-ui/core';

import CircleLogo from '../icons/CircleLogo';

const SuccessPage = ({ classes, back }) => (
  <div className={classes.successPageContainer}>
    <div className={classes.successMessageContainer}>
      <div className={classes.successHeading}>
        <CircleLogo className={classes.logo} />
        <Typography variant="h3">Success!</Typography>
      </div>

      <Typography gutterBottom>
        {/* eslint-disable-next-line max-len */}
        Your site setup has been sent to the Ekos team! You should receive a confirmation in the next 24 hours that we have received all of your information.
      </Typography>
      <Typography gutterBottom>
        {'If you have any questions please reach out to  '}
        <a
          href="mailto:sales@goekos.com?subject=Customer%20Onboarding"
          className={classes.link}
        >
          sales@goekos.com
        </a>
        .
      </Typography>
      <Button
        className={classes.backButton}
        variant="outlined"
        onClick={back}
      >
        {'< Back'}
      </Button>
    </div>
  </div>
);

SuccessPage.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  back: PropTypes.func.isRequired,
};

SuccessPage.defaultProps = {
  classes: {},
};

const styles = theme => ({
  backButton: {
    alignSelf: 'flex-start',
    marginTop: 35,
  },
  successPageContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    background: theme.palette.grey[200],
  },
  successMessageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'white',
    padding: '24px 48px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 56,
    textAlign: 'center',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '75%',
    },
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '40%',
    },
  },
  successHeading: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 32,
  },
  logo: {
    height: '4rem',
    width: '4rem',
    marginRight: '1rem',
  },
  link: {
    color: '#008EFF',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
});

export default withStyles(styles)(SuccessPage);
