import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uuid from 'react-uuid';

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  withStyles,
} from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';

import * as selectors from '../Form/selectors';
import * as actions from '../Form/actions';

import ComplexMenu from '../Components/MenuComponents/ComplexMenu';

const PackagingComponentsList = props => {
  const {
    classes,
    isExample,
    arrayKey,
    arrayIndex,
    fieldKey,
    packagingComponentsForBrand,
    selectedPackagingComponents,
    updatePackagingComponents,
    packagingRow,
    disableSelect,
    addCustomPackagingComponent,
    allPackagingTypes,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedComponents, setSelectedComponents] = useState(selectedPackagingComponents);
  // need this because we will be filtering the list of available packaging component options for the selected packaging type
  // the packaging components are filtered by the category of the selected packaging type
  const [
    packagingComponentOptions,
    setPackagingComponentOptions,
  ] = useState(packagingComponentsForBrand);

  const selectedPackagingTypeRef = useRef();

  const updateSelectedPackagingType = useCallback(() => {
    selectedPackagingTypeRef.current = allPackagingTypes.find(packagingType => (
      packagingType.value === packagingRow.packagingTypes
    ));
  }, [allPackagingTypes, packagingRow.packagingTypes]);

  const filterPackagingComponentOptions = useCallback(() => {
    const selectedPackagingType = selectedPackagingTypeRef.current;

    // custom packaging types will have no category
    // all packaging components should show for custom packaging types
    if (!selectedPackagingType || !selectedPackagingType.category) {
      return;
    }

    // if a packaging component has a category, make sure that category matches the selected packaging type
    // all custom packaging components should show for all packaging types, so we make sure to never filter out a component that doesn't have a category
    const filteredPackagingComponents = packagingComponentsForBrand.filter(component => (
      !component.category || component.category === selectedPackagingType.category
    ));
    setPackagingComponentOptions(filteredPackagingComponents);
  }, [packagingComponentsForBrand]);

  useEffect(() => {
    setSelectedComponents(selectedPackagingComponents);
  }, [selectedPackagingComponents]);

  useEffect(() => {
    if (!selectedPackagingTypeRef.current && packagingRow.packagingTypes) {
      updateSelectedPackagingType();
      filterPackagingComponentOptions();
    }

    if (selectedPackagingTypeRef.current
            && packagingRow.packagingTypes
            && packagingRow.packagingTypes !== selectedPackagingTypeRef.current.value) {
      updateSelectedPackagingType();
      filterPackagingComponentOptions();
    }
  }, [packagingRow, filterPackagingComponentOptions, updateSelectedPackagingType]);

  const handleQuantityUpdate = (quantity, componentValue) => {
    const updatedComponents = [...selectedComponents];
    const componentIdx = updatedComponents.findIndex(component => (
      component.value === componentValue
    ));
    const updatedComponent = {
      ...updatedComponents[componentIdx],
      quantity,
    };

    updatedComponents.splice(componentIdx, 1, updatedComponent);
    setSelectedComponents(updatedComponents);
    updatePackagingComponents(arrayKey, arrayIndex, fieldKey, updatedComponents);
  };

  const handleComponentSelect = selectedComponent => {
    let newSelectedComponents = [...selectedComponents];

    if (newSelectedComponents.findIndex(component => (
      component.value === selectedComponent.value
    )) > -1) {
      newSelectedComponents = newSelectedComponents.filter(component => (
        component.value !== selectedComponent.value
      ));
    } else {
      newSelectedComponents.push({
        label: selectedComponent.label,
        value: selectedComponent.tempId,
        tempId: selectedComponent.tempId,
        quantity: 1,
      });
    }

    setSelectedComponents(newSelectedComponents);
    updatePackagingComponents(arrayKey, arrayIndex, fieldKey, newSelectedComponents);
  };

  const handleCustomSubmit = async customValue => {
    const tempId = uuid();
    const newOption = { label: customValue, value: tempId, tempId };
    const newOptions = [
      newOption,
      ...packagingComponentsForBrand,
    ];

    if (selectedPackagingTypeRef.current && selectedPackagingTypeRef.current.category) {
      const updatedPackagingComponentOptions = newOptions.filter(option => (
        !option.category || option.category === selectedPackagingTypeRef.current.category
      ));
      setPackagingComponentOptions(updatedPackagingComponentOptions);
    } else {
      setPackagingComponentOptions(newOptions);
    }

    addCustomPackagingComponent(newOptions);

    handleComponentSelect(newOption);
  };

  return (
    <>
      <List className={!isExample && classes.list}>
        {selectedComponents && selectedComponents.map(component => (
          <ListItem
            key={component.value}
            className={classes.listItem}
          >
            <TextField
              className={classes.componentQuantity}
              disabled={isExample}
              type="number"
              value={component.quantity}
              inputProps={{
                className: classes.componentQuantityInput,
                min: '1',
              }}
              onChange={e => handleQuantityUpdate(e.target.value, component.value)}
            />
            <ListItemText
              primary={component.label}
              className={classes.componentTitle}
            />
          </ListItem>
        ))}
        {!isExample
                && (
                <ListItem
                  button
                  onClick={e => setAnchorEl(e.currentTarget.parentElement)}
                  disabled={disableSelect}
                  className={classes.updateButton}
                >
                  <ListItemIcon className={classes.addIcon}>
                    <AddCircleOutline />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.addText}
                    primary={selectedComponents.length > 0 ? 'Update Packaging Components' : 'Select Packaging Components'}
                  />
                </ListItem>
                )}
      </List>

      <ComplexMenu
        anchorEl={anchorEl}
        allowSearch
        allowCustom
        isMultiSelect
        label="Packaging Components"
        selectedOptions={selectedComponents}
        options={packagingComponentOptions}
        customInputCallback={handleCustomSubmit}
        optionSelectedCallback={handleComponentSelect}
        closeCallback={() => setAnchorEl(null)}
      />
    </>
  );
};

const styles = theme => ({
  list: {
    paddingTop: 11,
    paddingBottom: 11,
  },
  listItem: {
    padding: '5px 0',
    alignItems: 'baseline',
  },
  updateButton: {
    padding: '5px 0',
  },
  componentTitle: {
    maxWidth: 250,
  },
  componentQuantity: {
    maxWidth: 75,
    marginRight: 24,
  },
  componentQuantityInput: {
    textAlign: 'right',
  },
  addIcon: {
    color: 'black',
    marginRight: 5,
    minWidth: 24,
  },
  addText: {
    color: 'black',
    paddingLeft: 0,
  },
  textWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltipIcon: {
    height: 18,
    width: 18,
    marginLeft: 5,
    color: theme.palette.text.secondary,
  },
});

PackagingComponentsList.propTypes = {
  classes: PropTypes.shape(),
  isExample: PropTypes.bool,
  arrayIndex: PropTypes.number,
  arrayKey: PropTypes.string,
  disableSelect: PropTypes.bool,
  fieldKey: PropTypes.string,
  packagingComponentsForBrand: PropTypes.arrayOf(
    PropTypes.shape(),
  ),
  packagingRow: PropTypes.shape(),
  selectedPackagingComponents: PropTypes.arrayOf(
    PropTypes.shape(),
  ),
  updatePackagingComponents: PropTypes.func,
  addCustomPackagingComponent: PropTypes.func,
  allPackagingTypes: PropTypes.arrayOf(PropTypes.shape({
    category: PropTypes.string,
    defaultComponents: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      defaultQuantity: PropTypes.number,
      tempId: PropTypes.string,
    })),
    label: PropTypes.string,
    rowguid: PropTypes.string,
    wipAmount: PropTypes.number,
    wipAmountUom: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  })),
};

PackagingComponentsList.defaultProps = {
  classes: {},
  isExample: false,
  arrayIndex: 0,
  arrayKey: '',
  disableSelect: false,
  fieldKey: '',
  packagingComponentsForBrand: [],
  packagingRow: {},
  selectedPackagingComponents: [],
  updatePackagingComponents: () => {},
  addCustomPackagingComponent: () => {},
  allPackagingTypes: [],
};

const mapStateToProps = (state, ownProps) => {
  const { form } = state;
  const { arrayKey, arrayIndex } = ownProps;

  const packagingComponentsForBrand = selectors.getPackagingComponentsState(state);
  const allPackagingTypes = form.packagingTypes;

  const selectedPackagingComponents = selectors.getSelectedPackagingComponents(state, ownProps);

  let packagingRow;
  let packagingArray;

  let disableSelect = true;
  if (arrayKey) {
    packagingArray = form[arrayKey];
    packagingRow = packagingArray[arrayIndex];
    disableSelect = !packagingRow || packagingRow.packagingTypes === '';
  }

  return {
    packagingComponentsForBrand,
    allPackagingTypes,
    selectedPackagingComponents,
    packagingArray,
    packagingRow,
    disableSelect,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  // updatePackagingComponents: (arrayKey, arrayIndex, fieldKey, value) =>
  //     dispatch(actions.changeFieldArray(arrayKey, arrayIndex, fieldKey, value)),
  updatePackagingComponents: (arrayKey, arrayIndex, fieldKey, value) => (
    dispatch(actions.updatePackagingComponents(arrayIndex, value))
  ),
  addCustomPackagingComponent: newOptions => dispatch(actions.changeField(
    null,
    ownProps.fieldKey,
    newOptions,
  )),
});

const styledPackagingComponents = withStyles(styles, { withTheme: true })(PackagingComponentsList);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(styledPackagingComponents);
