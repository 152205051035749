
import { useSelector } from 'react-redux';

import { STEP_KEYS } from '../../constants';
import * as selectors from '../selectors';

const checkFieldForDuplicationError = (
  fieldKey,
  arrayKey,
  arrayIndex,
  mappedValuesByField,
  value,
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const storageLocations = useSelector(selectors.getStorageLocations);
  let valuesForField = mappedValuesByField[fieldKey];
  let duplicateValueForField = valuesForField && valuesForField.length > 1 && valuesForField.filter(v => v.trim() !== '' && v === value.toLowerCase().trim()).length > 1;
  // if the site is updated we need to make the same check as below but must set values for comaprison
  if (arrayKey === STEP_KEYS.STORAGELOCATIONS && fieldKey === 'site') {
    const currentLocationName = storageLocations[arrayIndex].name;
    valuesForField = mappedValuesByField.name;
    const locationSite = storageLocations[arrayIndex].site;
    const matchingStorageLocations = storageLocations.filter(location => location.name.trim() !== '' && location.name.trim() === currentLocationName.trim() && location.site === locationSite);
    duplicateValueForField = matchingStorageLocations.length > 1;
    // need to compare site on storageLocations
  } else if (arrayKey === STEP_KEYS.STORAGELOCATIONS && duplicateValueForField) {
    // site of location that user updated
    const locationSite = storageLocations[arrayIndex].site;
    const matchingStorageLocations = storageLocations.filter(location => location.name.trim() !== '' && location.name.trim() === value.trim() && location.site === locationSite);
    duplicateValueForField = matchingStorageLocations.length > 1;
  }
  return duplicateValueForField;
};

export default checkFieldForDuplicationError;
