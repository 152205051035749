import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { persistor, store } from './store';

import Root from './Root';

import './styles.css';

const App = () => (
  <Provider store={store}>
    <PersistGate
      loading={null}
      persistor={persistor}
    >
      <Root className="root" />
    </PersistGate>
  </Provider>
);

const rootElement = document.getElementById('root');
ReactDOM.render(<App />, rootElement);
