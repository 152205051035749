import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { config } from 'react-spring';
import { Transition } from 'react-spring/renderprops';

import MakerButton from '../Components/MakerButton';
import MakeMoreIcon from '../icons/MakeMore';

import { BRANDS } from '../constants';

const Welcome = ({ classes, setType }) => {
  const selectType = type => () => setType(type);

  // HOTJAR USABILITY
  useEffect(() => {
    const { hj } = window || {};
    hj('stateChange', 'welcome');
  });

  return (
    <Grid
      container
      justify="center"
      spacing={2}
    >
      <Grid
        item
        xs={12}
        className={classes.centered}
      >
        <MakeMoreIcon className={classes.icon} />
      </Grid>
      <Grid
        item
        xs={12}
        className={classes.centered}
      >
        <Typography
          variant="h4"
          gutterBottom
        >
          build your ekos_system
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        className={classes.centered}
      >
        <Typography
          variant="body1"
          gutterBottom
        >
          To get started, select the type of product that you make the
          most of.
        </Typography>
      </Grid>
      <Grid
        item
        xs={10}
        sm={8}
        md={4}
        className={classes.centered}
      >
        <Grid
          container
          spacing={3}
          justify="center"
        >
          <Transition
            items={BRANDS}
            keys={t => t.variant}
            from={{
              transform: 'scaleX(0)',
              opacity: 0,
              height: 'auto',
              width: 0,
            }}
            enter={{
              transform: 'scaleX(1)',
              opacity: 1,
              height: 'auto',
              width: '100%',
            }}
            leave={{
              transform: 'scaleY(0)',
              opacity: 0,
              height: 'auto',
              width: 0,
            }}
            trail={200}
            config={config.default}
          >
            {type => ({ width, ...restProps }) => (
              <Grid
                item
                xs={12}
                style={restProps}
              >
                <MakerButton
                  className={classes.makerButton}
                  variant={type.variant}
                  label={type.label}
                  onClick={selectType(type.variant)}
                />
              </Grid>
            )}
          </Transition>
        </Grid>
      </Grid>
    </Grid>
  );
};

Welcome.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  setType: PropTypes.func,
};

Welcome.defaultProps = {
  classes: {},
  setType: () => {},
};

const styles = {
  centered: {
    textAlign: 'center',
  },
  makerButton: {
    transformOrigin: 'left',
    willChange: 'transform, opacity',
  },
  icon: {
    width: '20rem',
    height: '4rem',
    marginTop: '2rem',
  },
};

export default withStyles(styles)(Welcome);
