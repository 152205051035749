import { isEqual } from 'lodash';

import * as types from './types';
import * as errorMessages from '../config/errorMessages';

import {
  PRODUCT_CLASS,
  EQUIPMENT_TYPES,
  GRAVITY_UOM,
  UOM,
  EQUIPMENT_SYSTEMS,
  STEP_KEYS,
  ALL_PACKAGING_COMPONENTS,
} from '../constants';

import getPrimaryPalette from '../config/themes';

import { fetchAndFormatAllPackagingTypes, formatPackagingTypesForBrand } from '../config/packagingTypes/packagingTypeUtils';

const initialState = {
  brand: '',
  form: {
    equipmentTypes: EQUIPMENT_TYPES,
    packagingComponents: ALL_PACKAGING_COMPONENTS,
    packagingTypes: fetchAndFormatAllPackagingTypes(),
    productClass: PRODUCT_CLASS,
    gravityUom: GRAVITY_UOM,
    uom: UOM,
    equipmentSystems: EQUIPMENT_SYSTEMS,
  },
  primaryPalette: getPrimaryPalette(),
  fileBuilding: false,
  emailSending: false,
  emailSent: false,
  logo: {
    content: '',
    fileName: '',
    type: '',
  },
  floorplan: {
    content: '',
    fileName: '',
    type: '',
  },
  error: {},
  showRequiredFieldErrors: false,
};

function reducer(state = initialState, action) {
  const { packaging, products } = state.form;

  switch (action.type) {
    case types.TOGGLE_REQUIRED_ERRORS: {
      const { showRequiredFieldErrors } = state;

      return {
        ...state,
        showRequiredFieldErrors: !showRequiredFieldErrors,
      };
    }
    case types.UPDATE_BRAND: {
      const packagingTypes = formatPackagingTypesForBrand(action.brand);

      return {
        ...state,
        brand: action.brand,
        form: {
          ...state.form,
          packagingTypes,
        },
        primaryPalette: getPrimaryPalette(action.brand),
      };
    }

    case types.UPDATE_PACKAGING_TYPES: {
      const packagingWithUpdatedType = [...packaging];

      const originalPackagingType = packaging[action.arrayIndex];

      packagingWithUpdatedType.splice(action.arrayIndex, 1, action.value);

      // need this check in case the user has not clicked on the products step yet
      if (products) {
        const productsWithUpdatedType = products.map(product => {
          const packagingTypeIdx = product.productPackagingTypes
            .findIndex(type => (
              type.rowguid === originalPackagingType.packagingTypeRowguid
            ));

          if (packagingTypeIdx > -1) {
            const formattedPackagingType = {
              packagingType: action.value.packagingTypes,
              rowguid: action.value.packagingTypeRowguid,
              packagingComponents: action.value.packagingComponents.map(component => ({
                ...component,
                isProductSpecific: false,
              })),
            };

            const productWithUpdatedPackaging = {
              ...product,
            };

            productWithUpdatedPackaging.productPackagingTypes
              .splice(action.arrayIndex, 1, formattedPackagingType);

            return productWithUpdatedPackaging;
          }

          return product;
        });

        return {
          ...state,
          form: {
            ...state.form,
            packaging: packagingWithUpdatedType,
            products: productsWithUpdatedType,
          },
        };
      }

      return {
        ...state,
        form: {
          ...state.form,
          packaging: packagingWithUpdatedType,
        },
      };
    }

    case types.UPDATE_PACKAGING_COMPONENTS: {
      const packagingWithUpdatedComponents = [...packaging];

      const updatedPackagingType = {
        ...packaging[action.arrayIndex],
        packagingComponents: action.value,
      };

      packagingWithUpdatedComponents.splice(action.arrayIndex, 1, updatedPackagingType);

      if (products) {
        const productsWithUpdatedComponents = products.map(product => {
          const packagingTypeIdx = product.productPackagingTypes
            .findIndex(type => (
              type.rowguid === updatedPackagingType.packagingTypeRowguid
            ));

          if (packagingTypeIdx > -1) {
            const packagingTypeToUpdate = { ...product.productPackagingTypes[packagingTypeIdx] };

            // the packaging components array on the original packaging type doesn't include the productSpecific field
            const removedProdSpecific = packagingTypeToUpdate.packagingComponents.map(component => {
              return {
                label: component.label,
                value: component.tempId,
                tempId: component.tempId,
                quantity: component.quantity,
              };
            });

            if (isEqual(removedProdSpecific, packaging[action.arrayIndex].packagingComponents)) {
              const updatedPackagingComponents = action.value.map(component => {
                const componentMatch = packagingTypeToUpdate.packagingComponents
                  .find(selectedComp => selectedComp.tempId === component.tempId);

                return {
                  ...component,
                  value: component.tempId,
                  isProductSpecific: componentMatch ? componentMatch.isProductSpecific : false,
                };
              });

              packagingTypeToUpdate.packagingComponents = updatedPackagingComponents;

              const updatedProductPackaging = [...product.productPackagingTypes];
              updatedProductPackaging.splice(packagingTypeIdx, 1, packagingTypeToUpdate);

              return {
                ...product,
                productPackagingTypes: updatedProductPackaging,
              };
            }
          }

          return product;
        });

        return {
          ...state,
          form: {
            ...state.form,
            packaging: packagingWithUpdatedComponents,
            products: productsWithUpdatedComponents,
          },
        };
      }

      return {
        ...state,
        form: {
          ...state.form,
          packaging: packagingWithUpdatedComponents,
        },
      };
    }

    case types.DELETE_PACKAGING_TYPE: {
      const updatedPackagingTypes = [...packaging];
      updatedPackagingTypes.splice(action.arrayIndex, 1);

      const packagingTypeToRemove = packaging[action.arrayIndex];

      if (products) {
        const productsWithPackagingTypeRemoved = products.map(product => {
          const packagingTypeIdx = product.productPackagingTypes
            .findIndex(type => (
              type.rowguid === packagingTypeToRemove.packagingTypeRowguid
            ));

          if (packagingTypeIdx > -1) {
            const updatedProduct = {
              ...product,
            };

            updatedProduct.productPackagingTypes.splice(packagingTypeIdx, 1);

            return updatedProduct;
          }

          return product;
        });

        return {
          ...state,
          form: {
            ...state.form,
            packaging: updatedPackagingTypes,
            products: productsWithPackagingTypeRemoved,
          },
        };
      }

      return {
        ...state,
        form: {
          ...state.form,
          packaging: updatedPackagingTypes,
        },
      };
    }

    case types.UPDATE_ROW_IN_ARRAY: {
      const arrayToUpdate = [...state.form[action.arrayKey]];
      arrayToUpdate.splice(action.arrayIndex, 1, action.row);

      return {
        ...state,
        form: {
          ...state.form,
          [action.arrayKey]: arrayToUpdate,
        },
      };
    }

    case types.CHANGE_FIELD: {
      let newForm = state.form;
      if (action.step) {
        newForm = {
          ...state.form,
          [action.step]: {
            ...state.form[action.step],
            [action.key]: action.value,
          },
        };
        // }
      } else {
        newForm = {
          ...state.form,
          [action.key]: action.value,
        };
      }

      return {
        ...state,
        form: newForm,
      };
    }

    case types.ADD_FIELD_ARRAY: {
      const fieldArray = state.form[action.arrayKey] || [];
      fieldArray.push(action.initialFields);
      return {
        ...state,
        form: {
          ...state.form,
          [action.arrayKey]: fieldArray,
        },
      };
    }

    case types.REMOVE_FIELD_ARRAY: {
      const newState = state.form[action.arrayKey];
      newState.splice(action.arrayIndex, 1);
      return {
        ...state,
        form: {
          ...state.form,
          [action.arrayKey]: newState,
        },
      };
    }

    case types.CHANGE_FIELD_ARRAY:
      if (action.value !== null && action.value !== undefined) {
        let newArray = state.form[action.arrayKey] || [];
        if (
          state.form[action.arrayKey]
          && state.form[action.arrayKey].length > 0
        ) {
          newArray = state.form[action.arrayKey].map(
            (item, index) => {
              if (index === action.arrayIndex && action.fieldKey) {
                // these handle default UOMs for BEER, WINE, CIDER product classes
                if (action.fieldKey === 'productClass' && action.value === 'beer') {
                  return {
                    ...item,
                    [action.fieldKey]: action.value,
                    uom: 'Barrel(s)',
                  };
                } if (action.fieldKey === 'productClass' && (action.value === 'wine' || action.value === 'cider')) {
                  return {
                    ...item,
                    [action.fieldKey]: action.value,
                    uom: 'Gallon(s)',
                  };
                } if (action.fieldKey === 'capacity' && state.form[action.arrayKey][index].maxCapacityIsSame) {
                  return {
                    ...item,
                    [action.fieldKey]: action.value,
                    maxCapacity: action.value,
                  };
                  // if maxCapacityIsSame is set to true, update maxCapacity to capacity value
                } if (action.fieldKey === 'maxCapacityIsSame' && action.value) {
                  return {
                    ...item,
                    [action.fieldKey]: action.value,
                    maxCapacity: item.capacity,
                  };
                }
                return {
                  ...item,
                  [action.fieldKey]: action.value,
                };
              }
              return item;
            },
          );
        }
        return {
          ...state,
          form: {
            ...state.form,
            [action.arrayKey]: newArray,
          },
        };
      }
      return state;

    case types.UPDATE_ENTIRE_ARRAY:
      return {
        ...state,
        form: {
          ...state.form,
          [action.arrayKey]: action.updatedArray,
        },
      };

    case types.EXCEL_STARTED:
      return {
        ...state,
        fileBuilding: true,
      };

    case types.EXCEL_COMPLETED:
      return {
        ...state,
        fileBuilding: false,
      };

    case types.LOGO_UPDATED:
      return {
        ...state,
        logo: action.logo,
      };

    case types.FLOORPLAN_UPDATED:
      return {
        ...state,
        floorplan: action.floorplan,
      };

    case types.SENDING_EMAIL:
      return {
        ...state,
        emailSending: action.emailSending,
      };

    case types.EMAIL_SENT:
      return {
        ...state,
        emailSent: action.emailSent,
      };

    case types.SET_VALIDATION_ERROR: {
      const { step } = action;
      // if error message is barrelAndEquipmentNameDuplicate and step is barrels => set equipment
      // step is equipment => set barrels
      if (action.error === errorMessages.barrelAndEquipmentNameDuplicate) {
        return {
          ...state,
          error: {
            ...state.error,
            [STEP_KEYS.BARRELS]: {
              ...state.error.barrels,
              number: action.error,
            },
            [STEP_KEYS.EQUIPMENT]: {
              ...state.error.equipment,
              name: action.error,
            },
          },
        };
      }
      // if error message is equipmentAndLocationNameDuplicate set equipment and location
      if (action.error === errorMessages.equipmentAndLocationNameDuplicate) {
        return {
          ...state,
          error: {
            ...state.error,
            [STEP_KEYS.STORAGELOCATIONS]: {
              ...state.error[step],
              name: action.error,
            },
            [STEP_KEYS.EQUIPMENT]: {
              ...state.error.equipment,
              name: action.error,
            },
          },
        };
      }
      // if error message is barrelAndLocationNameDuplicate set barrel and location
      if (action.error === errorMessages.barrelAndLocationNameDuplicate) {
        return {
          ...state,
          error: {
            ...state.error,
            [STEP_KEYS.STORAGELOCATIONS]: {
              ...state.error.storageLocations,
              name: action.error,
            },
            [STEP_KEYS.BARRELS]: {
              ...state.error.barrels,
              number: action.error,
            },
          },
        };
      }
      return {
        ...state,
        error: {
          ...state.error,
          [action.step]: {
            ...state.error[action.step],
            [action.field]: action.error,
          },
        },
      };
    }

    /* eslint-disable no-param-reassign */
    case types.CLEAR_VALIDATION_ERROR: {
      const errorStateForStep = state.error[action.step];
      // if site on storage locations/equipment/barrels is updated it is expected to clear these errors
      if (errorStateForStep && action.field === 'site') {
        if (errorStateForStep.name === errorMessages.storageNameDuplicates) {
          delete errorStateForStep.name;
        }
        if (errorStateForStep.name === errorMessages.equipmentAndLocationNameDuplicate) {
          delete state.error.storageLocations.name;
          delete state.error.equipment.name;
        }
        if (errorStateForStep.number === errorMessages.barrelAndLocationNameDuplicate
          || errorStateForStep.name === errorMessages.barrelAndLocationNameDuplicate) {
          delete state.error.storageLocations.name;
          delete state.error.barrels.number;
        }
      }
      // need to make more D.R.Y
      if (errorStateForStep && errorStateForStep[action.field]) {
        if (errorStateForStep[action.field] === errorMessages.barrelAndEquipmentNameDuplicate) {
          if (action.step === STEP_KEYS.BARRELS) {
            delete state.error.equipment.name;
          } else if (action.step === STEP_KEYS.EQUIPMENT) {
            delete state.error.barrels.number;
          }
        }
        if (errorStateForStep[action.field] === errorMessages.equipmentAndLocationNameDuplicate) {
          if (action.step === STEP_KEYS.STORAGELOCATIONS) {
            delete state.error.equipment.name;
          } else if (action.step === STEP_KEYS.EQUIPMENT) {
            delete state.error.storageLocations.name;
          }
        }
        if (errorStateForStep[action.field] === errorMessages.barrelAndLocationNameDuplicate) {
          if (action.step === STEP_KEYS.BARRELS) {
            delete state.error.storageLocations.name;
          } else if (action.step === STEP_KEYS.STORAGELOCATIONS) {
            delete state.error.barrels.number;
          }
        }

        delete errorStateForStep[action.field];
      }
      return {
        ...state,
        error: {
          ...state.error,
          [action.step]: {
            ...errorStateForStep,
          },
        },
      };
    }
    /* eslint-disable no-param-reassign */

    default:
      return state;
  }
}

export default reducer;
