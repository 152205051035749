import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const MakeMoreIcon = props => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 150.35 28.77"
    >
      <rect
        fill="#f16649"
        x="79.1"
        width="29.42"
        height="28.77"
      />
      <polygon
        fill="#86bf99"
        points="133.37 0 116.38 28.77 150.35 28.77 133.37 0"
      />
      <ellipse
        fill="#f1be06"
        cx="14.71"
        cy="14.39"
        rx="14.71"
        ry="14.39"
      />
      <polygon
        fill="#008c8c"
        points="62.76 0 45.77 0 37.27 14.39 45.77 28.77 62.76 28.77 71.25 14.39 62.76 0"
      />
    </SvgIcon>
  );
};

export default MakeMoreIcon;
