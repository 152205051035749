import React from 'react';
import PropTypes from 'prop-types';

import {
  ExpansionPanelDetails,
  Typography,
  Button,
  Grid,
  Checkbox,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import { Info, Edit } from '@material-ui/icons';

const ReadOnlyPackagingComponents = ({
  classes,
  packaging,
  toggleProductSpecific,
  toggleEditMode,
}) => {
  if (packaging.rowguid === 'userPackTypeNotListed') {
    return null;
  }

  return (
    <ExpansionPanelDetails>
      <Grid container>
        <Grid
          item
          xs={12}
        >
          <Grid
            container
            className={classes.packagingComponentsHeader}
          >
            <Grid
              item
              xs={8}
            >
              <Typography
                variant="caption"
                className={classes.label}
              >
                Packaging Components
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              className={classes.productSpecificGrid}
            >
              <Typography
                variant="caption"
                className={classes.label}
              >
                Product Specific
              </Typography>
              <Tooltip
                title="Packaging component is specific to this product"
                enterTouchDelay={300}
                placement="right-start"
              >
                <Info className={classes.tooltipIcon} />
              </Tooltip>
            </Grid>
            <Grid
              item
              xs={1}
            />
          </Grid>
        </Grid>
        {packaging.packagingComponents.map(component => (
          <Grid
            key={component.label}
            item
            xs={12}
            className={classes.productSpecificGrid}
          >
            <Grid container>
              <Grid
                item
                xs={1}
              >
                <Typography>{component.quantity}</Typography>
              </Grid>
              <Grid
                item
                xs={7}
              >
                <Typography>{component.label}</Typography>
              </Grid>
              <Grid
                item
                xs={3}
                className={classes.productSpecificGrid}
              >
                <Checkbox
                  checked={component.isProductSpecific}
                  onChange={e => toggleProductSpecific(packaging.rowguid, component.tempId, e)}
                />
              </Grid>
              <Grid
                item
                xs={1}
              />
            </Grid>
          </Grid>
        ))}

        <Grid
          item
          xs={12}
          className={classes.editButtonContainer}
        >
          <Button
            variant="text"
            className={classes.editButton}
            onClick={() => toggleEditMode(packaging.rowguid)}
          >
            <Edit className={classes.editIcon} />
            <Typography variant="body2">Edit Packaging Components</Typography>
          </Button>
        </Grid>

      </Grid>
    </ExpansionPanelDetails>
  );
};

const styles = theme => ({
  packagingComponentsHeader: {
    margin: '8px 0',
  },
  tooltipIcon: {
    color: theme.palette.text.secondary,
    height: 18,
    width: 18,
    marginLeft: 8,
  },
  productSpecificGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
  addButton: {
    padding: '8px 0',
  },
  addIcon: {
    color: 'black',
    marginRight: 5,
    minWidth: 24,
  },
  editButtonContainer: {
    marginTop: 64,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  editButton: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'none',
  },
  editIcon: {
    marginRight: 8,
    height: 20,
    width: 20,
  },
});

ReadOnlyPackagingComponents.propTypes = {
  packaging: PropTypes.shape(),
  classes: PropTypes.objectOf(PropTypes.string),
  toggleEditMode: PropTypes.func,
  toggleProductSpecific: PropTypes.func,
};

ReadOnlyPackagingComponents.defaultProps = {
  packaging: {},
  classes: {},
  toggleEditMode: () => {},
  toggleProductSpecific: () => {},
};

export default withStyles(styles)(ReadOnlyPackagingComponents);
