const nonAlcoholicPackagingTypes = [
  {
    label: 'Single - 12oz - Bottle',
    rowguid: 'CF298341-9987-E311-AB85-D4AE5266E0C4',
    category: 'BOTTLE',
    defaultComponents: [
      {
        label: '12oz Bottle',
        defaultQuantity: 1,
        tempId: 'b75e20-5c-8338-63b-df43330d12c',
      },
      {
        label: 'Bottle Cap',
        defaultQuantity: 1,
        tempId: '7e80d30-665b-7aa1-fa1d-2dfeb1ff5c8',
      },
    ],
  },
  {
    label: 'Single - 355mL - Bottle',
    rowguid: '4F9C8333-6DA2-E411-BBB5-D4AE5266E0C4',
    category: 'BOTTLE',
    defaultComponents: [
      {
        label: '355mL Bottle',
        defaultQuantity: 1,
        tempId: 'c6c31-8136-add3-c185-d6a67e0d433',
      },
      {
        label: 'Bottle Cap',
        defaultQuantity: 1,
        tempId: '7e80d30-665b-7aa1-fa1d-2dfeb1ff5c8',
      },
    ],
  },
  {
    label: 'Case - 6x - 12oz - Bottle',
    rowguid: '39250E57-4C09-E411-A60A-D4AE5266E0C4',
    category: 'BOTTLE',
    defaultComponents: [
      {
        label: '12oz Bottle',
        defaultQuantity: 6,
        tempId: 'b75e20-5c-8338-63b-df43330d12c',
      },
      {
        label: 'Bottle Cap',
        defaultQuantity: 6,
        tempId: '7e80d30-665b-7aa1-fa1d-2dfeb1ff5c8',
      },
    ],
  },
  {
    label: 'Case - 6x - 355mL - Bottle',
    rowguid: 'CFF8709D-6DA2-E411-BBB5-D4AE5266E0C4',
    category: 'BOTTLE',
    defaultComponents: [
      {
        label: '355mL Bottle',
        defaultQuantity: 6,
        tempId: 'c6c31-8136-add3-c185-d6a67e0d433',
      },
      {
        label: 'Bottle Cap',
        defaultQuantity: 6,
        tempId: '7e80d30-665b-7aa1-fa1d-2dfeb1ff5c8',
      },
    ],
  },
  {
    label: 'Case - 12x - 12oz - Bottle',
    rowguid: '19E9A078-98D0-E311-A60A-D4AE5266E0C4',
    category: 'BOTTLE',
    defaultComponents: [
      {
        label: '12oz Bottle',
        defaultQuantity: 12,
        tempId: 'b75e20-5c-8338-63b-df43330d12c',
      },
      {
        label: 'Bottle Cap',
        defaultQuantity: 12,
        tempId: '7e80d30-665b-7aa1-fa1d-2dfeb1ff5c8',
      },
    ],
  },
  {
    label: 'Case - 12x - 355mL - Bottle',
    rowguid: 'BA58083C-B96E-EA11-A9CF-0AC016C0647C',
    category: 'BOTTLE',
    defaultComponents: [
      {
        label: '355mL Bottle',
        defaultQuantity: 12,
        tempId: 'c6c31-8136-add3-c185-d6a67e0d433',
      },
      {
        label: 'Bottle Cap',
        defaultQuantity: 12,
        tempId: '7e80d30-665b-7aa1-fa1d-2dfeb1ff5c8',
      },
    ],
  },
  {
    label: 'Single - 12oz - Can',
    rowguid: 'FF596B40-C432-E411-A676-D4AE5266E0C4',
    category: 'CAN',
    defaultComponents: [
      {
        label: '12oz Can',
        defaultQuantity: 1,
        tempId: 'ce23d7-2f53-8b2-fd7f-7bf35dc82c1',
      },
      {
        label: 'Can Lid',
        defaultQuantity: 1,
        tempId: '6320d1a-25f-f6fa-26c3-8f31517164b4',
      },
    ],
  },
  {
    label: 'Single - 355mL - Can',
    rowguid: '477E6252-6DA2-E411-BBB5-D4AE5266E0C4',
    category: 'CAN',
    defaultComponents: [
      {
        label: '355mL Can',
        defaultQuantity: 1,
        tempId: 'b10125c-d77-cad-bd1b-bb28c72c7a',
      },
      {
        label: 'Can Lid',
        defaultQuantity: 1,
        tempId: '6320d1a-25f-f6fa-26c3-8f31517164b4',
      },
    ],
  },
  {
    label: 'Case - 4x - 12oz - Can',
    rowguid: 'D518F76B-2FC8-E411-94E5-E4C8A6DECC7D',
    category: 'CAN',
    defaultComponents: [
      {
        label: '12oz Can',
        defaultQuantity: 4,
        tempId: 'ce23d7-2f53-8b2-fd7f-7bf35dc82c1',
      },
      {
        label: 'Can Lid',
        defaultQuantity: 4,
        tempId: '6320d1a-25f-f6fa-26c3-8f31517164b4',
      },
    ],
  },
  {
    label: 'Case - 4x - 355mL - Can',
    rowguid: 'D837CDCA-90CD-E411-94E5-E4C8A6DECC7D',
    category: 'CAN',
    defaultComponents: [
      {
        label: '355mL Can',
        defaultQuantity: 4,
        tempId: 'b10125c-d77-cad-bd1b-bb28c72c7a',
      },
      {
        label: 'Can Lid',
        defaultQuantity: 4,
        tempId: '6320d1a-25f-f6fa-26c3-8f31517164b4',
      },
    ],
  },
  {
    label: 'Case - 6x - 12oz - Can',
    rowguid: 'A2790448-6537-E411-9329-D4AE5266E0C4',
    category: 'CAN',
    defaultComponents: [
      {
        label: '12oz Can',
        defaultQuantity: 6,
        tempId: 'ce23d7-2f53-8b2-fd7f-7bf35dc82c1',
      },
      {
        label: 'Can Lid',
        defaultQuantity: 6,
        tempId: '6320d1a-25f-f6fa-26c3-8f31517164b4',
      },
    ],
  },
  {
    label: 'Case - 6x - 355mL - Can',
    rowguid: 'EE3F948B-6DA2-E411-BBB5-D4AE5266E0C4',
    category: 'CAN',
    defaultComponents: [
      {
        label: '355mL Can',
        defaultQuantity: 6,
        tempId: 'b10125c-d77-cad-bd1b-bb28c72c7a',
      },
      {
        label: 'Can Lid',
        defaultQuantity: 6,
        tempId: '6320d1a-25f-f6fa-26c3-8f31517164b4',
      },
    ],
  },
  {
    label: 'Case - 4x6 - 12oz - Can',
    rowguid: '71AB6CA7-949D-E411-94E5-E4C8A6DECC7D',
    category: 'CAN',
    defaultComponents: [
      {
        label: '12oz Can',
        defaultQuantity: 24,
        tempId: 'ce23d7-2f53-8b2-fd7f-7bf35dc82c1',
      },
      {
        label: 'Can Lid',
        defaultQuantity: 24,
        tempId: '6320d1a-25f-f6fa-26c3-8f31517164b4',
      },
    ],
  },
  {
    label: 'Case - 4x6 - 355mL - Can',
    rowguid: 'CC94A696-6735-E511-A6D3-C8E90A8CADF6',
    category: 'CAN',
    defaultComponents: [
      {
        label: '355mL Can',
        defaultQuantity: 24,
        tempId: 'b10125c-d77-cad-bd1b-bb28c72c7a',
      },
      {
        label: 'Can Lid',
        defaultQuantity: 24,
        tempId: '6320d1a-25f-f6fa-26c3-8f31517164b4',
      },
    ],
  },
  {
    label: 'Case - 6x4 - 12oz - Can',
    rowguid: 'D64A5E91-2FC8-E411-94E5-E4C8A6DECC7D',
    category: 'CAN',
    defaultComponents: [
      {
        label: '12oz Can',
        defaultQuantity: 24,
        tempId: 'ce23d7-2f53-8b2-fd7f-7bf35dc82c1',
      },
      {
        label: 'Can Lid',
        defaultQuantity: 24,
        tempId: '6320d1a-25f-f6fa-26c3-8f31517164b4',
      },
    ],
  },
  {
    label: 'Case - 6x4 - 355mL - Can',
    rowguid: 'A7A78489-B96E-EA11-A9CF-0AC016C0647C',
    category: 'CAN',
    defaultComponents: [
      {
        label: '355mL Can',
        defaultQuantity: 24,
        tempId: 'b10125c-d77-cad-bd1b-bb28c72c7a',
      },
      {
        label: 'Can Lid',
        defaultQuantity: 24,
        tempId: '6320d1a-25f-f6fa-26c3-8f31517164b4',
      },
    ],
  },
  {
    label: 'Keg - 1/2bbl',
    rowguid: 'E7521AF6-9487-E311-AB85-D4AE5266E0C4',
    category: 'KEG',
    defaultComponents: [
      {
        label: 'Keg Shell - 1/2bbl',
        defaultQuantity: 1,
        tempId: '427ec6-40c-e4a-fb27-224acf5fcad',
      },
      {
        label: 'Keg Cap',
        defaultQuantity: 1,
        tempId: '7b143ec-5ad-2dd-172a-c5106d0f6d4',
      },
      {
        label: 'Keg Collar',
        defaultQuantity: 1,
        tempId: '128352-36b5-386-c82c-bfdfadc55c',
      },
    ],
  },
  {
    label: 'Keg - 1/6bbl',
    rowguid: 'EA2A8914-4ED1-E311-A60A-D4AE5266E0C4',
    category: 'KEG',
    defaultComponents: [
      {
        label: 'Keg Shell - 1/6bbl',
        defaultQuantity: 1,
        tempId: 'be38b32-bdd-816e-f4ff-8220ff53ad01',
      },
      {
        label: 'Keg Cap',
        defaultQuantity: 1,
        tempId: '7b143ec-5ad-2dd-172a-c5106d0f6d4',
      },
      {
        label: 'Keg Collar',
        defaultQuantity: 1,
        tempId: '128352-36b5-386-c82c-bfdfadc55c',
      },
    ],
  },
  {
    label: 'Keg - 20L',
    rowguid: '3DC0E3DE-B780-E411-BF10-EFBA34DDFD35',
    category: 'KEG',
    defaultComponents: [
      {
        label: 'Keg Shell - 20L',
        defaultQuantity: 1,
        tempId: 'b688c80-47dd-7b60-2bd-08534d4cb2',
      },
      {
        label: 'Keg Cap',
        defaultQuantity: 1,
        tempId: '7b143ec-5ad-2dd-172a-c5106d0f6d4',
      },
      {
        label: 'Keg Collar',
        defaultQuantity: 1,
        tempId: '128352-36b5-386-c82c-bfdfadc55c',
      },
    ],
  },
  {
    label: 'Keg - 30L',
    rowguid: '7D921DD4-B780-E411-BF10-EFBA34DDFD35',
    category: 'KEG',
    defaultComponents: [
      {
        label: 'Keg Shell - 30L',
        defaultQuantity: 1,
        tempId: 'd534776-ddbd-e78-7ce-fa647ec5c42f',
      },
      {
        label: 'Keg Cap',
        defaultQuantity: 1,
        tempId: '7b143ec-5ad-2dd-172a-c5106d0f6d4',
      },
      {
        label: 'Keg Collar',
        defaultQuantity: 1,
        tempId: '128352-36b5-386-c82c-bfdfadc55c',
      },
    ],
  },
  {
    label: 'Keg - 50L',
    rowguid: '40C6171A-1B22-E411-818F-D4AE5266E0C4',
    category: 'KEG',
    defaultComponents: [
      {
        label: 'Keg Shell - 50L',
        defaultQuantity: 1,
        tempId: 'a760635-bf41-17ea-eab3-8e8504be27',
      },
      {
        label: 'Keg Cap',
        defaultQuantity: 1,
        tempId: '7b143ec-5ad-2dd-172a-c5106d0f6d4',
      },
      {
        label: 'Keg Collar',
        defaultQuantity: 1,
        tempId: '128352-36b5-386-c82c-bfdfadc55c',
      },
    ],
  },
];

export default nonAlcoholicPackagingTypes;
