import React from 'react';
import PropTypes from 'prop-types';
import {
  ButtonBase,
  Paper,
  Typography,
  withStyles,
} from '@material-ui/core';
import { lighten } from '@material-ui/core/styles/colorManipulator';

import BeerIcon from '../icons/BeerIcon';
import CiderIcon from '../icons/CiderIcon';
import WineIcon from '../icons/WineIcon';
import FoodIcon from '../icons/FoodIcon';
import NonAlcoholicIcon from '../icons/NonAlcoholicIcon';
import RoasterIcon from '../icons/RoasterIcon';

const MakerButton = ({
  variant,
  classes,
  label,
  ...rest
}) => {
  let icon;
  switch (variant) {
    case 'beer':
      icon = <BeerIcon className={classes.icon} />;
      break;

    case 'food':
      icon = <FoodIcon className={classes.icon} />;
      break;

    case 'non-alcoholic':
      icon = <NonAlcoholicIcon className={classes.icon} />;
      break;

    case 'wine':
      icon = <WineIcon className={classes.icon} />;
      break;

    case 'cider':
      icon = <CiderIcon className={classes.icon} />;
      break;

    case 'roaster':
      icon = <RoasterIcon className={classes.icon} />;
      break;

    default:
      break;
  }

  return (
    <ButtonBase
      {...rest}
      className={classes.button}
    >
      <Paper className={`${classes[variant]} ${classes.paper}`}>
        {icon}
        <Typography
          variant="h6"
          className={classes.label}
        >
          {label}
        </Typography>
      </Paper>
    </ButtonBase>
  );
};

MakerButton.propTypes = {
  variant: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  label: PropTypes.string,
};

MakerButton.defaultProps = {
  variant: '',
  classes: {},
  label: '',
};

const styles = theme => ({
  button: {
    width: '100%',
  },
  label: {
    color: theme.palette.grey[700],
  },
  icon: {
    height: '3rem',
    width: '3rem',
    marginRight: '24px',
    marginLeft: '24px',
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '1rem',
    boxShadow: theme.shadows[4],
    transition: `all 300ms ${theme.transitions.easing.easeInOut}`,
    '&:hover': {
      boxShadow: theme.shadows[8],
    },
  },
  beer: {
    color: theme.palette.brewmaster.main,
    '&:hover': {
      backgroundColor: lighten(theme.palette.brewmaster.main, 0.75),
    },
    transition: `all 300ms ${theme.transitions.easing.easeInOut}`,
  },
  cider: {
    color: theme.palette.cider.main,
    '&:hover': {
      backgroundColor: lighten(theme.palette.cider.main, 0.75),
    },
    transition: `all 300ms ${theme.transitions.easing.easeInOut}`,
  },
  wine: {
    color: theme.palette.wine.main,
    '&:hover': {
      backgroundColor: lighten(theme.palette.wine.main, 0.75),
    },
    transition: `all 300ms ${theme.transitions.easing.easeInOut}`,
  },
  food: {
    color: theme.palette.maker.main,
    '&:hover': {
      backgroundColor: lighten(theme.palette.maker.main, 0.75),
    },
    transition: `all 300ms ${theme.transitions.easing.easeInOut}`,
  },
  'non-alcoholic': {
    color: theme.palette.maker.main,
    '&:hover': {
      backgroundColor: lighten(theme.palette.maker.main, 0.75),
    },
    transition: `all 300ms ${theme.transitions.easing.easeInOut}`,
  },
  roaster: {
    color: theme.palette.maker.main,
    '&:hover': {
      backgroundColor: lighten(theme.palette.maker.main, 0.75),
    },
    transition: `all 300ms ${theme.transitions.easing.easeInOut}`,
  },
});

export default withStyles(styles)(MakerButton);
