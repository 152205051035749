import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import {
  Button, Grid, Hidden, Typography, withStyles,
} from '@material-ui/core';
import { AddCircleOutline, Delete } from '@material-ui/icons';
import { lighten } from '@material-ui/core/styles/colorManipulator';

import * as actions from '../../Form/actions';
import * as selectors from '../../Form/selectors';

import Field from '../../Form/Field';
import IconButton from '../IconButton';
import CopyIcon from '../../icons/CopyIcon';

const EquipmentPage = props => {
  const {
    hasExample,
    classes,
    columns,
    rows,
    tableKey,
    onFieldChange,
    hasErrorMessage,
    errors,
    addNewRow,
    label,
    copyLast,
    removeTableRow,
    updateField,
    addCustomEquipmentSystem,
    systemOptions,
  } = props;
    // conditionally sizing Grid Items on this page
  const getFieldItemWidth = (fieldItemTableKey, fieldKey) => {
    if (fieldItemTableKey === 'equipment') {
      if (fieldKey === 'name' || fieldKey === 'equipmentType' || fieldKey === 'site') {
        return 2;
      }
      if (fieldKey === 'capacityFields') {
        return 3;
      }
      return 1;
    }
    if (fieldKey === 'number') {
      return 2;
    }
    return 3;
  };
  // as a last resort using this to target nested material classes
  const paddedItem = {
    paddingRight: 4,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 8,
  };

  const handleComplexSelectChange = (arrayIndex, fieldKey, value) => {
    updateField(arrayIndex, fieldKey, value);
  };

  const sites = useSelector(selectors.getFacilitySiteOptions);
  const systems = useSelector(selectors.getEquipmentSystemOptions);
  const equipmentTypes = useSelector(selectors.getEquipmentTypeOptions);

  const getFacilitySiteOptions = () => {
    return sites && sites.map(site => ({
      label: site.siteName,
      value: site.siteId,
    }));
  };

  // currently cant pass defaultValue
  // const defaultSiteValue = getFacilitySiteOptions()[0]

  const getOptions = fieldKey => {
    if (fieldKey === 'site') {
      return getFacilitySiteOptions();
    }
    if (fieldKey === 'system') {
      return systems;
    }
    if (fieldKey === 'equipmentType') {
      return equipmentTypes;
    }
  };


  const handleEquipmentSystemAdd = (customEquipmentSystem, arrayIndex) => {
    const isDuplicate = systemOptions.find(option => option.label === customEquipmentSystem);
    if (!isDuplicate) {
      const newSystem = {
        label: customEquipmentSystem,
        value: customEquipmentSystem,
      };

      const updatedEquipmentSystems = [
        ...systemOptions,
        newSystem,
      ];
      addCustomEquipmentSystem(updatedEquipmentSystems);
    }
    handleComplexSelectChange(arrayIndex, 'system', customEquipmentSystem);
  };
  return (
    <>
      <Hidden smDown>
        <Grid
          container
          justify="flex-start"
          className={classes.font}
          spacing={1}
        >
          {
            columns && columns.map(column => (
              <Grid
                item
                md={getFieldItemWidth(tableKey, column.fieldKey)}
                xs={12}
                key={`${tableKey}-${column.header}_header`}
                padding="none"
                className={classes.tableCellHead}
                style={paddedItem}
              >
                {column.header}
              </Grid>
            ))
          }
        </Grid>
        {hasExample && columns && (
        <>
          <Grid
            container
            direction="row"
            spacing={0}
            className={classes.example}
            justify="center"
          >
            <Grid
              item
              xs={12}
            >
              <Typography variant="caption">
                Example
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              justify="flex-start"
              spacing={1}
            >
              {columns.map(column => {
                return (
                  <Grid
                    item
                    md={getFieldItemWidth(tableKey, column.fieldKey)}
                    xs={12}
                    key={`${tableKey}-example-${column.fieldKey}`}
                    className={classes.exampleCell}
                  >
                    {column.example}
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </>
        )}
      </Hidden>
      {rows.map(row => (
        <Grid
          container
          spacing={1}
          key={`${tableKey}-${row.arrayIndex}`}
          justify="flex-start"
        >
          {
            columns && columns.map(column => {
              const {
                fieldKey,
                arrayKey,
                allowCustom,
                value,
                options,
                ...restColumn
              } = column;

              return (
                <Fragment key={`Fragment${tableKey}-${fieldKey}`}>
                  <Grid
                    key={`${tableKey}-${fieldKey}`}
                    item
                    xs={12}
                    md={getFieldItemWidth(tableKey, fieldKey)}
                    style={paddedItem}
                  >
                    <Field
                      key={`${tableKey}-${fieldKey}`}
                      arrayKey={tableKey}
                      arrayIndex={row.arrayIndex}
                      fieldKey={fieldKey}
                      allowCustom={allowCustom}
                      onChange={onFieldChange(
                        row.arrayIndex,
                        fieldKey,
                      )}
                      handleComplexSelectChange={fieldValue => {
                        handleComplexSelectChange(
                          row.arrayIndex,
                          fieldKey,
                          fieldValue,
                        );
                      }}
                      handleEquipmentSystemAdd={handleEquipmentSystemAdd}
                      options={getOptions(fieldKey)}
                      {...restColumn}
                    />
                  </Grid>
                </Fragment>
              );
            })
          }

          <Grid
            item
            xs={1}
            container
            justify="center"
            alignItems="center"
          >
            <IconButton onClick={removeTableRow(row.arrayIndex)}>
              <Delete />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Grid container>
        {
          hasErrorMessage && (
            <Grid
              container
              justify="center"
              className={classes.errorMessage}
            >
              <Grid item>
                {errors.map(msg => (
                  <Typography
                    color="error"
                    key={msg}
                  >
                    {msg}
                  </Typography>
                ))}
              </Grid>
            </Grid>
          )
        }
        <Grid
          item
          xs={12}
          className={classes.addRow}
        >
          <Button
            className={classes.addButton}
            onClick={addNewRow}
            disabled={hasErrorMessage}
          >
            <AddCircleOutline className={classes.addIcon} />
            Add {label}
          </Button>
          <Button
            className={classes.addButton}
            onClick={copyLast}
            disabled={hasErrorMessage}
          >
            <CopyIcon className={classes.copyIcon} />
            Copy Last
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const styles = theme => ({
  addRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: '#008EFF',
  },
  addButton: {
    textTransform: 'none',
    color: '#008EFF',
    marginRight: 25,
  },
  addIcon: {
    marginRight: '6px',
  },
  copyIcon: {
    height: '1em',
    width: '1em',
    fontSize: '1.5rem',
    marginRight: '6px',
  },
  example: {
    padding: '0.4rem',
    borderRadius: '4px',
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
  },
  exampleCell: {
    fontSize: '1rem',
    fontFamily: 'Europa,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
  },
  font: {
    fontFamily: 'Europa,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
  },

  tableCellHead: {
    color: theme.cidermaker_contrast_text,
    fontSize: '0.75rem',
    opacity: 0.54,
  },
});

EquipmentPage.propTypes = {
  addNewRow: PropTypes.func,
  classes: PropTypes.objectOf(PropTypes.string),
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  copyLast: PropTypes.func,
  label: PropTypes.string,
  rows: PropTypes.arrayOf(PropTypes.shape({})),
  tableKey: PropTypes.string,
  addRow: PropTypes.func,
  removeRow: PropTypes.func,
  removeTableRow: PropTypes.func,
  updateField: PropTypes.func,
  hasExample: PropTypes.bool,
  onFieldChange: PropTypes.func,
  hasErrorMessage: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  addCustomEquipmentSystem: PropTypes.func,
  systemOptions: PropTypes.arrayOf(PropTypes.shape({})),
};

EquipmentPage.defaultProps = {
  addNewRow: () => {},
  classes: undefined,
  columns: undefined,
  copyLast: () => {},
  label: '',
  rows: [],
  tableKey: '',
  addRow: () => {},
  removeRow: () => {},
  removeTableRow: () => {},
  updateField: () => {},
  hasExample: false,
  onFieldChange: () => {},
  hasErrorMessage: false,
  errors: [],
  addCustomEquipmentSystem: () => {},
  systemOptions: [],
};

const mapStateToProps = (state, ownProps) => {
  const { form } = state;
  const formStateForTable = form && ownProps.tableKey
    ? form[ownProps.tableKey]
    : null;

  const rows = form[ownProps.tableKey]
        && form[ownProps.tableKey].map((row, index) => {
          return { ...row, arrayIndex: index };
        });
  const systemOptions = form.equipmentSystems;
  return {
    rows,
    formStateForTable,
    systemOptions,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateField: (index, fieldKey, value) => dispatch(
    actions.changeFieldArray(ownProps.tableKey, index, fieldKey, value),
  ),
  addRow: initialFields => dispatch(actions.addRowToArray(ownProps.tableKey, initialFields)),
  removeRow: index => dispatch(actions.removeRowFromArray(ownProps.tableKey, index)),
  // setErrorForStep: (fieldKey, errorMessage) =>
  //     dispatch(
  //         actions.setStepValidationError(
  //             ownProps.tableKey,
  //             fieldKey,
  //             errorMessage,
  //         ),
  //     ),
  clearErrorForStep: fieldKey => dispatch(
    actions.clearStepValidationError(
      ownProps.tableKey,
      fieldKey,
    ),
  ),
  addCustomEquipmentSystem: updatedEquipmentSystems => dispatch(actions.updateEntireArray(
    'equipmentSystems',
    updatedEquipmentSystems,
  )),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EquipmentPage));
