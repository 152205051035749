import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CiderIcon = props => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 245.33 203.82"
    >
      <circle
        cx="24.23"
        cy="20.75"
        r="4.62"
      />{' '}
      <circle
        cx="24.23"
        cy="36.84"
        r="4.62"
      />{' '}
      <circle
        cx="39.42"
        cy="20.75"
        r="4.62"
      />{' '}
      <path d="M44.07,203.82H39.83l-1.5,0c-21.36-.38-26.58-3-28.84-5.49a3.43,3.43,0,0,1,1.18-5.43l18.15-9.22a9.37,9.37,0,0,0,5.6-8.26c.45-12.5,1.44-43.2.87-58.12-.4-10.39-4.77-13.75-11.38-18.85-5.13-4-11.53-8.89-18.07-18.6C-.59,70.27-1.73,54.07,2.43,31.64A224.21,224.21,0,0,1,10.53,1l.35-1H73l.36,1c.81,2.29,19.88,56.27,4.68,78.81C71.52,89.51,65.12,94.44,60,98.4c-6.61,5.1-11,8.46-11.38,18.85-.57,14.9.42,45.61.87,58.12A9.34,9.34,0,0,0,55,183.6l18.21,9.25a3.42,3.42,0,0,1,1.18,5.43c-2.26,2.51-7.48,5.1-28.81,5.49v0Zm-2.13-3h1.27l.84,0c16.38-.21,25.58-1.68,28.13-4.51a.42.42,0,0,0-.16-.68l-.1,0-18.19-9.24a12.28,12.28,0,0,1-7.25-10.83c-.45-12.55-1.44-43.35-.86-58.34.45-11.8,5.78-15.91,12.54-21.12,5-3.82,11.14-8.59,17.42-17.9C88.85,58.43,73.44,10.52,70.89,3H13c-2.55,7.52-18,55.44-4.69,75.12C14.6,87.43,20.78,92.2,25.74,96c6.76,5.21,12.09,9.32,12.55,21.12.57,15-.42,45.8-.87,58.34a12.31,12.31,0,0,1-7.31,10.86L11.88,195.6a.42.42,0,0,0-.16.68c2.55,2.83,11.75,4.3,28.13,4.51l1.48,0Z" />{' '}
      <circle
        cx="104.94"
        cy="20.75"
        r="4.62"
      />{' '}
      <circle
        cx="104.94"
        cy="36.84"
        r="4.62"
      />{' '}
      <circle
        cx="120.13"
        cy="20.75"
        r="4.62"
      />{' '}
      <path d="M124.78,203.82H119v0c-21.34-.39-26.56-3-28.81-5.49a3.42,3.42,0,0,1,1.18-5.43l18.14-9.22a9.35,9.35,0,0,0,5.61-8.26c.45-12.51,1.44-43.22.86-58.12-.4-10.39-4.76-13.75-11.37-18.85-5.14-4-11.53-8.89-18.08-18.6-6.42-9.53-7.57-25.73-3.4-48.16A223.36,223.36,0,0,1,91.24,1l.36-1h62.14l.35,1c.82,2.29,19.88,56.27,4.69,78.81-6.55,9.71-12.94,14.64-18.08,18.6-6.61,5.1-11,8.46-11.37,18.85-.57,14.92.41,45.62.86,58.12a9.34,9.34,0,0,0,5.54,8.23L154,192.85a3.45,3.45,0,0,1,2,2.39,3.4,3.4,0,0,1-.79,3c-2.26,2.51-7.48,5.11-28.85,5.49v0Zm-2.12-3h1.28l.82,0c16.38-.21,25.59-1.68,28.14-4.51a.39.39,0,0,0,.09-.38.38.38,0,0,0-.25-.3l-.11,0-18.19-9.24a12.29,12.29,0,0,1-7.24-10.83c-.46-12.54-1.45-43.33-.87-58.34.45-11.8,5.79-15.91,12.54-21.12,5-3.82,11.15-8.59,17.42-17.9,13.28-19.69-2.14-67.6-4.68-75.12H93.72c-2.54,7.52-18,55.44-4.68,75.12C95.32,87.43,101.5,92.2,106.46,96c6.75,5.21,12.09,9.32,12.54,21.12.58,15-.41,45.79-.86,58.34a12.33,12.33,0,0,1-7.31,10.86L92.59,195.6a.41.41,0,0,0-.25.3.4.4,0,0,0,.1.38c2.55,2.83,11.75,4.3,28.13,4.51l1.51,0Z" />{' '}
      <circle
        cx="185.66"
        cy="20.75"
        r="4.62"
      />{' '}
      <circle
        cx="185.66"
        cy="36.84"
        r="4.62"
      />{' '}
      <circle
        cx="200.85"
        cy="20.75"
        r="4.62"
      />{' '}
      <path d="M205.5,203.82h-5.76v0c-21.34-.39-26.56-3-28.82-5.49a3.43,3.43,0,0,1,1.18-5.43l18.15-9.22a9.38,9.38,0,0,0,5.61-8.26c.45-12.51,1.43-43.22.86-58.12-.4-10.39-4.77-13.75-11.38-18.85-5.13-4-11.52-8.89-18.07-18.6-6.43-9.53-7.57-25.73-3.41-48.16A224.21,224.21,0,0,1,172,1l.35-1h62.14l.36,1c.82,2.29,19.88,56.27,4.69,78.81-6.55,9.71-12.94,14.64-18.08,18.6-6.61,5.1-11,8.46-11.38,18.85-.57,14.9.42,45.61.87,58.12a9.34,9.34,0,0,0,5.54,8.23l18.21,9.25a3.42,3.42,0,0,1,1.18,5.43c-2.26,2.51-7.48,5.11-28.84,5.49v0Zm-2.13-3h1.28l.83,0c16.38-.21,25.58-1.68,28.13-4.51a.42.42,0,0,0-.16-.68l-.1,0-18.19-9.24a12.28,12.28,0,0,1-7.25-10.83c-.45-12.54-1.44-43.34-.86-58.34.45-11.8,5.78-15.91,12.54-21.12,5-3.82,11.14-8.59,17.42-17.9,13.27-19.69-2.14-67.6-4.68-75.12H174.44c-2.54,7.52-18,55.44-4.68,75.12C176,87.43,182.21,92.2,187.18,96c6.75,5.21,12.08,9.32,12.54,21.12.57,15-.42,45.8-.87,58.34a12.31,12.31,0,0,1-7.31,10.86l-18.23,9.26a.42.42,0,0,0-.16.68c2.55,2.83,11.75,4.3,28.13,4.51l1.51,0Z" />
    </SvgIcon>
  );
};

export default CiderIcon;
