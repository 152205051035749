import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const FoodIcon = props => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 161.31 128.99"
    >
      <path d="M13.89,58.69a3.65,3.65,0,1,0,3.65,3.64A3.65,3.65,0,0,0,13.89,58.69Z" />
      <path d="M13.89,71.39A3.65,3.65,0,1,0,17.54,75,3.66,3.66,0,0,0,13.89,71.39Z" />
      <path d="M25.88,58.69a3.65,3.65,0,1,0,3.65,3.64A3.64,3.64,0,0,0,25.88,58.69Z" />
      <path d="M137.48,38.68l20.76-20.76A10.5,10.5,0,0,0,143.43,3l-38.6,35.65H0V65.49a51.6,51.6,0,0,0,39.52,50.09l-4.31,3.36A5.61,5.61,0,0,0,38.65,129h84a5.62,5.62,0,0,0,3.45-10.05l-4.32-3.36a51.59,51.59,0,0,0,39.52-50.09V38.68Zm8-33.48a7.5,7.5,0,1,1,10.6,10.6L133.76,38.16H109.82Zm12.8,36.48v7.6H3v-7.6Zm-34,79.62a2.62,2.62,0,0,1-1.6,4.69h-84a2.62,2.62,0,0,1-1.6-4.69l6.36-4.94a51.48,51.48,0,0,0,8.1.64H109.8a51.48,51.48,0,0,0,8.1-.64Zm-6.19-8L80.7,114h0l-37.43-.72A48.58,48.58,0,0,1,3,65.49V51.28H158.31V65.49A48.58,48.58,0,0,1,118.07,113.28Z" />
    </SvgIcon>
  );
};

export default FoodIcon;
