import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { sortBy } from 'lodash';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
  withStyles,
  Typography,
} from '@material-ui/core';
import { Info } from '@material-ui/icons';

import * as actions from '../../Form/actions';
import { FIELD_TYPES } from '../../constants';

const EquipmentCapacityComponent = ({
  classes,
  fields,
  standardProps,
  arrayIndex,
  updateField,
  currentCapacityFields,
}) => {
  const [capacityFields, setCapacityField] = useState(currentCapacityFields);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    updateField(arrayIndex, 'capacityFields', capacityFields);
  }, [capacityFields]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const capacityFieldChange = fieldKey => e => {
    const updatedCapacityField = { ...capacityFields };
    if (capacityFields.maxCapacityIsSame && fieldKey === 'capacity') {
      updatedCapacityField[fieldKey] = e.target.value;
      updatedCapacityField.maxCapacity = e.target.value;
    } else {
      updatedCapacityField[fieldKey] = fieldKey === 'maxCapacityIsSame' ? e.target.checked : e.target.value;
    }
    setCapacityField(updatedCapacityField);
  };

  const component = fields.map(field => {
    const {
      type,
      fieldKey,
      placeholder,
      options,
    } = field;

    let menuItems = [];

    if (options && options.length > 0) {
      menuItems = sortBy(options, 'label').map(item => (
        <MenuItem
          key={item.value}
          value={item.value}
        >
          {item.value}
        </MenuItem>
      ));
    }
    const showMaxCapacityField = fieldKey === 'maxCapacity' ? !capacityFields.maxCapacityIsSame : true;
    const showAdornment = !capacityFields.maxCapacityIsSame && fieldKey === 'maxCapacity';
    const getItemWidth = key => {
      if (!capacityFields.maxCapacityIsSame) {
        return key === 'maxCapacity' ? 5 : 3;
      }
      return 6;
    };
    switch (type) {
      case FIELD_TYPES.CHECKBOX:
        return (
          <Fragment key={fieldKey}>
            {capacityFields.maxCapacityIsSame ? (
              <Grid
                item
                xs={12}
              >
                <FormControlLabel
                  key={fieldKey}
                  control={(
                    <Checkbox
                      color="primary"
                      checked={capacityFields.maxCapacityIsSame}
                      onChange={capacityFieldChange(fieldKey)}
                    />
                                  )}
                  label={(
                    <Typography
                      variant="caption"
                      color="secondary"
                    >{field.label}
                    </Typography>
)}
                />
                <Tooltip
                  title="Equipment is advertised as having a specific capacity. However, if you know that its true 'max' capacity is larger, you can note it here."
                  enterTouchDelay={300}
                  placement="right-start"
                >
                  <Info className={classes.tooltipIcon} />
                </Tooltip>
              </Grid>
            ) : (
              <Grid
                item
                container
                xs={12}
                spacing={0}
                alignItems="flex-end"
                className={classes.maxCapacityToolTip}
              >
                <Grid
                  item
                  xs={6}
                >
                  <Typography
                    variant="caption"
                    color="secondary"
                  >Standard
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  xs={6}
                  spacing={0}
                  alignItems="flex-end"
                >
                  <Grid
                    item
                    xs={4}
                  >
                    <Typography
                      variant="caption"
                      color="secondary"
                    >Max
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                  >
                    <Tooltip
                      title="Equipment is advertised as having a specific capacity. However, if you know that its true 'max' capacity is larger, you can note it here."
                      enterTouchDelay={300}
                      placement="right-start"
                    >
                      <Info className={classes.tooltipIcon} />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Fragment>
        );
      case FIELD_TYPES.NUMBER:
        return (
          <Fragment key={fieldKey}>
            {showMaxCapacityField && (
            <Grid
              item
              xs={getItemWidth(fieldKey)}
            >
              <TextField
                key={fieldKey}
                // {...standardProps}
                type={type}
                placeholder={placeholder}
                value={capacityFields[fieldKey]}
                onChange={capacityFieldChange(fieldKey)}
                // default behavior of lint with create-react-app does not check case of props - therefore this throws warning
                inputProps={{ min: '1' }}
                // eslint-disable-next-line
                InputProps = {{
                  startAdornment: showAdornment ? (
                    <InputAdornment
                      classes={{ root: classes.adornmentRoot }}
                      position="start"
                    >to
                    </InputAdornment>
                  ) : null,
                }}
                fullWidth
              />
            </Grid>
            )}
          </Fragment>
        );

      case FIELD_TYPES.SELECT:
        return (
          <Fragment key={fieldKey}>
            <Grid
              item
              xs={capacityFields.maxCapacityIsSame ? 6 : 4}
            >
              <TextField
                key={fieldKey}
                {...standardProps}
                onChange={capacityFieldChange(fieldKey)}
                value={capacityFields.capacityUOM}
                select
                fullWidth
              >
                {menuItems}
              </TextField>
            </Grid>
          </Fragment>
        );
      default:
        return <TextField key={fieldKey} />;
    }
  });

  return (
    <Grid
      container
      direction="row"
      justify="center"
      classes={{ root: classes.capacityFieldRoot }}
    >
      {component}
    </Grid>
  );
};

const styles = theme => ({
  adornmentRoot: {
    marginRight: 15,
  },
  tooltipIcon: {
    color: theme.palette.text.secondary,
    height: 18,
    width: 18,
  },
  capacityFieldRoot: {
    paddingTop: 16,
  },
  maxCapacityToolTip: {
    paddingTop: 10,
    paddingBottom: 10,
  },
});

EquipmentCapacityComponent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  standardProps: PropTypes.shape({}),
  arrayIndex: PropTypes.number,
  updateField: PropTypes.func,
  currentCapacityFields: PropTypes.shape({}),
};

EquipmentCapacityComponent.defaultProps = {
  classes: {},
  fields: [],
  standardProps: {},
  arrayIndex: 0,
  updateField: () => {},
  currentCapacityFields: {},
};

const mapStateToProps = (state, ownProps) => {
  const { form } = state;

  const { arrayKey, arrayIndex, fields } = ownProps;
  const formStateForTable = form && arrayKey
    ? form[arrayKey]
    : null;

  const currentEquipmentRow = formStateForTable[arrayIndex];
  const currentCapacityFields = currentEquipmentRow.capacityFields || {
    capacity: '0',
    maxCapacity: '0',
    maxCapacityIsSame: true,
    // capacityUOM index is determined in the config file
    capacityUOM: fields[2].defaultValue || '',
  };
  return {
    currentCapacityFields,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateField: (index, fieldKey, value) => dispatch(
    actions.changeFieldArray(ownProps.arrayKey, index, fieldKey, value),
  ),
});

const styledEquipmentCapacityComponent = withStyles(styles)(EquipmentCapacityComponent);

export default connect(mapStateToProps, mapDispatchToProps)(styledEquipmentCapacityComponent);
