const foodAndRoasterPackagingTypes = [
  {
    label: 'Single - 12oz - Jar',
    rowguid: '9B146227-B46E-EA11-A9CF-0AC016C0647C',
    category: 'JAR',
    defaultComponents: [
      {
        label: '12oz Jar',
        defaultQuantity: 1,
        tempId: '8d81bf-28d-285-4f43-f3be21fd42f',
      },
      {
        label: 'Jar Lid',
        defaultQuantity: 1,
        tempId: 'e68c76-71e-b8eb-66f-8c063a6cab1',
      },
    ],
  },
  {
    label: 'Case - 12x - 12oz - Jar',
    rowguid: 'D8CE0C34-B46E-EA11-A9CF-0AC016C0647C',
    category: 'JAR',
    defaultComponents: [
      {
        label: '12oz Jar',
        defaultQuantity: 12,
        tempId: '8d81bf-28d-285-4f43-f3be21fd42f',
      },
      {
        label: 'Jar Lid',
        defaultQuantity: 12,
        tempId: 'e68c76-71e-b8eb-66f-8c063a6cab1',
      },
    ],
  },
  {
    label: 'Single - 1oz - Bag',
    rowguid: '3C9B3E46-B46E-EA11-A9CF-0AC016C0647C',
    category: 'BAG',
    defaultComponents: [
      {
        label: '1oz Bag',
        defaultQuantity: 1,
        tempId: '4d53fa5-c143-3c7a-366-45dbf6b130c5',
      },
    ],
  },
  {
    label: 'Case - 24x - 1oz - Bag',
    rowguid: 'F749ED5A-B46E-EA11-A9CF-0AC016C0647C',
    category: 'BAG',
    defaultComponents: [
      {
        label: '1oz Bag',
        defaultQuantity: 24,
        tempId: '4d53fa5-c143-3c7a-366-45dbf6b130c5',
      },
      {
        label: 'Case Box',
        defaultQuantity: 1,
        tempId: '63c4e-6ef3-1d5-bfa-3588a5b85811',
      },
    ],
  },
  {
    label: 'Case - 12x24 - 1oz - Bag',
    rowguid: 'DBD11A86-B46E-EA11-A9CF-0AC016C0647C',
    category: 'BAG',
    defaultComponents: [
      {
        label: '1oz Bag',
        defaultQuantity: 288,
        tempId: '4d53fa5-c143-3c7a-366-45dbf6b130c5',
      },
      {
        label: 'Case Box',
        defaultQuantity: 1,
        tempId: '63c4e-6ef3-1d5-bfa-3588a5b85811',
      },
    ],
  },
  {
    label: 'Single - 16oz - Bag',
    rowguid: '809FA3AA-B46E-EA11-A9CF-0AC016C0647C',
    category: 'BAG',
    defaultComponents: [
      {
        label: '16oz Bag',
        defaultQuantity: 1,
        tempId: 'bb61267-125e-c273-3f2a-426b6d50e76',
      },
    ],
  },
  {
    label: 'Case - 12x - 16oz - Bag',
    rowguid: '265EB4BF-B46E-EA11-A9CF-0AC016C0647C',
    category: 'BAG',
    defaultComponents: [
      {
        label: '16oz Bag',
        defaultQuantity: 12,
        tempId: 'bb61267-125e-c273-3f2a-426b6d50e76',
      },
      {
        label: 'Case Box',
        defaultQuantity: 1,
        tempId: '63c4e-6ef3-1d5-bfa-3588a5b85811',
      },
    ],
  },
  {
    label: 'Single - 1lb - Bag',
    rowguid: '697423CD-B46E-EA11-A9CF-0AC016C0647C',
    category: 'BAG',
    defaultComponents: [
      {
        label: '1lb Bag',
        defaultQuantity: 1,
        tempId: '4d66462-dc61-21d-e16-8277a82260',
      },
    ],
  },
  {
    label: 'Single - 5lb - Bag',
    rowguid: '99A102DD-B46E-EA11-A9CF-0AC016C0647C',
    category: 'BAG',
    defaultComponents: [
      {
        label: '5lb Bag',
        defaultQuantity: 1,
        tempId: '67e64b8-7e31-d0d5-e1-818fe1b252e3',
      },
    ],
  },
  {
    label: 'Single - 12oz - Bottle',
    rowguid: 'CF298341-9987-E311-AB85-D4AE5266E0C4',
    category: 'BOTTLE',
    defaultComponents: [
      {
        label: '12oz Bottle',
        defaultQuantity: 1,
        tempId: 'b75e20-5c-8338-63b-df43330d12c',
      },
      {
        label: 'Bottle Cap',
        defaultQuantity: 1,
        tempId: '7e80d30-665b-7aa1-fa1d-2dfeb1ff5c8',
      },
    ],
  },
  {
    label: 'Single - 355mL - Bottle',
    rowguid: '4F9C8333-6DA2-E411-BBB5-D4AE5266E0C4',
    category: 'BOTTLE',
    defaultComponents: [
      {
        label: '355mL Bottle',
        defaultQuantity: 1,
        tempId: 'c6c31-8136-add3-c185-d6a67e0d433',
      },
      {
        label: 'Bottle Cap',
        defaultQuantity: 1,
        tempId: '7e80d30-665b-7aa1-fa1d-2dfeb1ff5c8',
      },
    ],
  },
  {
    label: 'Case - 6x - 12oz - Bottle',
    rowguid: '39250E57-4C09-E411-A60A-D4AE5266E0C4',
    category: 'BOTTLE',
    defaultComponents: [
      {
        label: '12oz Bottle',
        defaultQuantity: 6,
        tempId: 'b75e20-5c-8338-63b-df43330d12c',
      },
      {
        label: 'Bottle Cap',
        defaultQuantity: 6,
        tempId: '7e80d30-665b-7aa1-fa1d-2dfeb1ff5c8',
      },
    ],
  },
  {
    label: 'Case - 6x - 355mL - Bottle',
    rowguid: 'CFF8709D-6DA2-E411-BBB5-D4AE5266E0C4',
    category: 'BOTTLE',
    defaultComponents: [
      {
        label: '355mL Bottle',
        defaultQuantity: 6,
        tempId: 'c6c31-8136-add3-c185-d6a67e0d433',
      },
      {
        label: 'Bottle Cap',
        defaultQuantity: 6,
        tempId: '7e80d30-665b-7aa1-fa1d-2dfeb1ff5c8',
      },
    ],
  },
  {
    label: 'Case - 12x - 12oz - Bottle',
    rowguid: '19E9A078-98D0-E311-A60A-D4AE5266E0C4',
    category: 'BOTTLE',
    defaultComponents: [
      {
        label: '12oz Bottle',
        defaultQuantity: 12,
        tempId: 'b75e20-5c-8338-63b-df43330d12c',
      },
      {
        label: 'Bottle Cap',
        defaultQuantity: 12,
        tempId: '7e80d30-665b-7aa1-fa1d-2dfeb1ff5c8',
      },
    ],
  },
  {
    label: 'Case - 12x - 355mL - Bottle',
    rowguid: 'BA58083C-B96E-EA11-A9CF-0AC016C0647C',
    category: 'BOTTLE',
    defaultComponents: [
      {
        label: '355mL Bottle',
        defaultQuantity: 12,
        tempId: 'c6c31-8136-add3-c185-d6a67e0d433',
      },
      {
        label: 'Bottle Cap',
        defaultQuantity: 12,
        tempId: '7e80d30-665b-7aa1-fa1d-2dfeb1ff5c8',
      },
    ],
  },
  {
    label: 'Single - 12oz - Can',
    rowguid: 'FF596B40-C432-E411-A676-D4AE5266E0C4',
    category: 'CAN',
    defaultComponents: [
      {
        label: '12oz Can',
        defaultQuantity: 1,
        tempId: 'ce23d7-2f53-8b2-fd7f-7bf35dc82c1',
      },
      {
        label: 'Can Lid',
        defaultQuantity: 1,
        tempId: '6320d1a-25f-f6fa-26c3-8f31517164b4',
      },
    ],
  },
  {
    label: 'Single - 355mL - Can',
    rowguid: '477E6252-6DA2-E411-BBB5-D4AE5266E0C4',
    category: 'CAN',
    defaultComponents: [
      {
        label: '355mL Can',
        defaultQuantity: 1,
        tempId: 'b10125c-d77-cad-bd1b-bb28c72c7a',
      },
      {
        label: 'Can Lid',
        defaultQuantity: 1,
        tempId: '6320d1a-25f-f6fa-26c3-8f31517164b4',
      },
    ],
  },
  {
    label: 'Case - 6x - 12oz - Can',
    rowguid: 'A2790448-6537-E411-9329-D4AE5266E0C4',
    category: 'CAN',
    defaultComponents: [
      {
        label: '12oz Can',
        defaultQuantity: 6,
        tempId: 'ce23d7-2f53-8b2-fd7f-7bf35dc82c1',
      },
      {
        label: 'Can Lid',
        defaultQuantity: 6,
        tempId: '6320d1a-25f-f6fa-26c3-8f31517164b4',
      },
    ],
  },
  {
    label: 'Case - 6x - 355mL - Can',
    rowguid: 'EE3F948B-6DA2-E411-BBB5-D4AE5266E0C4',
    category: 'CAN',
    defaultComponents: [
      {
        label: '355mL Can',
        defaultQuantity: 6,
        tempId: 'b10125c-d77-cad-bd1b-bb28c72c7a',
      },
      {
        label: 'Can Lid',
        defaultQuantity: 6,
        tempId: '6320d1a-25f-f6fa-26c3-8f31517164b4',
      },
    ],
  },
  {
    label: 'Case - 12x - 12oz - Can',
    rowguid: 'FF596B40-C432-E411-A676-D4AE5266E0C4',
    category: 'CAN',
    defaultComponents: [
      {
        label: '12oz Can',
        defaultQuantity: 12,
        tempId: 'ce23d7-2f53-8b2-fd7f-7bf35dc82c1',
      },
      {
        label: 'Can Lid',
        defaultQuantity: 12,
        tempId: '6320d1a-25f-f6fa-26c3-8f31517164b4',
      },
    ],
  },
  {
    label: 'Case - 12x - 355mL - Can',
    rowguid: '251A54CE-851E-E511-A6D3-C8E90A8CADF6',
    category: 'CAN',
    defaultComponents: [
      {
        label: '355mL Can',
        defaultQuantity: 12,
        tempId: 'b10125c-d77-cad-bd1b-bb28c72c7a',
      },
      {
        label: 'Can Lid',
        defaultQuantity: 12,
        tempId: '6320d1a-25f-f6fa-26c3-8f31517164b4',
      },
    ],
  },
];

export default foodAndRoasterPackagingTypes;
