import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';

import Steps from './Form/Steps';
import Welcome from './Form/Welcome';

import getConfigByBrand from './config/getConfigByBrand';

import { updateBrand } from './Form/actions';
import europaFont from './fonts/EuropaRegular.otf';

const europa = {
  fontFamily: 'Europa',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `local(Europa) url(${europaFont}) format('otf')`,
};

const theme = primaryPalette => createMuiTheme({
  palette: {
    primary: primaryPalette,
    secondary: {
      main: '#939399',
      light: '#f0f0f0',
      dark: '#484848',
    },
    error: {
      main: '#e31c3d',
    },
    maker: {
      main: '#4c4ce5',
    },
    cider: {
      main: '#f1be06',
    },
    wine: {
      main: '#d6336e',
    },
    brewmaster: {
      main: '#008eff',
    },
  },
  typography: {
    fontFamily: [
      'Europa',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [europa],
      },
    },
    // had to adjust the height here because the bottom of letters like g, p, q, and j were getting cut off
    MuiInputBase: {
      input: {
        height: '1.2875em',
      },
    },
    // had to adjust the height here so that the selects were the same height as the text fields
    MuiSelect: {
      selectMenu: {
        height: '1.2875em',
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});

const Root = ({
  brand,
  changeBrand,
  primaryPalette,
  startOver,
}) => {
  const steps = getConfigByBrand(brand);
  return (
    <MuiThemeProvider theme={theme(primaryPalette)}>
      {steps ? (
        <Steps
          steps={steps}
          startOver={startOver}
        />
      ) : (
        <Welcome setType={changeBrand} />
      )}
    </MuiThemeProvider>
  );
};

Root.propTypes = {
  brand: PropTypes.string,
  primaryPalette: PropTypes.shape({
    light: PropTypes.string,
    main: PropTypes.string,
    dark: PropTypes.string,
    contrastText: PropTypes.string,
  }),
  startOver: PropTypes.func,
  changeBrand: PropTypes.func,
};

Root.defaultProps = {
  brand: '',
  primaryPalette: {},
  startOver: () => {},
  changeBrand: () => {},
};

const mapStateToProps = state => {
  const { brand, primaryPalette } = state;
  return {
    brand,
    primaryPalette,
  };
};

const mapDispatchToProps = dispatch => ({
  changeBrand: brand => dispatch(updateBrand(brand)),
  startOver: () => dispatch(updateBrand('')),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Root);
