import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';

import {
  Button,
  Checkbox,
  ExpansionPanelDetails,
  Grid,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import { AddCircleOutline, Close, Info } from '@material-ui/icons';

import * as selectors from '../Form/selectors';

import IconButton from '../Components/IconButton';
import ComplexMenu from '../Components/MenuComponents/ComplexMenu';

const EditModePackagingComponents = props => {
  const {
    classes,
    packaging,
    toggleProductSpecific,
    toggleEditMode,
    applyEditMode,
    // props from redux
    packagingComponentsForBrand,
  } = props;

  const formatSelectedComponents = components => {
    return components.map(component => ({
      label: component.label,
      value: component.tempId,
    }));
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [editedPackaging, setEditedPackaging] = useState(cloneDeep(packaging)); // want to make sure that we're not touching the original packaging object
  const [
    selectedComponents,
    setSelectedComponents,
  ] = useState(formatSelectedComponents(packaging.packagingComponents));

  useEffect(() => {
    const formattedComponents = formatSelectedComponents(editedPackaging.packagingComponents);
    setSelectedComponents(formattedComponents);
  }, [editedPackaging]);

  const updateQuantity = (e, componentId) => {
    const updatedPackaging = { ...editedPackaging };

    const componentToUpdate = updatedPackaging.packagingComponents.find(component => (
      component.tempId === componentId
    ));
    componentToUpdate.quantity = e.target.value;

    setEditedPackaging(updatedPackaging);
  };

  const removeComponent = componentId => {
    const updatedPackaging = { ...editedPackaging };

    const componentToRemoveIdx = updatedPackaging.packagingComponents.findIndex(component => (
      component.tempId === componentId
    ));
    if (componentToRemoveIdx > -1) {
      updatedPackaging.packagingComponents.splice(componentToRemoveIdx, 1);
    }

    setEditedPackaging(updatedPackaging);
  };

  const handleComponentSelect = selectedComponent => {
    const updatedPackaging = { ...editedPackaging };
    const componentIdx = updatedPackaging.packagingComponents.findIndex(component => (
      component.tempId === selectedComponent.value
    ));

    if (componentIdx > -1) {
      updatedPackaging.packagingComponents.splice(componentIdx, 1);
    } else {
      updatedPackaging.packagingComponents.push({
        ...selectedComponent,
        quantity: 1,
        isProductSpecific: false,
      });
    }

    setEditedPackaging(updatedPackaging);
  };

  const updateProductSpecific = (packagingTypeRowguid, componentId, e) => {
    const { checked } = e.target;
    const updatedPackaging = { ...editedPackaging };

    const componentIdx = updatedPackaging.packagingComponents.findIndex(component => (
      component.tempId === componentId
    ));
    updatedPackaging.packagingComponents[componentIdx].isProductSpecific = checked;

    setEditedPackaging(updatedPackaging);

    toggleProductSpecific(packagingTypeRowguid, componentId, e);
  };

  return (
    <ExpansionPanelDetails>
      <Grid container>
        <Grid
          item
          xs={12}
        >
          <Grid
            container
            className={classes.packagingComponentsHeader}
          >
            <Grid
              item
              xs={8}
            >
              <Typography
                variant="caption"
                className={classes.label}
              >
                Packaging Components
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              className={classes.productSpecificGrid}
            >
              <Typography
                variant="caption"
                className={classes.label}
              >
                Product Specific
              </Typography>
              <Tooltip
                title="Packaging component is specific to this product"
                enterTouchDelay={300}
                placement="right-start"
              >
                <Info className={classes.tooltipIcon} />
              </Tooltip>
            </Grid>
            {/* placeholder for remove column */}
            <Grid
              item
              xs={1}
            />
          </Grid>
        </Grid>
        {editedPackaging.packagingComponents.map(component => (
          <Grid
            key={component.label}
            item
            xs={12}
            className={classes.productSpecificGrid}
          >
            <Grid container>
              <Grid
                item
                xs={2}
              >
                <TextField
                  type="number"
                  value={component.quantity}
                  onChange={e => updateQuantity(e, component.tempId)}
                  inputProps={{
                    min: '1',
                  }}
                />
              </Grid>
              <Grid
                item
                xs={6}
              >
                <Typography>{component.label}</Typography>
              </Grid>
              <Grid
                item
                xs={3}
                className={classes.productSpecificGrid}
              >
                <Checkbox
                  checked={component.isProductSpecific}
                  onChange={e => updateProductSpecific(packaging.rowguid, component.tempId, e)}
                />
              </Grid>
              <Grid
                item
                xs={1}
              >
                <IconButton onClick={() => removeComponent(component.tempId)}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        ))}

        <Grid
          item
          xs={12}
        >
          <Button
            className={classes.updateButton}
            onClick={e => setAnchorEl(e.currentTarget.parentElement)}
          >
            <AddCircleOutline className={classes.updateIcon} />
            <Typography>Updated Packaging Conponents</Typography>
          </Button>
        </Grid>

        <Grid
          item
          xs={12}
          className={classes.editButtonContainer}
        >
          <Button
            variant="text"
            className={classes.editButton}
            onClick={() => toggleEditMode(packaging.rowguid)}
          >
            <Typography variant="body2">Cancel</Typography>
          </Button>
          <Button
            variant="outlined"
            className={classes.editButton}
            onClick={() => applyEditMode(editedPackaging)}
          >
            <Typography variant="body2">Apply</Typography>
          </Button>
        </Grid>
      </Grid>

      <ComplexMenu
        anchorEl={anchorEl}
        allowSearch
        isMultiSelect
        label="Packaging Components"
        selectedOptions={selectedComponents}
        options={packagingComponentsForBrand}
        optionSelectedCallback={handleComponentSelect}
        closeCallback={() => setAnchorEl(null)}
      />
    </ExpansionPanelDetails>
  );
};

const styles = theme => ({
  packagingComponentsHeader: {
    margin: '8px 0',
  },
  tooltipIcon: {
    color: theme.palette.text.secondary,
    height: 18,
    width: 18,
    marginLeft: 8,
  },
  productSpecificGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
  updateButton: {
    padding: '8px 0',
    textTransform: 'none',
  },
  updateIcon: {
    color: 'black',
    marginRight: 5,
    minWidth: 24,
  },
  editButtonContainer: {
    marginTop: 64,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  editButton: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'none',
  },
  editIcon: {
    marginRight: 8,
    height: 20,
    width: 20,
  },
});

EditModePackagingComponents.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  packaging: PropTypes.shape(),
  toggleProductSpecific: PropTypes.func,
  toggleEditMode: PropTypes.func,
  applyEditMode: PropTypes.func,
  packagingComponentsForBrand: PropTypes.shape(),
};

EditModePackagingComponents.defaultProps = {
  classes: {},
  packaging: {},
  toggleProductSpecific: () => {},
  toggleEditMode: () => {},
  applyEditMode: () => {},
  packagingComponentsForBrand: [],
};

const mapStateToProps = state => {
  const packagingComponentsForBrand = selectors.getPackagingComponentsState(state);

  return {
    packagingComponentsForBrand,
  };
};

// eslint-disable-next-line no-unused-vars
const mapDispatchToProps = (dispatch, ownProps) => ({});

const styledEditModePackagingComponents = withStyles(styles)(EditModePackagingComponents);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(styledEditModePackagingComponents);
