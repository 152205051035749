import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CiderIcon = props => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 220.47 210.99"
    >
      <path d="M1.5,49.39a1.51,1.51,0,0,1-.63-2.87A216.74,216.74,0,0,1,29.72,35.78a1.5,1.5,0,0,1,.85,2.88A212.5,212.5,0,0,0,2.13,49.25,1.6,1.6,0,0,1,1.5,49.39Z" />
      <path d="M59,32l-.51-3a294.22,294.22,0,0,1,31.58-3.75l.19,3A291.11,291.11,0,0,0,59,32Z" />
      <path d="M161.32,32a289,289,0,0,0-30.13-3.63l.21-3A295,295,0,0,1,161.84,29Z" />
      <path d="M219,49.39a1.56,1.56,0,0,1-.63-.14A212.62,212.62,0,0,0,189.8,38.63a1.5,1.5,0,1,1,.85-2.88A217.36,217.36,0,0,1,219.6,46.52a1.51,1.51,0,0,1-.63,2.87Z" />
      <path d="M1.84,83.29l-.68-2.92c8.91-2.05,18.59-3.85,28.76-5.35l.44,3C20.26,79.48,10.67,81.26,1.84,83.29Z" />
      <path d="M59,74.64l-.26-3c11.78-1,24-1.71,36.39-2l.07,3C82.92,73,70.74,73.62,59,74.64Z" />
      <path d="M161.45,74.64c-12.3-1.07-25.09-1.76-38-2l.07-3c13,.28,25.83,1,38.2,2Z" />
      <path d="M218.63,83.29c-8.85-2-18.47-3.82-28.59-5.32l.44-3c10.2,1.5,19.9,3.31,28.82,5.36Z" />
      <path d="M30.15,180.82a1.65,1.65,0,0,1-.43-.06A215.88,215.88,0,0,1,.87,170a1.5,1.5,0,0,1,1.26-2.73,212.15,212.15,0,0,0,28.45,10.6,1.5,1.5,0,0,1-.43,2.93Z" />
      <path d="M110.23,191.89a300.33,300.33,0,0,1-51.6-4.39l.52-3a297.29,297.29,0,0,0,51.08,4.35,297.4,297.4,0,0,0,51.09-4.35l.52,3A300.45,300.45,0,0,1,110.23,191.89Z" />
      <path d="M190.22,180.85a1.5,1.5,0,0,1-.42-2.94,212.11,212.11,0,0,0,28.54-10.62A1.5,1.5,0,0,1,219.6,170a216.33,216.33,0,0,1-28.95,10.77A1.59,1.59,0,0,1,190.22,180.85Z" />
      <path d="M29.92,141.53c-10.18-1.51-19.86-3.31-28.76-5.36l.68-2.92c8.82,2,18.42,3.81,28.52,5.31Z" />
      <path d="M103.72,147c-15.31-.16-30.44-.88-45-2.15l.26-3c14.45,1.26,29.5,2,44.74,2.14Z" />
      <path d="M114.53,147.06l0-3c16-.11,31.83-.84,47-2.17l.26,3C146.52,146.22,130.63,147,114.53,147.06Z" />
      <path d="M190.53,141.53l-.44-3c10.11-1.49,19.71-3.28,28.54-5.31l.67,2.92C210.4,138.22,200.72,140,190.53,141.53Z" />
      <path d="M1.5,170.16a1.5,1.5,0,0,1-1.5-1.5V47.89a1.5,1.5,0,0,1,3,0V168.66A1.5,1.5,0,0,1,1.5,170.16Z" />
      <path d="M60.39,211H28.7V27.24l31.69-8.77ZM31.7,208H57.39V22.41L31.7,29.53Z" />
      <path d="M191.77,211H160.08V18.47l31.69,8.77Zm-28.69-3h25.69V29.53l-25.69-7.12Z" />
      <path d="M219,170.16a1.5,1.5,0,0,1-1.5-1.5V47.89a1.5,1.5,0,0,1,3,0V168.66A1.5,1.5,0,0,1,219,170.16Z" />
      <path d="M109.14,142A16.9,16.9,0,1,1,126,125.13,16.92,16.92,0,0,1,109.14,142Zm0-30.8A13.9,13.9,0,1,0,123,125.13,13.92,13.92,0,0,0,109.14,111.23Z" />
      <path d="M121.36,99.67H96.89L86.77,9.59A8.64,8.64,0,0,1,95.35,0h28.81a8.61,8.61,0,0,1,8.56,9.71Zm-21.79-3h19.15l11-87.33A5.63,5.63,0,0,0,124.16,3H95.35a5.62,5.62,0,0,0-5.59,6.26Z" />
      <polygon points="116.02 110.78 113.02 110.78 113.02 99.67 105.25 99.67 105.25 110.52 102.25 110.52 102.25 96.67 116.02 96.67 116.02 110.78" />
      <polygon points="114.46 153.79 103.52 153.79 101.22 139.13 104.18 138.66 106.08 150.79 111.95 150.79 114.09 138.89 117.05 139.42 114.46 153.79" />
      <rect
        x="180.46"
        y="37.19"
        width="2"
        height="106.2"
      />
      <rect
        x="48.95"
        y="37.19"
        width="2"
        height="106.2"
      />
      <circle
        cx="103.14"
        cy="17.63"
        r="3.65"
      />
      <circle
        cx="103.14"
        cy="30.34"
        r="3.65"
      />
      <circle
        cx="115.13"
        cy="17.63"
        r="3.65"
      />
    </SvgIcon>
  );
};

export default CiderIcon;
