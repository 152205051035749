const allPackagingTypes = [
  {
    id: 0,
    rowguid: 'E5451E98-CE5E-E411-9329-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-10-28T18:17:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Bulk',
    packageTypeClass: '5FC6B3D1-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'FB6FDA8F-6C5F-E411-9329-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 1,
    volumeUOM: '2AE2D52E-A4AB-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 1,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '95AB2200-364B-E911-BAFC-02B432DFC051',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2019-03-20T17:31:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Bulk-Each',
    packageTypeClass: '5FC6B3D1-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'FB6FDA8F-6C5F-E411-9329-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 1,
    volumeUOM: '67EF3BF4-4B88-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 1,
    packagingTypeWeightUOM: 'E90139D5-64BB-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'E5451E98-CE5E-E411-9329-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-10-28T18:17:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Bulk-Volume',
    packageTypeClass: '5FC6B3D1-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'FB6FDA8F-6C5F-E411-9329-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 1,
    volumeUOM: '2AE2D52E-A4AB-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 1,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'B205B0F3-354B-E911-BAFC-02B432DFC051',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2019-03-20T17:31:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Bulk-Weight',
    packageTypeClass: '5FC6B3D1-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'FB6FDA8F-6C5F-E411-9329-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 1,
    volumeUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 1,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '19E9A078-98D0-E311-A60A-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-04-30T18:51:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 12x - 12oz - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'CF298341-9987-E311-AB85-D4AE5266E0C4',
    quantity: 12,
    kegDepositAmount: 0,
    volume: 144,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 0,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '692032E0-D07F-E411-9F99-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-12-09T18:26:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 12x - 12oz - Can',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'FF596B40-C432-E411-A676-D4AE5266E0C4',
    quantity: 12,
    kegDepositAmount: 0,
    volume: 144,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 9,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'D8CE0C34-B46E-EA11-A9CF-0AC016C0647C',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2020-03-25T16:18:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 12x - 12oz - Jar',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '9B146227-B46E-EA11-A9CF-0AC016C0647C',
    quantity: 12,
    kegDepositAmount: 0,
    volume: 144,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 0,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '265EB4BF-B46E-EA11-A9CF-0AC016C0647C',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2020-03-25T16:21:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 12x - 16oz - Bag',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '809FA3AA-B46E-EA11-A9CF-0AC016C0647C',
    quantity: 12,
    kegDepositAmount: 0,
    volume: 192,
    volumeUOM: 'E90139D5-64BB-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 192,
    packagingTypeWeightUOM: 'E90139D5-64BB-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '7E8007E9-46A0-E511-A81F-AEF28B59B497',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-12-11T20:36:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 12x - 19.2oz - Can',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'CE88F3C1-46A0-E511-A81F-AEF28B59B497',
    quantity: 12,
    kegDepositAmount: 0,
    volume: 230.4,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'C065716E-4217-E411-A60A-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-07-29T17:05:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 12x - 22oz - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'CE854D65-4217-E411-A60A-D4AE5266E0C4',
    quantity: 12,
    kegDepositAmount: 0,
    volume: 264,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 27.6,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'A264F3A6-C1B6-E411-94E5-E4C8A6DECC7D',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-02-17T16:25:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 12x - 32oz - Growler',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '203AC1C2-C432-E411-A676-D4AE5266E0C4',
    quantity: 12,
    kegDepositAmount: 0,
    volume: 384,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 384,
    packagingTypeWeightUOM: 'E90139D5-64BB-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '5E811D9F-614A-E511-A6D3-C8E90A8CADF6',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-08-24T13:11:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 12x - 341ml - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '8C17DF46-3136-E511-A6D3-C8E90A8CADF6',
    quantity: 12,
    kegDepositAmount: 0,
    volume: 4092,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'BA58083C-B96E-EA11-A9CF-0AC016C0647C',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2020-03-25T16:54:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 12x - 355ml - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '4F9C8333-6DA2-E411-BBB5-D4AE5266E0C4',
    quantity: 12,
    kegDepositAmount: 0,
    volume: 4260,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 0,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '251A54CE-851E-E511-A6D3-C8E90A8CADF6',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-06-29T17:39:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 12x - 355ml - Can',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '477E6252-6DA2-E411-BBB5-D4AE5266E0C4',
    quantity: 12,
    kegDepositAmount: 0,
    volume: 4260,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: '4AD910D2-0869-E411-9329-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '261A6F86-AD3A-E411-9329-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-09-12T18:49:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 12x - 375ml - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '660188C1-A028-E411-818F-D4AE5266E0C4',
    quantity: 12,
    kegDepositAmount: 0,
    volume: 4500,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 5,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '96BAC37B-111C-E411-A60A-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-08-04T19:57:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 12x - 500ml - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'F8B19905-1CF3-E311-A60A-D4AE5266E0C4',
    quantity: 12,
    kegDepositAmount: 0,
    volume: 6000,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 42,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '098CC2B8-6DA2-E411-BBB5-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-01-22T19:34:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 12x - 650ml - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '2898A066-6DA2-E411-BBB5-D4AE5266E0C4',
    quantity: 12,
    kegDepositAmount: 0,
    volume: 7800,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '4ABD3254-1CF3-E311-A60A-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-06-13T17:01:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 12x - 750ml - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'B7B4D0F8-1BF3-E311-A60A-D4AE5266E0C4',
    quantity: 12,
    kegDepositAmount: 0,
    volume: 9000,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 42,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'DBD11A86-B46E-EA11-A9CF-0AC016C0647C',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2020-03-25T16:20:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 12x24 - 1oz - Bag',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'F749ED5A-B46E-EA11-A9CF-0AC016C0647C',
    quantity: 12,
    kegDepositAmount: 0,
    volume: 288,
    volumeUOM: 'E90139D5-64BB-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 288,
    packagingTypeWeightUOM: 'E90139D5-64BB-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'DBD9A880-6735-E511-A6D3-C8E90A8CADF6',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-07-28T20:30:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 15x - 355ml - Can',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '477E6252-6DA2-E411-BBB5-D4AE5266E0C4',
    quantity: 15,
    kegDepositAmount: 0,
    volume: 5325,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '2062BB51-43A0-E511-A81F-AEF28B59B497',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-12-11T20:11:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 24x - 11.2oz - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '6FD631C7-42A0-E511-A81F-AEF28B59B497',
    quantity: 24,
    kegDepositAmount: 0,
    volume: 268.8,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '4DC89A38-9987-E311-AB85-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-01-27T21:23:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 24x - 12oz - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'CF298341-9987-E311-AB85-D4AE5266E0C4',
    quantity: 24,
    kegDepositAmount: 0,
    volume: 288,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 39,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '3066ED5E-C432-E411-A676-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-09-02T17:13:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 24x - 12oz - Can',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'FF596B40-C432-E411-A676-D4AE5266E0C4',
    quantity: 24,
    kegDepositAmount: 0,
    volume: 288,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 18,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'F749ED5A-B46E-EA11-A9CF-0AC016C0647C',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2020-03-25T16:19:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 24x - 1oz - Bag',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '3C9B3E46-B46E-EA11-A9CF-0AC016C0647C',
    quantity: 24,
    kegDepositAmount: 0,
    volume: 24,
    volumeUOM: 'E90139D5-64BB-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 24,
    packagingTypeWeightUOM: 'E90139D5-64BB-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '4ED82613-B880-E411-BF10-EFBA34DDFD35',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-12-10T22:01:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 24x - 330ml - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '07FF67EF-B780-E411-BF10-EFBA34DDFD35',
    quantity: 24,
    kegDepositAmount: 0,
    volume: 7920,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'E9EA565A-3136-E511-A6D3-C8E90A8CADF6',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-07-29T20:35:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 24x - 341ml - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '8C17DF46-3136-E511-A6D3-C8E90A8CADF6',
    quantity: 24,
    kegDepositAmount: 0,
    volume: 8184,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '7BB5F78E-4C2D-E411-818F-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-08-26T18:13:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 24x - 375ml - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '660188C1-A028-E411-818F-D4AE5266E0C4',
    quantity: 24,
    kegDepositAmount: 0,
    volume: 9000,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'E90139D5-64BB-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'EBBE1751-A630-E511-A6D3-C8E90A8CADF6',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-07-22T19:17:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 24x - 473ml - Can',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'E9E17AEA-39CE-E411-94E5-E4C8A6DECC7D',
    quantity: 24,
    kegDepositAmount: 0,
    volume: 11352,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'B2A2BE86-58DD-E411-94E5-E4C8A6DECC7D',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-04-07T19:01:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 2x12 - 12oz - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '19E9A078-98D0-E311-A60A-D4AE5266E0C4',
    quantity: 2,
    kegDepositAmount: 0,
    volume: 288,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 39,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'FF7D6270-46A0-E511-A81F-AEF28B59B497',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-12-11T20:33:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 2x12 - 12oz - Can',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '692032E0-D07F-E411-9F99-D4AE5266E0C4',
    quantity: 2,
    kegDepositAmount: 0,
    volume: 288,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 18,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '41E5C662-A6ED-E411-94E5-E4C8A6DECC7D',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-04-28T12:59:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 35x - 375ml - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '660188C1-A028-E411-818F-D4AE5266E0C4',
    quantity: 35,
    kegDepositAmount: 0,
    volume: 13125,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '7C0F8055-6B8D-E511-A6D3-C8E90A8CADF6',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-11-17T20:39:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 3x - 22oz - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'CE854D65-4217-E411-A60A-D4AE5266E0C4',
    quantity: 3,
    kegDepositAmount: 0,
    volume: 66,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 6.9,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '78C24C71-6B8D-E511-A6D3-C8E90A8CADF6',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-11-17T20:40:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 3x3 - 22oz - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '7C0F8055-6B8D-E511-A6D3-C8E90A8CADF6',
    quantity: 3,
    kegDepositAmount: 0,
    volume: 198,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 13.8,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'AB837281-3136-E511-A6D3-C8E90A8CADF6',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-07-29T20:36:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 3x4 - 341ml - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'FBF6986F-3136-E511-A6D3-C8E90A8CADF6',
    quantity: 3,
    kegDepositAmount: 0,
    volume: 4092,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '5B7A71D3-6735-E511-A6D3-C8E90A8CADF6',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-07-28T20:32:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 3x8 - 12oz - Can',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '3D4D46BA-6735-E511-A6D3-C8E90A8CADF6',
    quantity: 3,
    kegDepositAmount: 0,
    volume: 288,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 18,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '0B31FD52-6935-E511-A6D3-C8E90A8CADF6',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-07-28T20:43:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 3x8 - 355ml - Can',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '06D4D566-6735-E511-A6D3-C8E90A8CADF6',
    quantity: 3,
    kegDepositAmount: 0,
    volume: 8520,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '1E0DB9EF-42A0-E511-A81F-AEF28B59B497',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-12-11T20:08:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 4x - 11.2oz - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '6FD631C7-42A0-E511-A81F-AEF28B59B497',
    quantity: 4,
    kegDepositAmount: 0,
    volume: 44.8,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '3026EDE3-667A-E411-9F99-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-12-02T21:05:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 4x - 12oz - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'CF298341-9987-E311-AB85-D4AE5266E0C4',
    quantity: 4,
    kegDepositAmount: 0,
    volume: 48,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 6.5,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'D518F76B-2FC8-E411-94E5-E4C8A6DECC7D',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-03-11T20:44:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 4x - 12oz - Can',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'FF596B40-C432-E411-A676-D4AE5266E0C4',
    quantity: 4,
    kegDepositAmount: 0,
    volume: 48,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 3,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '0E931F9D-42A0-E511-A81F-AEF28B59B497',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-12-11T20:06:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 4x - 14.9oz - Can',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '6ECE0858-42A0-E511-A81F-AEF28B59B497',
    quantity: 4,
    kegDepositAmount: 0,
    volume: 59.6,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '7C147B14-2F17-E411-A60A-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-07-29T14:46:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 4x - 16oz - Can',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '4F9E9B2A-2D17-E411-A60A-D4AE5266E0C4',
    quantity: 4,
    kegDepositAmount: 0,
    volume: 64,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 4,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '0D63DAD5-7A40-E511-A6D3-C8E90A8CADF6',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-08-11T22:46:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 4x - 22oz - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'CE854D65-4217-E411-A60A-D4AE5266E0C4',
    quantity: 4,
    kegDepositAmount: 0,
    volume: 88,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 9.2,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'B639D116-E2F8-E411-94E5-E4C8A6DECC7D',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-05-12T20:04:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 4x - 330ml - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '07FF67EF-B780-E411-BF10-EFBA34DDFD35',
    quantity: 4,
    kegDepositAmount: 0,
    volume: 1320,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'FBF6986F-3136-E511-A6D3-C8E90A8CADF6',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-07-29T20:36:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 4x - 341ml - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '8C17DF46-3136-E511-A6D3-C8E90A8CADF6',
    quantity: 4,
    kegDepositAmount: 0,
    volume: 1364,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'CA1E62EC-AF6E-EA11-A9CF-0AC016C0647C',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2020-03-25T15:47:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 4x - 355ml - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '4F9C8333-6DA2-E411-BBB5-D4AE5266E0C4',
    quantity: 4,
    kegDepositAmount: 0,
    volume: 1420,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 0,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '9ED27183-B96E-EA11-A9CF-0AC016C0647C',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2020-03-25T16:56:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 4x - 355ml - Can',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '477E6252-6DA2-E411-BBB5-D4AE5266E0C4',
    quantity: 4,
    kegDepositAmount: 0,
    volume: 1420,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 0,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '39F0A001-3ACE-E411-94E5-E4C8A6DECC7D',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-03-19T13:15:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 4x - 473ml - Can',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'E9E17AEA-39CE-E411-94E5-E4C8A6DECC7D',
    quantity: 4,
    kegDepositAmount: 0,
    volume: 1892,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '1164C337-F683-E511-A6D3-C8E90A8CADF6',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-11-05T19:48:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 4x - 750ml - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'B7B4D0F8-1BF3-E311-A60A-D4AE5266E0C4',
    quantity: 4,
    kegDepositAmount: 0,
    volume: 3000,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 14,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '6161A9C9-66EF-E411-94E5-E4C8A6DECC7D',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-04-30T18:29:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 4x4 - 16oz - Can',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '7C147B14-2F17-E411-A60A-D4AE5266E0C4',
    quantity: 4,
    kegDepositAmount: 0,
    volume: 256,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 16,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '7166347E-CF0A-E411-A60A-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-07-13T20:51:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 4x6 - 12oz - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '39250E57-4C09-E411-A60A-D4AE5266E0C4',
    quantity: 4,
    kegDepositAmount: 0,
    volume: 288,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 55.2,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '71AB6CA7-949D-E411-94E5-E4C8A6DECC7D',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-01-16T15:30:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 4x6 - 12oz - Can',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'A2790448-6537-E411-9329-D4AE5266E0C4',
    quantity: 4,
    kegDepositAmount: 0,
    volume: 288,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 18,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '054EF40B-C383-E511-A6D3-C8E90A8CADF6',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-11-05T13:42:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 4x6 - 16oz - Can',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '7A055E5F-BC7B-E411-9F99-D4AE5266E0C4',
    quantity: 4,
    kegDepositAmount: 0,
    volume: 384,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 24,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '11E1C158-2853-E511-A6D3-C8E90A8CADF6',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-09-04T17:14:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 4x6 - 330ml - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '6F5CFEA2-44E2-E411-94E5-E4C8A6DECC7D',
    quantity: 4,
    kegDepositAmount: 0,
    volume: 7920,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'CC94A696-6735-E511-A6D3-C8E90A8CADF6',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-07-28T20:31:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 4x6 - 355ml - Can',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'EE3F948B-6DA2-E411-BBB5-D4AE5266E0C4',
    quantity: 4,
    kegDepositAmount: 0,
    volume: 8520,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '39250E57-4C09-E411-A60A-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-07-11T22:40:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 6x - 12oz - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'CF298341-9987-E311-AB85-D4AE5266E0C4',
    quantity: 6,
    kegDepositAmount: 0,
    volume: 72,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 72,
    packagingTypeWeightUOM: 'E90139D5-64BB-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'A2790448-6537-E411-9329-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-09-08T14:34:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 6x - 12oz - Can',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'FF596B40-C432-E411-A676-D4AE5266E0C4',
    quantity: 6,
    kegDepositAmount: 0,
    volume: 72,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 4.5,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '7A055E5F-BC7B-E411-9F99-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-12-04T13:49:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 6x - 16oz - Can',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '4F9E9B2A-2D17-E411-A60A-D4AE5266E0C4',
    quantity: 6,
    kegDepositAmount: 0,
    volume: 96,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 6,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '7AEA9445-B06E-EA11-A9CF-0AC016C0647C',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2020-03-25T15:49:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 6x - 1L - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'C5D7713F-B06E-EA11-A9CF-0AC016C0647C',
    quantity: 6,
    kegDepositAmount: 0,
    volume: 6,
    volumeUOM: '4A711580-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 0,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '3080DF74-8988-E511-A6D3-C8E90A8CADF6',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-11-11T15:32:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 6x - 22oz - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'CE854D65-4217-E411-A60A-D4AE5266E0C4',
    quantity: 6,
    kegDepositAmount: 0,
    volume: 132,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 13.8,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '6F5CFEA2-44E2-E411-94E5-E4C8A6DECC7D',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-04-14T01:22:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 6x - 330ml - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '07FF67EF-B780-E411-BF10-EFBA34DDFD35',
    quantity: 6,
    kegDepositAmount: 0,
    volume: 1980,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '398F2191-3136-E511-A6D3-C8E90A8CADF6',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-07-29T20:37:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 6x - 341ml - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '8C17DF46-3136-E511-A6D3-C8E90A8CADF6',
    quantity: 6,
    kegDepositAmount: 0,
    volume: 2046,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'CFF8709D-6DA2-E411-BBB5-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-01-22T19:34:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 6x - 355ml - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '4F9C8333-6DA2-E411-BBB5-D4AE5266E0C4',
    quantity: 6,
    kegDepositAmount: 0,
    volume: 2130,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'EE3F948B-6DA2-E411-BBB5-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-01-22T19:33:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 6x - 355ml - Can',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '477E6252-6DA2-E411-BBB5-D4AE5266E0C4',
    quantity: 6,
    kegDepositAmount: 0,
    volume: 2130,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '55CD16A9-AD3A-E411-9329-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-09-12T18:50:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 6x - 375ml - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '660188C1-A028-E411-818F-D4AE5266E0C4',
    quantity: 6,
    kegDepositAmount: 0,
    volume: 2250,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'AC3B33C8-6273-E511-A6D3-C8E90A8CADF6',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-10-15T17:33:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 6x - 500ml - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'F8B19905-1CF3-E311-A60A-D4AE5266E0C4',
    quantity: 6,
    kegDepositAmount: 0,
    volume: 3000,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 12,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '279042BA-56B1-E411-94E5-E4C8A6DECC7D',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-02-10T18:57:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 6x - 64oz - Growler',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'C8A5D6CD-C432-E411-A676-D4AE5266E0C4',
    quantity: 6,
    kegDepositAmount: 0,
    volume: 384,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '8C9DA262-AD3A-E411-9329-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-09-12T18:48:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 6x - 750ml - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'B7B4D0F8-1BF3-E311-A60A-D4AE5266E0C4',
    quantity: 6,
    kegDepositAmount: 0,
    volume: 4500,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 21,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'C9BB6B02-43A0-E511-A81F-AEF28B59B497',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-12-11T20:08:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 6x4 - 11.2oz - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '1E0DB9EF-42A0-E511-A81F-AEF28B59B497',
    quantity: 6,
    kegDepositAmount: 0,
    volume: 268.8,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'F0A9D4F4-667A-E411-9F99-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-12-02T21:05:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 6x4 - 12oz - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '3026EDE3-667A-E411-9F99-D4AE5266E0C4',
    quantity: 6,
    kegDepositAmount: 0,
    volume: 288,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 39,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'D64A5E91-2FC8-E411-94E5-E4C8A6DECC7D',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-03-11T20:45:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 6x4 - 12oz - Can',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'D518F76B-2FC8-E411-94E5-E4C8A6DECC7D',
    quantity: 6,
    kegDepositAmount: 0,
    volume: 288,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 18,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'D4DC52B3-42A0-E511-A81F-AEF28B59B497',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-12-11T20:06:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 6x4 - 14.9oz - Can',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '0E931F9D-42A0-E511-A81F-AEF28B59B497',
    quantity: 6,
    kegDepositAmount: 0,
    volume: 357.6,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '10598A24-677A-E411-9F99-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-12-02T21:06:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 6x4 - 16oz - Can',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '7C147B14-2F17-E411-A60A-D4AE5266E0C4',
    quantity: 6,
    kegDepositAmount: 0,
    volume: 384,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 24,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'CE1E62EC-AF6E-EA11-A9CF-0AC016C0647C',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2020-03-25T15:47:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 6x4 - 355ml - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'CA1E62EC-AF6E-EA11-A9CF-0AC016C0647C',
    quantity: 6,
    kegDepositAmount: 0,
    volume: 8520,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 0,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'A7A78489-B96E-EA11-A9CF-0AC016C0647C',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2020-03-25T16:56:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 6x4 - 355ml - Can',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '9ED27183-B96E-EA11-A9CF-0AC016C0647C',
    quantity: 6,
    kegDepositAmount: 0,
    volume: 8520,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 0,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '7975FA19-3ACE-E411-94E5-E4C8A6DECC7D',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-03-19T13:16:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 6x4 - 473ml - Can',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '39F0A001-3ACE-E411-94E5-E4C8A6DECC7D',
    quantity: 6,
    kegDepositAmount: 0,
    volume: 11352,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '3D4D46BA-6735-E511-A6D3-C8E90A8CADF6',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-07-28T20:32:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 8x - 12oz - Can',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'FF596B40-C432-E411-A676-D4AE5266E0C4',
    quantity: 8,
    kegDepositAmount: 0,
    volume: 96,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 6,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '06D4D566-6735-E511-A6D3-C8E90A8CADF6',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-07-28T20:30:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 8x - 355ml - Can',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '477E6252-6DA2-E411-BBB5-D4AE5266E0C4',
    quantity: 8,
    kegDepositAmount: 0,
    volume: 2840,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '90A6B03D-C136-E511-A6D3-C8E90A8CADF6',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-07-30T13:45:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Case - 9x - 16oz - Can',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: '2DB0E429-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: '4F9E9B2A-2D17-E411-A60A-D4AE5266E0C4',
    quantity: 9,
    kegDepositAmount: 0,
    volume: 144,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 9,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '8C612BE1-6C0F-E911-B3AE-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2019-01-03T15:33:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Draft',
    packageTypeClass: 'E1B8CA78-6C0F-E911-B3AE-D4AE5266E0C4',
    style: '53D3DBA5-6C0F-E911-B3AE-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 1,
    volumeUOM: '2AE2D52E-A4AB-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 1,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '1DE614B6-8C81-E511-A6D3-C8E90A8CADF6',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-11-02T18:08:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Jar - 3.5oz',
    packageTypeClass: 'DCEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'ED4D461F-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 3.5,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '00327FBC-2687-E511-A6D3-C8E90A8CADF6',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-11-09T21:13:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Jar - 9oz',
    packageTypeClass: 'DCEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'ED4D461F-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 9,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'E7521AF6-9487-E311-AB85-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-01-27T20:52:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Keg - 1/2 bbl',
    packageTypeClass: 'DBEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: '8D703E46-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 15.5,
    volumeUOM: '002E6AAC-A5AB-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 120,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '89597A81-2B37-E611-BE55-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2016-06-20T21:11:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Keg - 1/2 BBL Micro*',
    packageTypeClass: 'DBEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: '8D703E46-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 15.5,
    volumeUOM: '002E6AAC-A5AB-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 140,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '4FEB102C-8366-E411-9329-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-11-07T13:37:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Keg - 1/4 bbl',
    packageTypeClass: 'DBEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: '8D703E46-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 7.75,
    volumeUOM: '002E6AAC-A5AB-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 90,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'EA2A8914-4ED1-E311-A60A-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-05-01T16:31:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Keg - 1/6 bbl',
    packageTypeClass: 'DBEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: '8D703E46-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 5.1667,
    volumeUOM: '002E6AAC-A5AB-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 65,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'C6A29713-ED37-E611-BE55-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2016-06-21T20:16:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Keg - 1/6 BBL Micro*',
    packageTypeClass: 'DBEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: '8D703E46-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 5.1666,
    volumeUOM: '002E6AAC-A5AB-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 65,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '4B1BCEEE-A028-E411-818F-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-08-20T19:34:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Keg - 10L',
    packageTypeClass: 'DBEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: '8D703E46-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 10,
    volumeUOM: '4A711580-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'BAF51B1A-4C2A-E511-A6D3-C8E90A8CADF6',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-07-14T17:16:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Keg - 12 gal',
    packageTypeClass: 'DBEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: '8D703E46-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 12,
    volumeUOM: '002E6AAC-A5AB-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 5,
    packagingTypeWeightUOM: 'E90139D5-64BB-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'F81377B8-5182-E511-A6D3-C8E90A8CADF6',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-11-03T17:38:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Keg - 13.2 gal',
    packageTypeClass: 'DBEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: '8D703E46-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 13.2,
    volumeUOM: '002E6AAC-A5AB-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '76BAA2D6-6DA2-E411-BBB5-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-01-22T19:35:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Keg - 19.5L',
    packageTypeClass: 'DBEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: '8D703E46-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 19.5,
    volumeUOM: '4A711580-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 50,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'FE7F2AB5-EF0A-E511-94E5-E4C8A6DECC7D',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-06-04T19:27:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Keg - 19L',
    packageTypeClass: 'DBEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: '8D703E46-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 19,
    volumeUOM: '4A711580-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 5,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '3DC0E3DE-B780-E411-BF10-EFBA34DDFD35',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-12-10T21:59:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Keg - 20L',
    packageTypeClass: 'DBEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: '8D703E46-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 20,
    volumeUOM: '4A711580-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 1,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'B09968FC-6829-E511-A6D3-C8E90A8CADF6',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-07-13T14:11:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Keg - 25L',
    packageTypeClass: 'DBEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: '8D703E46-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 25,
    volumeUOM: '4A711580-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '77002DEB-6DA2-E411-BBB5-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-01-22T19:36:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Keg - 29.3L',
    packageTypeClass: 'DBEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: '8D703E46-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 29.3,
    volumeUOM: '4A711580-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 90,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '7D921DD4-B780-E411-BF10-EFBA34DDFD35',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-12-10T21:59:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Keg - 30L',
    packageTypeClass: 'DBEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: '8D703E46-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 30,
    volumeUOM: '4A711580-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 90,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'CCFD4B72-48F3-E411-94E5-E4C8A6DECC7D',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-05-05T17:02:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Keg - 40L',
    packageTypeClass: 'DBEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: '8D703E46-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 40,
    volumeUOM: '4A711580-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 1,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '40C6171A-1B22-E411-818F-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-08-12T12:21:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Keg - 50L',
    packageTypeClass: 'DBEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: '8D703E46-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 50,
    volumeUOM: '4A711580-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 130,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '6F04B5FF-ADCD-E411-94E5-E4C8A6DECC7D',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-03-18T20:33:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Keg - 58.6L',
    packageTypeClass: 'DBEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: '8D703E46-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 58.6,
    volumeUOM: '4A711580-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 160,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'C6611665-E4ED-E411-94E5-E4C8A6DECC7D',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-04-28T20:23:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Keg - 5L',
    packageTypeClass: 'DBEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: '8D703E46-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 5,
    volumeUOM: '4A711580-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 20,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'B71A10DC-3990-E411-BF10-EFBA34DDFD35',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-12-30T15:38:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Keg - Corny',
    packageTypeClass: 'DBEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: '8D703E46-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 5,
    volumeUOM: '002E6AAC-A5AB-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 57,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'E713CFF7-4017-E411-A60A-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-07-29T16:54:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Keg - Firkin',
    packageTypeClass: 'DBEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: '8D703E46-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 10,
    volumeUOM: '002E6AAC-A5AB-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 115,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'DD05D400-4117-E411-A60A-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-07-29T16:55:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Keg - Pin',
    packageTypeClass: 'DBEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: '8D703E46-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 5.4,
    volumeUOM: '002E6AAC-A5AB-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 60,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '02024518-7BAD-E411-94E5-E4C8A6DECC7D',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-02-05T21:08:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Keg - Pub Keg',
    packageTypeClass: 'DBEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: '8D703E46-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 5.17,
    volumeUOM: '002E6AAC-A5AB-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'ADFE6EC6-D044-E911-BAFC-02B432DFC051',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2019-03-12T14:11:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Single - 1.5L - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'ED4D461F-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 1.5,
    volumeUOM: '4A711580-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 1,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'DB5CB514-0AA3-E411-BBB5-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-01-23T14:14:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Single - 1.89L - Growler',
    packageTypeClass: 'DCEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'ED4D461F-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 1.89,
    volumeUOM: '4A711580-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '6FD631C7-42A0-E511-A81F-AEF28B59B497',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-12-11T20:07:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Single - 11.2oz - Bottle',
    packageTypeClass: 'DCEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'ED4D461F-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 11.2,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'CF298341-9987-E311-AB85-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-01-27T21:23:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Single - 12oz - Bottle',
    packageTypeClass: 'DCEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'ED4D461F-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 1,
    kegDepositAmount: 0,
    volume: 12,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 2.3,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'FF596B40-C432-E411-A676-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-09-02T17:12:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Single - 12oz - Can',
    packageTypeClass: 'DCEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'ED4D461F-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 12,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 0.75,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '9B146227-B46E-EA11-A9CF-0AC016C0647C',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2020-03-25T16:17:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Single - 12oz - Jar',
    packageTypeClass: 'DCEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'ED4D461F-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 12,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 0,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '6ECE0858-42A0-E511-A81F-AEF28B59B497',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-12-11T20:04:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Single - 14.9oz - Can',
    packageTypeClass: 'DCEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'ED4D461F-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 14.9,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '809FA3AA-B46E-EA11-A9CF-0AC016C0647C',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2020-03-25T16:21:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Single - 16oz - Bag',
    packageTypeClass: 'DCEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'ED4D461F-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 16,
    volumeUOM: 'E90139D5-64BB-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 16,
    packagingTypeWeightUOM: 'E90139D5-64BB-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '5DB02E3C-0ACF-E411-94E5-E4C8A6DECC7D',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-03-20T10:06:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Single - 16oz - Bottle',
    packageTypeClass: 'DCEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'ED4D461F-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 16,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '4F9E9B2A-2D17-E411-A60A-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-07-29T14:33:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Single - 16oz - Can',
    packageTypeClass: 'DCEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'ED4D461F-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 16,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 1,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'CE88F3C1-46A0-E511-A81F-AEF28B59B497',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-12-11T20:35:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Single - 19.2oz - Can',
    packageTypeClass: 'DCEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'ED4D461F-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 19.2,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '2BFE04E1-D239-E411-9329-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-09-11T16:44:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Single - 1gal - Growler',
    packageTypeClass: 'DCEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'ED4D461F-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 1,
    volumeUOM: '002E6AAC-A5AB-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'C5D7713F-B06E-EA11-A9CF-0AC016C0647C',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2020-03-25T15:49:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Single - 1L - Bottle',
    packageTypeClass: 'DCEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'ED4D461F-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 1,
    volumeUOM: '4A711580-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 0,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '8621E721-0AA3-E411-BBB5-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-01-23T14:14:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Single - 1L - Growler',
    packageTypeClass: 'DCEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'ED4D461F-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 1,
    volumeUOM: '4A711580-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '697423CD-B46E-EA11-A9CF-0AC016C0647C',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2020-03-25T16:22:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Single - 1lb - Bag',
    packageTypeClass: 'DCEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'ED4D461F-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 1,
    volumeUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 1,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '3C9B3E46-B46E-EA11-A9CF-0AC016C0647C',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2020-03-25T16:18:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Single - 1oz - Bag',
    packageTypeClass: 'DCEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'ED4D461F-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 1,
    volumeUOM: 'E90139D5-64BB-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 1,
    packagingTypeWeightUOM: 'E90139D5-64BB-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'CE854D65-4217-E411-A60A-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-07-29T17:05:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Single - 22oz - Bottle',
    packageTypeClass: 'DCEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'ED4D461F-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 22,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 2.3,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'EDA9E221-0ACF-E411-94E5-E4C8A6DECC7D',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-03-20T10:05:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Single - 32oz - Bottle',
    packageTypeClass: 'DCEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'ED4D461F-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 32,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'EEDE9B92-E9AB-E411-94E5-E4C8A6DECC7D',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-02-03T21:14:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Single - 32oz - Can',
    packageTypeClass: 'DCEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'ED4D461F-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 32,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '203AC1C2-C432-E411-A676-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-09-02T17:15:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Single - 32oz - Growler',
    packageTypeClass: 'DCEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'ED4D461F-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 32,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '07FF67EF-B780-E411-BF10-EFBA34DDFD35',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-12-10T22:00:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Single - 330ml - Bottle',
    packageTypeClass: 'DCEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'ED4D461F-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 330,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '8C17DF46-3136-E511-A6D3-C8E90A8CADF6',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-07-29T20:34:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Single - 341ml - Bottle',
    packageTypeClass: 'DCEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'ED4D461F-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 341,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '4F9C8333-6DA2-E411-BBB5-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-01-22T19:31:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Single - 355ml - Bottle',
    packageTypeClass: 'DCEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'ED4D461F-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 355,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '477E6252-6DA2-E411-BBB5-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-01-22T19:31:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Single - 355ml - Can',
    packageTypeClass: 'DCEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'ED4D461F-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 355,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '660188C1-A028-E411-818F-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-08-20T19:33:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Single - 375ml - Bottle',
    packageTypeClass: 'DCEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'ED4D461F-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 375,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '60BEF828-45A0-E511-A81F-AEF28B59B497',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-12-11T20:24:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Single - 3L - Bottle',
    packageTypeClass: 'DCEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'ED4D461F-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 3,
    volumeUOM: '4A711580-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'E9E17AEA-39CE-E411-94E5-E4C8A6DECC7D',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-03-19T13:14:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Single - 473ml - Can',
    packageTypeClass: 'DCEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'ED4D461F-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 473,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'F8B19905-1CF3-E311-A60A-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-06-13T16:59:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Single - 500ml - Bottle',
    packageTypeClass: 'DCEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'ED4D461F-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 500,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 2,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '75EC443E-AD3A-E411-9329-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-09-12T18:47:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Single - 50ml - Bottle',
    packageTypeClass: 'DCEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'ED4D461F-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 50,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '99A102DD-B46E-EA11-A9CF-0AC016C0647C',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2020-03-25T16:22:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Single - 5lb - Bag',
    packageTypeClass: 'DCEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'ED4D461F-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 5,
    volumeUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 5,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'C679F72E-9CA2-E511-A81F-AEF28B59B497',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-12-14T19:52:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Single - 640ml - Bottle',
    packageTypeClass: 'DCEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'ED4D461F-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 640,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'C8A5D6CD-C432-E411-A676-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-09-02T17:16:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Single - 64oz - Growler',
    packageTypeClass: 'DCEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'ED4D461F-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 64,
    volumeUOM: 'E368C195-12A3-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: '2898A066-6DA2-E411-BBB5-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2015-01-22T19:32:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Single - 650ml - Bottle',
    packageTypeClass: 'DCEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'ED4D461F-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 650,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: null,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'A5D4D3DB-D044-E911-BAFC-02B432DFC051',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2019-03-12T14:12:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Single - 6L - Bottle',
    packageTypeClass: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'ED4D461F-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 6,
    volumeUOM: '4A711580-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 1,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'B7B4D0F8-1BF3-E311-A60A-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2014-06-13T16:58:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'Single - 750ml - Bottle',
    packageTypeClass: 'DCEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'ED4D461F-A207-E411-A60A-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 750,
    volumeUOM: 'ACA2F646-0E6C-E411-9F99-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 3.25,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
  {
    id: 0,
    rowguid: 'B26D01E7-B594-E811-A551-D4AE5266E0C4',
    isRetired: false,
    createdByID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    createdDate: '2018-07-31T11:36:00',
    lastModifiedByID: 'NULL',
    lastModifiedDate: null,
    ownerID: 'DDB70AB3-E20E-4633-972A-6FB5A4BA1695',
    title: 'TapRoom Bulk',
    packageTypeClass: '5FC6B3D1-9CDB-E311-A60A-D4AE5266E0C4',
    style: 'FB6FDA8F-6C5F-E411-9329-D4AE5266E0C4',
    subPackagingType: 'NULL',
    quantity: 0,
    kegDepositAmount: 0,
    volume: 1,
    volumeUOM: '2AE2D52E-A4AB-E311-AB85-D4AE5266E0C4',
    active: true,
    packagingTypeWeight: 100,
    packagingTypeWeightUOM: 'BCEC11B8-ED88-E311-AB85-D4AE5266E0C4',
    packagingTypeDepositItem: 'NULL',
  },
];

export default allPackagingTypes;
