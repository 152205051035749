import XLSX from 'xlsx';
import fetch from 'cross-fetch';
import { forEach, isEqual } from 'lodash';
import moment from 'moment';

import log from '../utils/log';
import * as types from './types';
import { STEP_KEYS } from '../constants';
import getConfigByBrand from '../config/getConfigByBrand';

export const toggleRequiredFieldErrors = () => {
  return {
    type: types.TOGGLE_REQUIRED_ERRORS,
  };
};

export const updateBrand = brand => {
  return {
    type: types.UPDATE_BRAND,
    brand,
  };
};

export const updateSendingEmail = emailSending => {
  return {
    type: types.SENDING_EMAIL,
    emailSending,
  };
};

export const updateEmailSent = emailSent => {
  return {
    type: types.EMAIL_SENT,
    emailSent,
  };
};

export const updateLogo = logo => {
  return {
    type: types.LOGO_UPDATED,
    logo,
  };
};

export const updateFloorplan = floorplan => {
  return {
    type: types.FLOORPLAN_UPDATED,
    floorplan,
  };
};

export const updatePackagingTypes = (arrayIndex, value) => {
  return {
    type: types.UPDATE_PACKAGING_TYPES,
    arrayIndex,
    value,
  };
};

export const updatePackagingComponents = (arrayIndex, value) => {
  return {
    type: types.UPDATE_PACKAGING_COMPONENTS,
    arrayIndex,
    value,
  };
};

export const deletePackagingType = arrayIndex => {
  return {
    type: types.DELETE_PACKAGING_TYPE,
    arrayIndex,
  };
};

export const changeField = (step, key, value) => {
  return {
    type: types.CHANGE_FIELD,
    step,
    key,
    value,
  };
};

export const startExcel = () => {
  return {
    type: types.EXCEL_STARTED,
  };
};

export const completeExcel = () => {
  return {
    type: types.EXCEL_COMPLETED,
  };
};

export const changeFieldArray = (arrayKey, arrayIndex, fieldKey, value) => {
  const newValue = Array.isArray(value) ? value.filter(v => !!v) : value;
  return {
    type: types.CHANGE_FIELD_ARRAY,
    arrayKey,
    arrayIndex,
    fieldKey,
    value: newValue,
  };
};

export const addRowToArray = (arrayKey, initialFields) => {
  return {
    type: types.ADD_FIELD_ARRAY,
    arrayKey,
    initialFields,
  };
};

export const removeRowFromArray = (arrayKey, arrayIndex) => {
  return {
    type: types.REMOVE_FIELD_ARRAY,
    arrayKey,
    arrayIndex,
  };
};

export const updateRowInArray = (arrayKey, arrayIndex, row) => {
  return {
    type: types.UPDATE_ROW_IN_ARRAY,
    arrayKey,
    arrayIndex,
    row,
  };
};

export const updateEntireArray = (arrayKey, updatedArray) => {
  return {
    type: types.UPDATE_ENTIRE_ARRAY,
    arrayKey,
    updatedArray,
  };
};

export const setStepValidationError = (step, field, error) => {
  return ({
    type: types.SET_VALIDATION_ERROR,
    step,
    field,
    error,
  });
};


export const clearStepValidationError = (step, field) => ({
  type: types.CLEAR_VALIDATION_ERROR,
  step,
  field,
});

export const uploadLogo = logoFile => dispatch => {
  const reader = new FileReader();
  reader.readAsDataURL(logoFile);
  reader.onload = () => {
    const content = reader.result && reader.result.split(',')[1];
    dispatch(
      updateLogo({
        content,
        fileName: logoFile.name,
        type: logoFile.type,
      }),
    );
  };
  reader.onerror = error => {
    log('Error: ', error);
  };
};

export const uploadFloorplan = floorplanFile => dispatch => {
  const reader = new FileReader();
  reader.readAsDataURL(floorplanFile);
  reader.onload = () => {
    const content = reader.result && reader.result.split(',')[1];
    dispatch(
      updateFloorplan({
        content,
        fileName: floorplanFile.name,
        type: floorplanFile.type,
      }),
    );
  };
  reader.onerror = error => {
    log('Error: ', error);
  };
};

const buildPackagingSheet = (tableRows, form) => () => {
  const allPackagingTypes = form.packagingTypes;
  const allProducts = form.products || [];

  const values = [];

  if (tableRows && tableRows.length) {
    tableRows.forEach(row => {
      const selectedPackagingType = allPackagingTypes.find(type => (
        type.rowguid === row.packagingTypeRowguid
      ));
      if (!selectedPackagingType) {
        return;
      }

      // all of this is used to make sure that the original packaging type is included in the spreadsheet
      const packagingComponentsForRow = (row.packagingComponents || []).map(component => ({
        ...component,
        isProductSpecific: false,
      }));

      let rowHasProductMatch = false;

      // group the products by their components, including product specific or generic
      const associatedProductsGroupedByComponents = allProducts.reduce((acc, product) => {
        const packagingTypeIdx = product.productPackagingTypes.findIndex(type => (
          type.rowguid === row.packagingTypeRowguid
        ));

        if (packagingTypeIdx > -1) {
          const productPackagingType = product.productPackagingTypes[packagingTypeIdx];

          if (
            !rowHasProductMatch
            && isEqual(productPackagingType.packagingComponents, packagingComponentsForRow)
          ) {
            rowHasProductMatch = true;
          }

          const componentsMatchIdx = acc.findIndex(type => (
            isEqual(type.packagingComponents, productPackagingType.packagingComponents)
          ));

          if (componentsMatchIdx > -1 && acc.length > 0) {
            const packagingTypeToUpdate = { ...acc[componentsMatchIdx] };

            packagingTypeToUpdate.products.push(product.value);

            acc.splice(componentsMatchIdx, 1, packagingTypeToUpdate);

            return acc;
          }

          acc.push({
            products: [product.value],
            packagingType: row.packagingTypes,
            packagingComponents: [
              ...product.productPackagingTypes[packagingTypeIdx].packagingComponents,
            ],
          });
        }

        return acc;
      }, []);

      // if none of the products have the same packaging components as the original packaging type, we need to still make sure to include it
      if (!rowHasProductMatch) {
        associatedProductsGroupedByComponents.splice(0, 0, {
          products: [],
          packagingType: row.packagingTypes,
          packagingComponents: packagingComponentsForRow,
        });
      }

      // populate the spreadsheet rows
      const packagingType = row.packagingTypes;

      associatedProductsGroupedByComponents.forEach(group => {
        const products = group.products.length > 0 ? group.products.join(', ') : '';

        const packagingComponents = group.packagingComponents.map(component => {
          if (!component.isProductSpecific) {
            return {
              label: component.label,
              quantity: component.quantity,
            };
          }

          return {
            label: `${component.label} - Product Specific`,
            quantity: component.quantity,
          };
        });

        packagingComponents.forEach((component, idx) => {
          // need to include the products, packaging type, and wip amount for the first component in the array
          if (idx === 0) {
            values.push([products, packagingType, component.label, parseFloat(component.quantity)]);
          } else {
            values.push(['', '', component.label, parseFloat(component.quantity)]);
          }
        });
      });
    });
  }

  return values;
};

const buildProductsSheet = (tableRows = []) => () => {
  return tableRows.map(product => ([product.value, product.productClass, product.uom]));
};

const buildInventoryItemsSheet = (tableRows, form) => () => {
  const values = [];

  if (tableRows && tableRows.length) {
    tableRows.forEach(row => {
      values.push([row.item, 'Ingredients', row.category, row.uom]);
    });
  }

  const packagingRows = form.packaging || [];
  const productRows = form.products || [];

  const allPackagingComponents = [];

  packagingRows.forEach(packagingType => {
    productRows.forEach(product => {
      const packagingTypeMatch = product.productPackagingTypes.find(type => (
        type.rowguid === packagingType.packagingTypeRowguid
      ));

      if (packagingTypeMatch) {
        packagingTypeMatch.packagingComponents.forEach(component => {
          const componentLabel = component.isProductSpecific
            ? `${component.label} - ${product.value}`
            : component.label;

          if (!allPackagingComponents.includes(componentLabel)) {
            allPackagingComponents.push(componentLabel);
          }
        });
      }
    });
  });

  allPackagingComponents.forEach(component => {
    values.push([component, 'Packaging', 'Packaging', 'Each']);
  });
  return values;
};

const buildEquipmentSheet = (tableRows, tableKey) => (dispatch, getState) => {
  const values = [];
  const { sites } = getState().form;
  if (tableRows && tableRows.length) {
    if (tableKey === 'equipment') {
      tableRows.forEach(row => {
        // the site here is actually the siteID need to pass the siteName
        const {
          name, abbreviation, equipmentType, capacityFields, site, system,
        } = row;
        const siteObject = sites.find(s => s.siteId === site);
        const siteName = siteObject && siteObject.siteName;
        values.push([
          name,
          abbreviation,
          equipmentType,
          capacityFields.capacity,
          capacityFields.maxCapacity,
          capacityFields.capacityUOM,
          siteName,
          system,
        ]);
      });
    } else if (tableKey === 'barrels') {
      tableRows.forEach(row => {
        // the site here is actually the siteID need to pass the siteName
        const {
          number, type, capacityFields, site,
        } = row;
        const siteObject = sites.find(s => s.siteId === site);
        const siteName = siteObject && siteObject.siteName;
        values.push([
          number,
          type,
          capacityFields.capacity,
          capacityFields.maxCapacity,
          capacityFields.capacityUOM,
          siteName,
        ]);
      });
    }
  }
  return values;
};

const buildStorageSheet = tableRows => (dispatch, getState) => {
  const values = [];
  const { sites } = getState().form;
  if (tableRows && tableRows.length) {
    tableRows.forEach(row => {
      // the site here is actually the siteID need to pass the siteName
      const { name, site } = row;
      const siteObject = sites.find(s => s.siteId === site);
      const siteName = siteObject && siteObject.siteName;
      values.push([name, siteName]);
    });
  }
  return values;
};

export const buildExcelFile = () => (dispatch, getState) => {
  try {
    const { brand, form } = getState();

    dispatch(startExcel());
    if (!form) throw new Error('There is no form data to parse');

    let name = 'New Company';
    if (form[STEP_KEYS.FACILITY] && form[STEP_KEYS.FACILITY].name) {
      ({ name } = form[STEP_KEYS.FACILITY]);
    }

    // initialize the workbook
    const wb = XLSX.utils.book_new();
    wb.Props = {
      Title: name,
      Subject: 'New Customer',
      Author: 'Ekos Engineering',
      CreatedDate: new Date(),
    };

    // get all the steps for this brand
    const steps = getConfigByBrand(brand);

    forEach(steps, step => {
      const {
        heading, subheading, stepKey, fields, tables,
      } = step;
      // push this sheet into the workbook
      wb.SheetNames.push(heading);
      let ws = XLSX.utils.aoa_to_sheet([
        ['Title', heading],
        ['Instructions', subheading],
      ]);

      wb.Sheets[heading] = ws;

      let originRow = 3;
      // create array of arrays for fields
      if (fields && fields.length > 0) {
        const fieldAOA = [];
        fields.forEach(field => {
          if (field.type !== 'hidden') {
            let fieldValue = form && form[stepKey] && form[stepKey][field.fieldKey];

            if (field.type === 'kegDeposit' && !!fieldValue) {
              fieldValue = `${fieldValue.currency} ${fieldValue.amount}`;
            }

            fieldAOA.push([field.label, fieldValue]);
          }
        });
        XLSX.utils.sheet_add_aoa(ws, fieldAOA, {
          origin: { r: originRow, c: 0 },
        });
        originRow = fieldAOA.length + 1;
      }

      if (tables && tables.length > 0) {
        if (stepKey === STEP_KEYS.FACILITY) {
          const sitesTable = tables[0];
          // need to create a sites sheet and push it into the workbook
          wb.SheetNames.push('Sites');
          ws = XLSX.utils.aoa_to_sheet([
            ['Title', sitesTable.heading],
            ['Instructions', sitesTable.subheading],
          ]);

          wb.Sheets.Sites = ws;

          originRow = 3;
        }

        let cols = [];

        forEach(tables, table => {
          const { columns, tableKey } = table;
          columns.forEach(column => {
            if (column.type !== 'hidden') {
              cols.push(column.header);
            }
          });

          let values = [];

          if (stepKey === STEP_KEYS.PACKAGING) {
            forEach(tables, () => {
              cols = ['Products', 'Packaging Type', 'Production Component', 'Quantity'];

              const tableRows = form[tableKey];
              values = dispatch(buildPackagingSheet(tableRows, form));
            });
          } else if (stepKey === STEP_KEYS.PRODUCTS) {
            cols = ['Product Name', 'Product Class', 'Unit of Measure'];

            const tableRows = form[tableKey];
            values = dispatch(buildProductsSheet(tableRows));
          } else if (stepKey === STEP_KEYS.INVENTORY) {
            forEach(tables, () => {
              cols = ['Item', 'Item Class', 'Item Category', 'Stock UOM'];
              const tableRows = form[tableKey];
              values = dispatch(buildInventoryItemsSheet(tableRows, form));
            });
          } else if (stepKey === STEP_KEYS.EQUIPMENT) {
            forEach(tables, () => {
              if (tableKey === 'equipment') {
                cols = ['Name', 'Abbreviation', 'Type', 'Capacity', 'Max Capacity', 'Capacity UOM', 'Site', 'System'];
              } else if (tableKey === 'barrels') {
                cols = ['Number', 'Type', 'Capacity', 'Max Capacity', 'Capacity UOM', 'Site'];
              }
              const tableRows = form[tableKey];
              values = dispatch(buildEquipmentSheet(tableRows, tableKey));
            });
          } else if (stepKey === STEP_KEYS.STORAGELOCATIONS) {
            cols = ['Location', 'Site'];
            const tableRows = form[tableKey];
            values = dispatch(buildStorageSheet(tableRows, tableKey));
          } else {
            values = form
              && form[tableKey]
              && form[tableKey].map(val => {
                return columns.map(col => {
                  if (col.type === 'hidden') {
                    return null;
                  }

                  const value = val[col.fieldKey];
                  if (Array.isArray(value)) {
                    return value.join(', ');
                  }
                  return value;
                });
              });
          }

          if (values) {
            const tableAOA = [cols, ...values];
            // argument order worksheet, data, cell origin
            XLSX.utils.sheet_add_aoa(ws, tableAOA, {
              origin: { r: originRow, c: 0 },
            });
            originRow += tableAOA.length + 1;
          }
        });
      }
    });

    const fileName = `${name}_${moment().format(
      'YYYY-MM-DD',
    )}.xlsx`.replace('/ /g', '_');

    const workbook = XLSX.write(wb, {
      bookType: 'xlsx',
      bookSST: false,
      type: 'base64',
    });

    dispatch(completeExcel());
    return { workbook, fileName };
  } catch (error) {
    log(error);
    dispatch(completeExcel());
  }
};

export const getUserPackagingTypeNotListedMessage = (packaging = []) => {
  const notListed = packaging.some(packagingType => packagingType.packagingTypeRowguid === 'userPackTypeNotListed');
  return notListed ? 'The customer specified their packaging type was not listed in the list provided. They will need a custom packaging type setup for one or more of their products.' : '';
};

export const sendSetupEmail = () => async (dispatch, getState) => {
  dispatch(updateSendingEmail(true));
  dispatch(updateEmailSent(false));
  const { form, logo, floorplan } = getState();
  const { packaging } = form;

  let name = 'A new customer';
  if (form && form.facility) {
    if (form.facility.name) {
      name = form.facility.name;
    }
  }

  let excelBase64;
  let excelFileName;
  try {
    const { workbook, fileName } = dispatch(buildExcelFile());
    excelBase64 = workbook;
    excelFileName = fileName;
  } catch (excelError) {
    // eslint-disable-next-line no-alert
    alert('There was a problem compiling your data');
    log(excelError);
    dispatch(updateSendingEmail(false));
    return;
  }

  const attachments = [];
  if (excelBase64 && excelFileName) {
    attachments.push({
      content: excelBase64,
      filename: excelFileName,
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      disposition: 'attachment',
      contentId: 'excel',
    });
  }

  if (logo) {
    attachments.push({
      content: logo.content,
      fileName: logo.fileName,
      type: logo.type,
      disposition: 'attachment',
      contentId: 'logo',
    });
  }

  if (floorplan) {
    attachments.push({
      content: floorplan.content,
      fileName: floorplan.fileName,
      type: floorplan.type,
      disposition: 'attachment',
      contentId: 'floorplan',
    });
  }


  // possible future dev: generate a list of Products from "form" that have packaging types 'not listed'

  const userPackagingTypeNotListedMessage = getUserPackagingTypeNotListedMessage(packaging);

  const contactInfo = form.implementation;
  const msgBody = `${name} has finished their site setup and sent you the attached files.

    Contact Name: ${contactInfo.contactName}
    Preferred Email: ${contactInfo.contactEmail}

    ${userPackagingTypeNotListedMessage}

    `;

  const msg = {
    from: 'success@goekos.com',
    subject: `${name} submitted their site setup!`,
    text: msgBody,
    attachments,
  };

  try {
    /*
        400/500 is not an error with fetch, it's a response. You'd only get an exception (rejection) when there's a network problem.
        When the server answers, you have to check whether it's good or not:
        */
    const response = await fetch(
      'https://us-central1-ekos-828da.cloudfunctions.net/email/send',
      {
        headers: {
          'content-type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(msg),
      },
    );
    if (response.ok) {
      dispatch(updateSendingEmail(false));
      dispatch(updateEmailSent(true));
    } else {
      alert('There was a problem submitting your site setup');
      dispatch(updateSendingEmail(false));
    }
  } catch (mailErr) {
    alert('There was a problem submitting your site setup');
    log(mailErr);
    dispatch(updateSendingEmail(false));
  }
};
