import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const NonAlcoholicIcon = props => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 52.86 152.68"
    >
      <circle
        cx="20.21"
        cy="81.43"
        r="3.65"
      />
      <path d="M20.21,97.78a3.65,3.65,0,1,0-3.65-3.65A3.64,3.64,0,0,0,20.21,97.78Z" />
      <circle
        cx="32.2"
        cy="81.43"
        r="3.65"
      />
      <path d="M43.32,25.78A12.84,12.84,0,0,1,38.76,17h.75a3,3,0,0,0,3-3V3a3,3,0,0,0-3-3H13.35a3,3,0,0,0-3,3V14a3,3,0,0,0,3,3h.76a12.88,12.88,0,0,1-4.56,8.78A26.35,26.35,0,0,0,0,46.11v95.51a12.76,12.76,0,0,0,6,10.83l.36.23h40.2l.37-.23a12.78,12.78,0,0,0,6-10.83V46.11A26.37,26.37,0,0,0,43.32,25.78ZM13.35,3h0V3Zm0,0L39.49,3l0,11H14.17v0h-.8ZM11.46,28.09A15.85,15.85,0,0,0,17.13,17h18.6A15.89,15.89,0,0,0,41.4,28.09a23.36,23.36,0,0,1,8.46,18v15.2c-2.9-2.31-7.18-2.15-11-2a19.55,19.55,0,0,1-4.83-.15c-4.19-1.13-6.21-6-6.23-6l-1.39-3.47L25,53.12c0,.05-2,4.89-6.23,6A19.53,19.53,0,0,1,14,59.3c-3.8-.14-8.07-.3-11,2V46.11A23.4,23.4,0,0,1,11.46,28.09ZM3,68.72c0-2.22.51-3.78,1.56-4.79,2-1.91,5.87-1.76,9.3-1.63A20.83,20.83,0,0,0,19.59,62a12.39,12.39,0,0,0,6.84-5.26A12.38,12.38,0,0,0,33.28,62,20.75,20.75,0,0,0,39,62.3c3.43-.13,7.32-.28,9.31,1.63,1,1,1.55,2.57,1.55,4.79V127.4l-34.66,0L3,127.4Zm42.64,81H7.22a9.89,9.89,0,0,1-3-3.3H48.62A9.79,9.79,0,0,1,45.64,149.68Zm4.22-8.06a9.7,9.7,0,0,1-.41,2.76h-46A9.7,9.7,0,0,1,3,141.62V130.4l34.67,0,12.19,0Z" />
      <rect
        x="17.79"
        y="5.81"
        width="2"
        height="5.31"
      />
      <rect
        x="25.43"
        y="5.81"
        width="2"
        height="5.31"
      />
      <rect
        x="33.07"
        y="5.81"
        width="2"
        height="5.31"
      />
      <rect
        x="17.01"
        y="105.71"
        width="18.84"
        height="2"
      />
      <rect
        x="20.66"
        y="110.41"
        width="11.54"
        height="2"
      />
      <path d="M45,118.44V72.22a4.86,4.86,0,0,0-4.85-4.86H12.7a4.87,4.87,0,0,0-4.86,4.86v46.22a4.87,4.87,0,0,0,4.86,4.86H40.17A4.86,4.86,0,0,0,45,118.44Zm-35.18,0V72.22a2.86,2.86,0,0,1,2.86-2.86H40.17A2.86,2.86,0,0,1,43,72.22v46.22a2.86,2.86,0,0,1-2.85,2.86H12.7A2.86,2.86,0,0,1,9.84,118.44Z" />
    </SvgIcon>
  );
};

export default NonAlcoholicIcon;
