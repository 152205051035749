/**
 * Outputs JavaScript error to the console.
 * NOTE: This will show up in production environments
 *
 * @param {Error} err - JavaScript error to log
 * @param {string} msg - Message to be outputted
 * @returns undefined
 */
const log = (msg = '', err) => {
  if (!window.console) {
    return;
  }

  // eslint-disable-next-line no-console
  (console.error || console.log)(msg, err);
};

export default log;
