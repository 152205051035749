import beerSteps from './beerConfig';
import ciderSteps from './ciderConfig';
import wineSteps from './wineConfig';
import foodSteps from './foodConfig';
import nonAlcoholSteps from './nonAlcoholConfig';
import roasterSteps from './roasterConfig';

const getConfigByBrand = brand => {
  switch (brand) {
    case 'beer':
      return beerSteps;

    case 'wine':
      return wineSteps;

    case 'cider':
      return ciderSteps;

    case 'food':
      return foodSteps;

    case 'non-alcoholic':
      return nonAlcoholSteps;

    case 'roaster':
      return roasterSteps;

    default:
      return null;
  }
};

export default getConfigByBrand;
