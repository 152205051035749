import allPackagingTypes from './allPackagingTypes';

import {
  BEER_CIDER_PACKAGING_TYPES,
  WINE_PACKAGING_TYPES,
  FOOD_ROASTER_PACKAGING_TYPES,
  NON_ALCOHOLIC_PACKAGING_TYPES,
} from '../../constants';

let formattedPackagingTypes = [];

/**
 * TODO - once this is coming from an actual server call it might makes sense to break this into 2 functions
 * one for the fetch and one for formatting
 */
export const fetchAndFormatAllPackagingTypes = () => {
  // these are only needed in this one instance so not putting them in a constants file
  const packageTypeClasses = {
    RACKED_KEG: 'DBEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    BOTTLED_BULK: 'DCEAC8BF-9CDB-E311-A60A-D4AE5266E0C4',
    BOTTLED_CASE: 'F7280FCA-9CDB-E311-A60A-D4AE5266E0C4',
    RACKED_BULK: '5FC6B3D1-9CDB-E311-A60A-D4AE5266E0C4',
  };

  const draftPackageTypeRowguid = '8C612BE1-6C0F-E911-B3AE-D4AE5266E0C4';

  const groupedPackagingTypes = allPackagingTypes.reduce((acc, type) => {
    // filter out all bulk packaging and draft packaging
    if (type.packageTypeClass !== packageTypeClasses.RACKED_BULK
      && type.rowguid !== draftPackageTypeRowguid) {
      const packagingType = {
        label: type.title,
        rowguid: type.rowguid,
      };

      const uppercaseLabel = packagingType.label.toUpperCase();
      const keys = ['BOTTLE', 'CAN', 'KEG', 'BAG', 'JAR'];

      const categoryKey = keys.find(key => uppercaseLabel.includes(key));
      if (categoryKey && acc[categoryKey]) {
        packagingType.category = categoryKey;
        acc[categoryKey].push(packagingType);
        return acc;
      }

      if (categoryKey && !acc[categoryKey]) {
        packagingType.category = categoryKey;
        acc[categoryKey] = [packagingType];
        return acc;
      }

      if (!categoryKey && !acc.OTHER) {
        acc.OTHER = [packagingType];
        return acc;
      }

      acc.OTHER.push(packagingType);
    }

    return acc;
  }, {});

  formattedPackagingTypes = [
    ...groupedPackagingTypes.BOTTLE,
    ...groupedPackagingTypes.CAN,
    ...groupedPackagingTypes.KEG,
    ...groupedPackagingTypes.BAG,
    ...groupedPackagingTypes.JAR,
    ...groupedPackagingTypes.OTHER,
  ];

  return formattedPackagingTypes;
};

export const formatPackagingTypesForBrand = brand => {
  let defaultPackagingTypesForBrand = [{
    rowguid: 'userPackTypeNotListed',
    label: 'My packaging type is not listed.',
    checked: false, // only need this if we move forward with checkbox OB-99
  }];

  switch (brand) {
    case 'beer':
    case 'cider':
      defaultPackagingTypesForBrand = defaultPackagingTypesForBrand.concat(
        BEER_CIDER_PACKAGING_TYPES,
      );
      break;
    case 'wine':
      defaultPackagingTypesForBrand = defaultPackagingTypesForBrand.concat(
        WINE_PACKAGING_TYPES,
      );
      break;
    case 'food':
    case 'roaster':
      defaultPackagingTypesForBrand = defaultPackagingTypesForBrand.concat(
        FOOD_ROASTER_PACKAGING_TYPES,
      );
      break;
    case 'non-alcoholic':
      defaultPackagingTypesForBrand = defaultPackagingTypesForBrand.concat(
        NON_ALCOHOLIC_PACKAGING_TYPES,
      );
      break;
    default:
      defaultPackagingTypesForBrand = [];
  }

  if (defaultPackagingTypesForBrand.length === 0) {
    return formattedPackagingTypes;
  }

  const defaultPackagingTypesForBrandRowguids = defaultPackagingTypesForBrand.map(
    type => type.rowguid,
  );

  const formattedPackagingTypesForBrand = formattedPackagingTypes.reduce((acc, type) => {
    if (!defaultPackagingTypesForBrandRowguids.includes(type.rowguid)) {
      acc.push(type);
    }

    return acc;
  }, defaultPackagingTypesForBrand);

  return formattedPackagingTypesForBrand;
};
