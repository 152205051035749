import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputBase,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { sortBy } from 'lodash';

import * as actions from './actions';
import * as errorMessages from '../config/errorMessages';
import * as selectors from './selectors';
import { FIELD_TYPES } from '../constants';
import getMappedValuesByField from '../Hooks/getMappedValuesByField';
import checkEquipmentBarrelLocationNames from './fieldUtils/checkEquipmentBarrelLocationNames';
import checkFieldForDuplicationError from './fieldUtils/checkFieldForDuplicationError';
import InlineErrorMessage from '../Components/InlineErrorMessage';

import PackagingComponentsList from '../Packaging/PackagingComponentsList';
import PackagingTypesField from '../Packaging/PackagingTypesField';
import ComplexSelect from '../Components/MenuComponents/ComplexSelect';

import KegDepositInput from '../Components/KegDepositInput';

import EquipmentCapacityComponent from '../Components/EquipmentPage/EquipmentCapacityComponent';

const Field = props => {
  const {
    allowCustom,
    arrayIndex,
    arrayKey,
    changeField,
    changeCheckboxField,
    value,
    type,
    label,
    menuOptions,
    onChange,
    packagingTypes,
    packagingComponents,
    productClass,
    products,
    selectedOptions,
    fieldKey,
    updateOptions,
    stepKey,
    formStateForStep,
    setErrorForStep,
    clearErrorForStep,
    defaultValue, // <- not used, but not meant to be included in ...rest collection
    showRequiredFieldErrors,
    handleComplexSelectChange,
    handleEquipmentSystemAdd,
    packagingTypeRowguid,
    required,
    inputProps,
    error,
    ...rest
  } = props;

  const standardProps = {
    fullWidth: true,
    margin: 'normal',
    value: value || '',
    onChange: onChange || changeField,
    label,
    ...rest,
  };

  const [customValue, setCustomValue] = useState('');
  const handleCustomInput = e => setCustomValue(e.target.value);
  const handleCustomSubmit = () => {
    const newOptions = [
      ...menuOptions,
      { label: customValue, value: customValue },
    ];
    updateOptions(newOptions);
    standardProps.onChange({
      target: {
        value: [...selectedOptions, customValue].filter(opt => !!opt),
      },
    });
    setCustomValue('');
  };

  const handleCheckboxChange = e => {
    changeField({ target: { value: e.target.checked } });
  };

  const mappedValuesByField = getMappedValuesByField(formStateForStep);
  const errorState = useSelector(selectors.getErrorState);
  /*  checkErrorStateForFieldError assumes errorState object will
        follow errorState[stepKey][fieldKey] for all errors
        that utilize <Field> component and is passed to
        'error' prop
    */
  const checkErrorStateForFieldError = () => {
    if (errorState && errorState[stepKey] && !!errorState[stepKey][fieldKey]) {
      return true;
    }
    return false;
  };

  const getRequiredFieldError = () => {
    return showRequiredFieldErrors && required && !value
        && (!errorState[stepKey] || !errorState[stepKey][fieldKey]);
  };

  const fieldSpecificError = checkErrorStateForFieldError();
  const hasDuplicateError = checkFieldForDuplicationError(
    fieldKey,
    arrayKey,
    arrayIndex,
    mappedValuesByField,
    value,
  );
  const requiredFieldError = getRequiredFieldError();

  // checking to see if there are any duplicates among barrel/equipment/location names
  const equipmentBarrelLocationDuplicate = checkEquipmentBarrelLocationNames(
    stepKey,
    arrayKey,
    fieldKey,
    arrayIndex,
  );

  const validateEmail = () => {
    if (fieldKey === 'contactEmail') {
      if (formStateForStep && (formStateForStep.contactEmail || formStateForStep.contactEmail === '')) {
        const invalidEmail = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formStateForStep.contactEmail.trim()) && formStateForStep.contactEmail !== '';
        return invalidEmail;
      }
      return true;
    }
  };

  const isEmailValid = validateEmail();

  const shouldValidateOnBlur = useCallback(() => {
    // stepKey and arrayKey are used interchangeably depending on the content of the form step
    const key = stepKey || arrayKey;
    switch (key) {
      case 'equipment':
        return fieldKey === 'name' || fieldKey === 'abbreviation' || fieldKey === 'site';
      case 'barrels':
        return fieldKey === 'number' || fieldKey === 'site';
      case 'storageLocations':
        return fieldKey === 'name' || fieldKey === 'site';
      case 'implementation':
        return fieldKey === 'contactEmail';
      case 'products':
        return fieldKey === 'value';
      case 'inventoryItems':
        return fieldKey === 'item';
      case 'sites':
        return fieldKey === 'siteName';
      default:
        return false;
    }
  }, [stepKey, arrayKey, fieldKey]);

  const key = stepKey || arrayKey;

  const barrels = useSelector(selectors.getBarrels) || [];
  const equipment = useSelector(selectors.getEquipment) || [];
  const storageLocations = useSelector(selectors.getStorageLocations) || [];
  // this fires when user deletes a barrel, equipment, storageLocation and checks if any errors should be thrown
  // TODO: Address exhaustive-deps issue
  /* eslint-disable react-hooks/exhaustive-deps */
  const handleBlur = useCallback(() => {
    const stepArrayKey = stepKey || arrayKey;
    if (!shouldValidateOnBlur()) {
      return;
    }

    if ((equipmentBarrelLocationDuplicate && (stepArrayKey === 'equipment' || stepArrayKey === 'storageLocations' || stepArrayKey === 'barrels'))
      || hasDuplicateError
      || isEmailValid) {
      let errorMessage;
      if (equipmentBarrelLocationDuplicate
        && (fieldKey === 'name' || fieldKey === 'number' || fieldKey === 'site')) {
        errorMessage = equipmentBarrelLocationDuplicate;
        setErrorForStep(errorMessage, fieldKey);
      } else if (hasDuplicateError || isEmailValid) {
        const errorMessageMap = {
          name: stepArrayKey === 'storageLocations' ? errorMessages.storageNameDuplicates : errorMessages.equipmentNameDuplicates,
          abbreviation: errorMessages.equipmentAbbrevDuplicates,
          number: errorMessages.barrelNumberDuplicates,
          contactEmail: errorMessages.invalidEmailAddress,
          value: errorMessages.productNameDuplicates,
          item: errorMessages.inventoryItemNameDuplicates,
          siteName: errorMessages.siteNameDuplicates,
        };
        errorMessage = errorMessageMap[fieldKey];
        if (fieldKey === 'site' && hasDuplicateError) {
          errorMessage = errorMessageMap.name;
          setErrorForStep(errorMessage, 'name');
          return;
        }
        setErrorForStep(errorMessage, fieldKey);
      }
    } else {
      clearErrorForStep();
    }
  }, [
    shouldValidateOnBlur,
    hasDuplicateError,
    equipmentBarrelLocationDuplicate,
    isEmailValid,
    fieldKey,
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  // TODO: Address exhaustive-deps issue
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (key === 'equipment' || key === 'storageLocations' || key === 'barrels') {
      handleBlur();
    }
  }, [
    equipment.length,
    barrels.length,
    storageLocations.length,
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  let menuItems = [];

  if (menuOptions.length > 0) {
    menuItems = sortBy(menuOptions, 'label').map(item => (
      <MenuItem
        key={item.value}
        value={item.value}
      >
        {item.label}
      </MenuItem>
    ));
  }

  const customInput = (
    <ListItem
      key="custom"
      button={false}
      dense
      divider
      tabIndex={-1}
      onClick={e => e.preventDefault()}
    >
      <ListItemText
        primary={(
          <InputBase
            fullWidth
            onChange={handleCustomInput}
            placeholder="Custom"
            value={customValue}
          />
                  )}
      />
      <ListItemSecondaryAction>
        <IconButton
          onClick={handleCustomSubmit}
          disabled={!customValue}
        >
          <Check color="primary" />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
  switch (type) {
    case FIELD_TYPES.HIDDEN:
      /**
             * hidden fields will not render markup
             * this field exists so that we have a way to toggle the hasBeenTouched flag for steps with no required fields or tables
             * also used to hide fields we don't want the user to fill out but that we are considering bringing back in implementation
            */
      return null;

    case FIELD_TYPES.PACKAGING_COMPONENTS:
      return (
        <PackagingComponentsList
          arrayKey={arrayKey}
          arrayIndex={arrayIndex}
          fieldKey={fieldKey}
        />
      );

    case FIELD_TYPES.PACKAGING_TYPES:
      return (
        <PackagingTypesField
          {...standardProps}
          packagingTypeRowguid={packagingTypeRowguid}
          arrayKey={arrayKey}
          arrayIndex={arrayIndex}
          fieldKey={fieldKey}
          setErrorForStep={setErrorForStep}
          clearErrorForStep={clearErrorForStep}
        />
      );

    case FIELD_TYPES.EQUIPMENT_CAPACITY_FIELD:
      return (
        <EquipmentCapacityComponent
          arrayKey={arrayKey}
          arrayIndex={arrayIndex}
          fieldKey={fieldKey}
          {...standardProps}
        />
      );

    case FIELD_TYPES.KEG_DEPOSIT:
      return (
        <KegDepositInput
          stepKey={stepKey}
          fieldKey={fieldKey}
          {...standardProps}
        />
      );

    case FIELD_TYPES.TEXT:
    case FIELD_TYPES.TEXT_AREA:
    case FIELD_TYPES.NUMBER:
    case FIELD_TYPES.TEL:
      return (
        <>
          <TextField
            {...standardProps}
            type={type}
            inputProps={inputProps}
            onBlur={handleBlur}
            error={!!(fieldSpecificError || requiredFieldError || error || hasDuplicateError || (equipmentBarrelLocationDuplicate && (fieldKey === 'name' || fieldKey === 'number')))}
          />
          {
            /* assumes errorState object keys will follow this (errorState[stepKey][fieldKey]: "fieldKey specific error message") pattern */
          }
          {errorState[stepKey] && errorState[stepKey][fieldKey] && (
          <InlineErrorMessage>
            {errorState[stepKey][fieldKey]}
          </InlineErrorMessage>
          )}

          {getRequiredFieldError() && (
          <InlineErrorMessage>
            {label} is required
          </InlineErrorMessage>
          )}
        </>
      );

    case FIELD_TYPES.SELECT:
      return (
        <>
          <TextField
            {...standardProps}
            select
            error={getRequiredFieldError() || hasDuplicateError}
            onBlur={handleBlur}
          >
            {menuItems}
          </TextField>

          {getRequiredFieldError() && (
          <InlineErrorMessage>
            {label} is required
          </InlineErrorMessage>
          )}
        </>
      );

    case FIELD_TYPES.MULTI_SELECT:
      if (menuOptions.length > 0) {
        menuItems = sortBy(menuOptions, 'label').map(item => (
          <MenuItem
            key={item.value}
            value={item.value}
          >
            <Checkbox
              color="primary"
              checked={
                selectedOptions
                && Array.isArray(selectedOptions)
                && selectedOptions.length > 0
                && selectedOptions.findIndex(
                  option => option === item.value,
                ) > -1
              }
            />
            <ListItemText primary={item.value} />
          </MenuItem>
        ));
      }
      if (allowCustom) {
        menuItems.splice(0, 0, customInput);
      }
      return (
        <TextField
          {...standardProps}
          select
          SelectProps={{
            multiple: true,
            renderValue: textFieldValue => `${textFieldValue.length} selected`,
          }}
        >
          {menuItems}
        </TextField>
      );

    case FIELD_TYPES.CHECKBOX: {
      const {
        value: checkboxValue,
        fullWidth,
        ...restProps
      } = standardProps;
      // if (noContractProduction()) {
      //     return null;
      // } else {
      return (
        <FormControlLabel
          control={(
            <Checkbox
              color="primary"
              checked={value}
              {...restProps}
              onChange={onChange || handleCheckboxChange}
            />
          )}
          label={label}
        />
      );
      // }
    }

    case FIELD_TYPES.COMPLEX_SELECT:
      return (
        <ComplexSelect
          {...standardProps}
          allowCustom
          value={standardProps.value}
          optionSelectedCallback={handleComplexSelectChange}
          customInputCallback={complexSelectValue => (
            handleEquipmentSystemAdd(complexSelectValue, arrayIndex)
          )}
        />
      );
    default:
      return <TextField {...standardProps} />;
  }
};

/* eslint-disable react/require-default-props */
Field.propTypes = {
  allowCustom: PropTypes.bool,
  arrayKey: PropTypes.string,
  arrayIndex: PropTypes.number,
  changeField: PropTypes.func,
  changeCheckboxField: PropTypes.func,
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  options: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  onChange: PropTypes.func, // custom onchange function
  selectedOptions: PropTypes.arrayOf(PropTypes.shape({})),
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.shape({}),
    PropTypes.bool,
  ]),
  updateOptions: PropTypes.func,
  label: PropTypes.string,
  packagingTypes: PropTypes.arrayOf(PropTypes.shape()),
  packagingComponents: PropTypes.arrayOf(PropTypes.shape()),
  productClass: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.shape()),
  fieldKey: PropTypes.string,
  stepKey: PropTypes.string,
  formStateForStep: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.shape({}),
  ]),
  setErrorForStep: PropTypes.func,
  clearErrorForStep: PropTypes.func,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.shape({}),
    PropTypes.bool,
  ]), // <- not used, but not meant to be included in ...rest collection
  showRequiredFieldErrors: PropTypes.bool,
  handleComplexSelectChange: PropTypes.func,
  handleEquipmentSystemAdd: PropTypes.func,
  packagingTypeRowguid: PropTypes.string,
  required: PropTypes.bool,
  inputProps: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.shape({}),
    PropTypes.bool,
  ]),
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.shape({}),
    PropTypes.bool,
  ]),
};
/* eslint-enable react/require-default-props */

Field.defaultProps = {
  menuOptions: [],
};

const mapStateToProps = (state, ownProps) => {
  // TODO: This logic should be moved to selectors
  const { form, showRequiredFieldErrors } = state;
  const {
    arrayKey,
    arrayIndex,
    fieldKey,
    stepKey,
    type,
  } = ownProps;

  const formStateForStep = form && (stepKey || arrayKey) ? form[stepKey || arrayKey] : null;
  let value = type === FIELD_TYPES.MULTI_SELECT ? [] : '';

  if (form && fieldKey) {
    if (arrayKey && arrayIndex > -1 && fieldKey) {
      if (
        form[arrayKey]
        && form[arrayKey][arrayIndex]
        && form[arrayKey][arrayIndex][fieldKey]
      ) {
        value = form[arrayKey][arrayIndex][fieldKey];
      } else {
        value = type === FIELD_TYPES.MULTI_SELECT ? [] : '';
      }
    } else {
      value = form && form[stepKey] ? form[stepKey][fieldKey] : '';
    }
  }

  // for the packaging types field, we also need to pass the packagingTypeRowguid
  let packagingTypeRowguid;
  if (form && fieldKey && fieldKey === 'packagingTypes') {
    if (
      form[arrayKey]
      && form[arrayKey][arrayIndex]
      && form[arrayKey][arrayIndex].packagingTypeRowguid
    ) {
      packagingTypeRowguid = form[arrayKey][arrayIndex].packagingTypeRowguid;
    }
  }

  let menuOptions = ownProps.options;
  if (ownProps.options && !Array.isArray(ownProps.options)) {
    menuOptions = form[ownProps.options];
  }
  let selectedOptions = [];
  if (
    form
    && form[arrayKey]
    && form[arrayKey][arrayIndex]
    && form[arrayKey][arrayIndex][fieldKey]
    && Array.isArray(form[arrayKey][arrayIndex][fieldKey])
    && ownProps.type === FIELD_TYPES.MULTI_SELECT
  ) {
    selectedOptions = form[arrayKey][arrayIndex][fieldKey];
  }

  return {
    formStateForStep,
    menuOptions,
    selectedOptions,
    value,
    showRequiredFieldErrors,
    packagingTypeRowguid,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeField: event => dispatch(
    actions.changeField(
      ownProps.stepKey,
      ownProps.fieldKey,
      event.target.value,
    ),
  ),
  changeCheckboxField: event => dispatch(
    actions.changeField(
      ownProps.stepKey,
      ownProps.fieldKey,
      event.target.checked,
    ),
  ),
  updateOptions: newOptions => dispatch(
    actions.changeField(
      null,
      ownProps.options,
      newOptions,
    ),
  ),
  setErrorForStep: (errorMessage, fieldKey) => dispatch(
    actions.setStepValidationError(
      (ownProps.stepKey || ownProps.arrayKey),
      (fieldKey || ownProps.fieldKey),
      errorMessage,
    ),
  ),
  clearErrorForStep: () => dispatch(
    actions.clearStepValidationError(
      (ownProps.stepKey || ownProps.arrayKey),
      ownProps.fieldKey,
    ),
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Field);
