import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Checkbox,
  Select,
  MenuItem,
  FormControl,
} from '@material-ui/core';

import ComplexMenuSearch from './ComplexMenuSearch';
import ComplexMenuCustomInput from './ComplexMenuCustomInput';

const ComplexSelect = ({
  allowSearch,
  allowCustom,
  label,
  value,
  options,
  customInputCallback,
  optionSelectedCallback,
  hasError,
  persistentOption,
}) => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [allOptions, setAllOptions] = useState(options);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [isCustomInputOpen, setIsCustomInputOpen] = useState(false);

  useEffect(() => {
    setAllOptions(options);
    setFilteredOptions(options);
  }, [options]);

  const handleSelectOpen = () => setIsSelectOpen(true);

  const handleSelectClose = event => {
    const valueSelected = event.target.dataset.value;
    // these are the ids of the elements that make up the search input and the custom input
    const customFieldIds = [
      'searchContainer',
      'searchInput',
      'searchInputAdornment',
      'searchIcon',
    ];

    if (valueSelected) {
      optionSelectedCallback(valueSelected);
      setFilteredOptions(allOptions);
      setIsCustomInputOpen(false);
      setIsSelectOpen(false);
    } else if (isSelectOpen && !customFieldIds.includes(event.target.id)) {
      setFilteredOptions(allOptions);
      setIsCustomInputOpen(false);
      setIsSelectOpen(false);
    }
  };

  const addCustomOption = customOption => {
    customInputCallback(customOption);
    setIsSelectOpen(false);
  };

  return (
    <FormControl
      fullWidth
      margin={label ? 'normal' : 'none'}
      error={hasError}
    >
      <Select
        open={isSelectOpen}
        value={value || ''}
        onOpen={handleSelectOpen}
        onClose={handleSelectClose}
        MenuProps={{
          variant: 'menu',
        }}
      >
        {
          allowSearch && (
            <ComplexMenuSearch
              allOptions={allOptions}
              queryFilterCallback={updatedOptions => setFilteredOptions(updatedOptions)}
              label={label}
              isCustomInputOpen={isCustomInputOpen}
              persistentOption={persistentOption}
            />
          )
        }

        {
          allowCustom && (
            <ComplexMenuCustomInput
              isCustomInputOpen={isCustomInputOpen}
              label={label}
              toggleCustomInput={() => setIsCustomInputOpen(!isCustomInputOpen)}
              addCustomOption={addCustomOption}
            />
          )
        }

        {filteredOptions.map(option => (
          <MenuItem
            key={option.value}
            value={option.value}
          >
            {Object.prototype.hasOwnProperty.call(option, 'checked') && isSelectOpen && (
              <Checkbox
                onChange={() => optionSelectedCallback(option.value)}
                checked={value === option.rowguid}
              />
            )}
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

ComplexSelect.propTypes = {
  allowSearch: PropTypes.bool,
  allowCustom: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  customInputCallback: PropTypes.func,
  optionSelectedCallback: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.bool,
    PropTypes.shape({}),
  ]),
  persistentOption: PropTypes.shape(),
};

ComplexSelect.defaultProps = {
  allowSearch: false,
  allowCustom: false,
  label: '',
  customInputCallback: () => {},
  hasError: false,
  value: undefined,
  persistentOption: {},
};

export default ComplexSelect;
