import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

const StepHeading = ({ step }) => {
  const { heading, subheading } = step;

  return (
    <Grid
      item
      xs={12}
    >
      <Typography
        variant="h4"
        gutterBottom
      >{heading}
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
      >{subheading}
      </Typography>
    </Grid>
  );
};

StepHeading.propTypes = {
  step: PropTypes.shape({
    heading: PropTypes.string,
    subheading: PropTypes.string,
  }).isRequired,
};

export default StepHeading;
