import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const FoodIcon = props => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 113.41 182.18"
    >
      <path d="M50.71,50.15a3.65,3.65,0,1,0-3.65-3.64A3.65,3.65,0,0,0,50.71,50.15Z" />
      <path d="M50.71,62.86a3.65,3.65,0,1,0-3.65-3.65A3.65,3.65,0,0,0,50.71,62.86Z" />
      <path d="M62.7,50.15a3.65,3.65,0,1,0-3.65-3.64A3.64,3.64,0,0,0,62.7,50.15Z" />
      <path d="M104.63,166V90.56A48,48,0,0,0,73.41,45.63V36.47L84.56,25.89V0H28.85V25.89L40,36.47v9.16A48,48,0,0,0,8.78,90.56V166H0v16.22H113.41V166ZM47.76,92.06H65.65a7.77,7.77,0,0,0,7.76-7.76V80.07a19.53,19.53,0,0,1,3,10.49A19.74,19.74,0,1,1,40,80.06V84.3A7.77,7.77,0,0,0,47.76,92.06ZM43,84.3v-47H70.41v47a4.76,4.76,0,0,1-4.76,4.76H47.76A4.76,4.76,0,0,1,43,84.3Zm-3-9.14A22.73,22.73,0,1,0,78.83,95.8H90A33.65,33.65,0,1,1,40,61.35ZM92.91,92.8H79.33a20.87,20.87,0,0,0,.11-2.24c0-.75,0-1.5-.1-2.24H92.91a2.24,2.24,0,1,1,0,4.48ZM78.82,85.32a22.54,22.54,0,0,0-5.41-10.15V61.35a33.92,33.92,0,0,1,16.53,24Zm-36.72-51-8-7.58H79.3l-8,7.58ZM81.56,3V23.75H31.85V3ZM11.78,90.56A45,45,0,0,1,40,48.84V58A36.64,36.64,0,1,0,93,95.79a5.23,5.23,0,0,0,0-10.46A36.89,36.89,0,0,0,73.41,58V48.84a45,45,0,0,1,28.22,41.72V166H87V131.11H26.45V166H11.78ZM46.92,166a15.08,15.08,0,0,1-2.27-12.38,8.39,8.39,0,0,0,5.9,3.67l1.8.28-.07-1.81c-.17-4.93,3-8.1,5.28-9.72-.07,10.32,3.29,15.21,3.45,15.42l.82,1.16,1.2-.75c2.9-1.82,4.22-5.27,4.82-8,2.56,4.75.94,10.17.22,12.09Zm21-17-2.48-2.42-.07,3.46c0,1.63-.59,5.75-2.66,8.15-1-2.26-2.51-7.11-2-14.78l.16-2.47-2.26,1c-.36.16-8.3,3.77-9.19,11.87-1.3-.59-2.42-1.79-3.63-4.37l-1.23-2.63-1.43,2.53A17.79,17.79,0,0,0,43.41,166h-14V134.11H84V166H71.24C72.35,162.4,73.76,154.75,67.89,149Zm42.52,30.17H3V169H45.39l.15.19.25-.19h64.62Z" />
    </SvgIcon>
  );
};

export default FoodIcon;
