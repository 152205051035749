import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core';

import { STEP_KEYS } from '../constants';
import { toggleRequiredFieldErrors } from './actions';
import * as selectors from './selectors';

import Message from '../Components/Message';
import StepHeading from '../Components/StepComponents/StepHeading';

const Review = ({
  classes,
  handleStep,
  incompleteSteps,
  showRequiredFieldErrors,
  toggleRequiredFieldErrors: toggleRequiredFieldErrorsProp,
  getIncompleteSteps,
}) => {
  if (!showRequiredFieldErrors) {
    toggleRequiredFieldErrorsProp();
  }

  const [incompleteReqSteps, setIncompleteReqSteps] = useState([]);
  const [incompleteOptionalSteps, setIncompleteOptionalSteps] = useState([]);

  const requiredSteps = [STEP_KEYS.FACILITY, STEP_KEYS.IMPLEMENTATION, STEP_KEYS.RETAIL];

  const errorState = useSelector(selectors.getErrorState);

  const stepsWithErrors = incompleteSteps.filter(step => (
    (errorState[step.stepKey] && !isEmpty(errorState[step.stepKey]))
    || (errorState.barrels && !isEmpty(errorState.barrels) ? step.stepKey === 'equipment' : null)
  ));

  if (stepsWithErrors) {
    stepsWithErrors.forEach(step => requiredSteps.push(step.stepKey));
    getIncompleteSteps(stepsWithErrors);
  }

  const filterIncompleteSteps = useCallback(() => {
    // need to push steps with errors into 'required' section
    const required = incompleteSteps.filter(step => requiredSteps.includes(step.stepKey));
    const optional = incompleteSteps.filter(step => !requiredSteps.includes(step.stepKey));

    setIncompleteReqSteps(required);
    setIncompleteOptionalSteps(optional);
  }, [incompleteSteps, requiredSteps]);

  useEffect(() => {
    if (incompleteSteps.length > 0) {
      filterIncompleteSteps();
    }
  }, [incompleteSteps, filterIncompleteSteps]);


  return (
    <Grid
      container
      justify="center"
      spacing={3}
    >
      <StepHeading step={{ heading: 'Review Your Info' }} />

      {incompleteReqSteps.length > 0
            && (
            <Grid
              item
              xs={12}
            >
              <Message
                type="error"
                className={classes.messageContainer}
              >
                <div>
                  <Typography>
                    {/* eslint-disable-next-line max-len */}
                    The following section(s) are required to submit to Ekos. Please fix all errors before submitting.
                  </Typography>
                  <ul className={classes.messageList}>
                    {incompleteReqSteps.map(step => (
                      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
                      <li
                        key={step.heading}
                        onClick={() => handleStep(step.stepKey)}
                      >
                        <Typography className={classes.link}>{step.heading}</Typography>
                      </li>
                    ))}
                  </ul>
                </div>
              </Message>
            </Grid>
            )}

      {incompleteOptionalSteps.length > 0
            && (
            <Grid
              item
              xs={12}
            >
              <Message
                type="warning"
                className={classes.messageContainer}
              >
                <div className={classes.messageContent}>
                  <Typography>
                    {/* eslint-disable-next-line max-len */}
                    The following section(s) were not completed and will not be created in your Ekos:
                  </Typography>
                  <ul className={classes.messageList}>
                    {incompleteOptionalSteps.map(step => (
                      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
                      <li
                        key={step.heading}
                        onClick={() => handleStep(step.stepKey)}
                      >
                        <Typography className={classes.link}>{step.heading}</Typography>
                      </li>
                    ))}
                  </ul>
                </div>
              </Message>
            </Grid>
            )}

      {incompleteReqSteps.length === 0
            && (
            <Grid
              item
              xs={12}
            >
              <Typography gutterBottom>
                {/* eslint-disable-next-line max-len */}
                To complete your setup click the submit button to send your information to the Ekos team to begin building your site.
              </Typography>
              <Typography gutterBottom>
                {'If you have any questions please reach out to  '}
                <a
                  href="mailto:sales@goekos.com?subject=Customer%20Onboarding"
                  className={classes.link}
                >
                  sales@goekos.com
                </a>
                .
              </Typography>
            </Grid>
            )}

    </Grid>
  );
};

const styles = {
  messageContainer: {
    alignItems: 'flex-start',
    margin: '0 5px',
    paddingTop: 5,
  },
  messageContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  messageList: {
    paddingLeft: 5,
  },
  link: {
    color: '#008EFF',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
};

Review.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  handleStep: PropTypes.func.isRequired,
  incompleteSteps: PropTypes.arrayOf(PropTypes.shape()),
  getIncompleteSteps: PropTypes.func.isRequired,
  showRequiredFieldErrors: PropTypes.bool,
  toggleRequiredFieldErrors: PropTypes.func,
};

Review.defaultProps = {
  classes: {},
  incompleteSteps: [],
  showRequiredFieldErrors: false,
  toggleRequiredFieldErrors: () => {},
};

const mapStateToProps = state => {
  const { showRequiredFieldErrors } = state;

  return { showRequiredFieldErrors };
};

const mapDispatchToProps = dispatch => ({
  toggleRequiredFieldErrors: () => dispatch(toggleRequiredFieldErrors()),
});

const styledReview = withStyles(styles)(Review);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(styledReview);
