export const bottlePackagingComponents = [
  {
    label: '12oz Bottle',
    category: 'BOTTLE',
    tempId: 'b75e20-5c-8338-63b-df43330d12c',
  },
  {
    label: '355mL Bottle',
    category: 'BOTTLE',
    tempId: 'c6c31-8136-add3-c185-d6a67e0d433',
  },
  {
    label: '375mL Bottle',
    category: 'BOTTLE',
    tempId: '34e0546-fa8-1c6e-ecfb-b8023daabe',
  },
  {
    label: '750mL Bottle',
    category: 'BOTTLE',
    tempId: '863bbc4-2bef-00c6-ce55-727eaa88ca3c',
  },
  {
    label: '1L Bottle',
    category: 'BOTTLE',
    tempId: '8bf07c-5e2e-e8c7-638c-515ccf7c7042',
  },
  {
    label: 'Bottle Cap',
    category: 'BOTTLE',
    tempId: '7e80d30-665b-7aa1-fa1d-2dfeb1ff5c8',
  },
  {
    label: 'Bottle Label',
    category: 'BOTTLE',
    tempId: '2318c24-23ba-5007-50e2-5a8da505d018',
  },
  {
    label: 'Cork',
    category: 'BOTTLE',
    tempId: 'c2ff826-c58e-ec3b-1e43-fa8fc46c8a5',
  },
  {
    label: 'Cork Cage',
    category: 'BOTTLE',
    tempId: '85b7a8-10c8-238-68c1-7bb11a54dc41',
  },
];

export const canPackagingComponents = [
  {
    label: '12oz Can',
    category: 'CAN',
    tempId: 'ce23d7-2f53-8b2-fd7f-7bf35dc82c1',
  },
  {
    label: '16oz Can',
    category: 'CAN',
    tempId: '468ed1a-63a4-34b6-dcd0-beb0bef4e75',
  },
  {
    label: '355mL Can',
    category: 'CAN',
    tempId: 'b10125c-d77-cad-bd1b-bb28c72c7a',
  },
  {
    label: '473mL Can',
    category: 'CAN',
    tempId: 'a06031c-68f1-a0a7-4a70-7f04552c1e',
  },
  {
    label: 'Can Label',
    category: 'CAN',
    tempId: 'd22ad36-bcb-e44c-b60-d1b2231e8ff',
  },
  {
    label: 'Can Lid',
    category: 'CAN',
    tempId: '6320d1a-25f-f6fa-26c3-8f31517164b4',
  },
];

export const kegPackagingComponents = [
  {
    label: 'Keg Cap',
    category: 'KEG',
    tempId: '7b143ec-5ad-2dd-172a-c5106d0f6d4',
  },
  {
    label: 'Keg Collar',
    category: 'KEG',
    tempId: '128352-36b5-386-c82c-bfdfadc55c',
  },
  {
    label: 'Keg Shell - 1/2bbl',
    category: 'KEG',
    tempId: '427ec6-40c-e4a-fb27-224acf5fcad',
  },
  {
    label: 'Keg Shell - 1/6bbl',
    category: 'KEG',
    tempId: 'be38b32-bdd-816e-f4ff-8220ff53ad01',
  },
  {
    label: 'Keg Shell - 20L',
    category: 'KEG',
    tempId: 'b688c80-47dd-7b60-2bd-08534d4cb2',
  },
  {
    label: 'Keg Shell - 30L',
    category: 'KEG',
    tempId: 'd534776-ddbd-e78-7ce-fa647ec5c42f',
  },
  {
    label: 'Keg Shell - 50L',
    category: 'KEG',
    tempId: 'a760635-bf41-17ea-eab3-8e8504be27',
  },
];

export const bagPackagingComponents = [
  {
    label: '1oz Bag',
    category: 'BAG',
    tempId: '4d53fa5-c143-3c7a-366-45dbf6b130c5',
  },
  {
    label: '16oz Bag',
    category: 'BAG',
    tempId: 'bb61267-125e-c273-3f2a-426b6d50e76',
  },
  {
    label: '1lb Bag',
    category: 'BAG',
    tempId: '4d66462-dc61-21d-e16-8277a82260',
  },
  {
    label: '5lb Bag',
    category: 'BAG',
    tempId: '67e64b8-7e31-d0d5-e1-818fe1b252e3',
  },
];

export const jarPackagingComponents = [
  {
    label: '12oz Jar',
    category: 'JAR',
    tempId: '8d81bf-28d-285-4f43-f3be21fd42f',
  },
  {
    label: 'Jar Lid',
    category: 'JAR',
    tempId: 'e68c76-71e-b8eb-66f-8c063a6cab1',
  },
  {
    label: 'Jar Label',
    category: 'JAR',
    tempId: '0cddb55-c7f7-468f-8bef-ea7df5dd10c7',
  },
];

export const otherPackagingComponents = [
  {
    label: 'Case Box',
    tempId: '63c4e-6ef3-1d5-bfa-3588a5b85811',
  },
  {
    label: 'Case Tray',
    tempId: 'e06b78-ce14-cece-f737-22a78c117dc',
  },
  {
    label: 'Carrier - 6 pack',
    tempId: '2cc468-af2-088-a5b6-5d76781d02',
  },
  {
    label: 'Carrier - 4 pack',
    tempId: 'ec67640-5713-e0a0-78cd-17d65f544a64',
  },
  {
    label: 'Pak Tech - 6 pack',
    tempId: 'b277583-cb35-ae28-2d4a-e02073dc34',
  },
  {
    label: 'Pak Tech - 4 pack',
    tempId: '40a308-5061-4428-7dac-c1d3315e3cf',
  },
];
