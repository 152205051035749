import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Fuse from 'fuse.js';

import {
  MenuItem,
  TextField,
  InputAdornment,
  withStyles,
} from '@material-ui/core';

import { Search } from '@material-ui/icons';

// left this in the component file for now since it is the only place that we use Fuse.js
const initFuse = data => {
  const search = {
    shouldSort: true,
    threshold: 0.3,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: [
      'label',
    ],
  };

  return new Fuse(data, search);
};

const ComplexMenuSearch = props => {
  const {
    classes,
    allOptions,
    queryFilterCallback,
    label,
    isCustomInputOpen,
    persistentOption,
  } = props;

  const [queryString, setQueryString] = useState('');
  const [fuse, setFuse] = useState(initFuse(allOptions));

  // TODO: Address exhaustive-deps issue
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const newFuse = initFuse(allOptions);
    setFuse(newFuse);

    if (queryString !== '') {
      const newFilteredOptions = fuse.search(queryString);
      queryFilterCallback(newFilteredOptions);
    }
  }, [allOptions]);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    setQueryString('');
  }, [isCustomInputOpen]);

  const updateQueryAndFilterOptions = e => {
    // without this, the keyEvent will bubble up and cause issues with typing in the search
    e.stopPropagation();
    const updatedQuery = e.target.value;
    setQueryString(updatedQuery);

    if (e.target.value === '') {
      queryFilterCallback(allOptions);
      return;
    }

    let newFilteredOptions = fuse.search(updatedQuery);

    // always want the persistent option to be the first option
    if (persistentOption && persistentOption.value) {
      newFilteredOptions = newFilteredOptions.filter(
        option => option.value !== persistentOption.value,
      );
      newFilteredOptions.unshift(persistentOption);
    }

    queryFilterCallback(newFilteredOptions);
  };


  return (
    <MenuItem
      className={classes.searchAndCustomContainer}
      id="searchContainer"
      button={false}
    >
      <TextField
        fullWidth
        id="searchInput"
        placeholder={label ? `Search ${label}` : 'Search options'}
        value={queryString}
        onChange={updateQueryAndFilterOptions}
        onKeyDown={e => e.stopPropagation()}
        disabled={isCustomInputOpen}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              id="searchInputAdornment"
            >
              <Search id="searchIcon" />
            </InputAdornment>
          ),
        }}
      />
    </MenuItem>
  );
};

const styles = theme => ({
  searchAndCustomContainer: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    padding: '12px 16px',
  },
});

ComplexMenuSearch.propTypes = {
  classes: PropTypes.shape(),
  allOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  queryFilterCallback: PropTypes.func.isRequired,
  label: PropTypes.string,
  isCustomInputOpen: PropTypes.bool,
  persistentOption: PropTypes.shape(),
};

ComplexMenuSearch.defaultProps = {
  classes: {},
  label: '',
  isCustomInputOpen: false,
  persistentOption: null,
};

export default withStyles(styles)(ComplexMenuSearch);
