import React, { Fragment, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import {
  Button,
  Divider,
  Grid,
  Hidden,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { AddCircleOutline, Delete } from '@material-ui/icons';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { forEach, size } from 'lodash';

import Field from './Field';
import { FIELD_TYPES } from '../constants';
import * as actions from './actions';
import * as selectors from './selectors';
import getMappedValuesByField from '../Hooks/getMappedValuesByField';
import * as errorMessages from '../config/errorMessages';

import PackagingGrid from '../Packaging/PackagingGrid';
import EquipmentPage from '../Components/EquipmentPage/EquipmentPage';
import ProductsGrid from '../Products/ProductsGrid';
import IconButton from '../Components/IconButton';

import CopyIcon from '../icons/CopyIcon';

const FieldTable = props => {
  const {
    addRow,
    classes,
    columns,
    hasExample,
    label,
    removeRow,
    rows,
    tableKey,
    updateField,
    setErrorForStep,
    clearErrorForStep,
    formStateForTable,
    removePackagingType,
  } = props;
  const addNewRow = useCallback(() => {
    let initialFields = {};

    if (tableKey === 'products') {
      initialFields = {
        value: '',
        productClass: '',
        uom: '',
        productPackagingTypes: [],
        tempId: uuid(),
      };
    } else {
      forEach(columns, column => {
        if (column.fieldKey) {
          initialFields = {
            ...initialFields,
            [column.fieldKey]: '',
          };
        }
      });
    }

    addRow(initialFields);
  }, [columns, addRow, tableKey]);

  const siteOptions = useSelector(selectors.getFacilitySiteOptions);
  const errorState = useSelector(selectors.getErrorState);
  const getErrorMessages = () => {
    const errorMapForStep = errorState[tableKey];
    return errorMapForStep
      && Object.keys(errorMapForStep).map(errorField => errorMapForStep[errorField]);
  };

  const mappedValuesByField = getMappedValuesByField(formStateForTable);

  const copyLast = useCallback(() => {
    let initialFields = {};
    const rowToCopy = rows && rows.length > 0 && rows[rows.length - 1];
    if (rowToCopy) {
      forEach(columns, column => {
        let initialValue = rowToCopy[column.fieldKey] || '';
        if (column.type === FIELD_TYPES.MULTI_SELECT) {
          initialValue = rowToCopy[column.fieldKey] || [];
        }
        if (column.fieldKey) {
          initialFields = {
            ...initialFields,
            [column.fieldKey]: initialValue,
          };
          if (mappedValuesByField[column.fieldKey] && mappedValuesByField[column.fieldKey].length) {
            const errorMessageMap = {
              name: tableKey === 'storageLocations' ? errorMessages.storageNameDuplicates : errorMessages.equipmentNameDuplicates,
              number: errorMessages.barrelNumberDuplicates,
              abbreviation: errorMessages.equipmentAbbrevDuplicates,
              value: errorMessages.productNameDuplicates,
              item: errorMessages.inventoryItemNameDuplicates,
              packagingTypes: errorMessages.packagingTypeDuplicates,
            };
            setErrorForStep(column.fieldKey, errorMessageMap[column.fieldKey]);
          } else {
            clearErrorForStep();
          }
        }
      });
    }
    addRow(initialFields);
  }, [
    columns,
    addRow,
    rows,
    clearErrorForStep,
    mappedValuesByField,
    setErrorForStep,
    tableKey,
  ]);

  const removeTableRow = index => () => {
    if (tableKey === 'packaging') {
      removePackagingType(index);
    } else {
      removeRow(index);
    }

    const errorForTable = errorState[tableKey];

    if (errorForTable) {
      forEach(columns, column => {
        const { fieldKey } = column;
        if (fieldKey && errorForTable[fieldKey] && mappedValuesByField[fieldKey]) {
          clearErrorForStep(fieldKey);
        }
      });
    }
  };

  useEffect(() => {
    if (!rows || (size(rows) === 0 && columns && size(columns) > 0)) {
      addNewRow();
    }
  }, [addNewRow, updateField, rows, columns]);

  /**
     * If no value exists for a field within a row within a table,
     * And that field has a default value specified in the schema,
     * Dispatch the update action to set the default value in form state
     */
  const sites = useSelector(selectors.getFacilitySiteOptions);

  const getFacilitySiteOptions = useCallback(() => {
    return sites && sites.map(site => ({
      label: site.siteName,
      value: site.siteId,
    }));
  }, [sites]);

  const defaultSiteValue = getFacilitySiteOptions()[0];
  useEffect(() => {
    const shouldPersistDefaultFields = rows
        && rows.length
        && columns
        && columns.length
        && formStateForTable;

    if (shouldPersistDefaultFields) {
      const columnsWithoutDefaultValues = columns.filter(
        column => column.fieldKey !== 'site' && column.fieldKey !== 'capacityFields',
      );
      formStateForTable.forEach(tableEntry => {
        // if row is untouched by the user it is considered incomplete
        const isIncompleteRow = columnsWithoutDefaultValues.every(
          column => !tableEntry[column.fieldKey],
        );
        if (isIncompleteRow) {
          rows.forEach(row => {
            columns.forEach(column => {
              const facilitySiteOptions = getFacilitySiteOptions();
              // checking if the siteValue passed matches available site options - if not pass new default
              const siteValueIsOutOfRange = facilitySiteOptions
                && !facilitySiteOptions.find(site => (
                  site.value === row[column.fieldKey]
                ));

              if (
                column.fieldKey !== 'site'
                && row[column.fieldKey] === ''
                && column.defaultValue
              ) {
                updateField(row.arrayIndex, column.fieldKey, column.defaultValue);
              } else if (
                column.fieldKey === 'site'
                && (row[column.fieldKey] === '' || siteValueIsOutOfRange)
                && defaultSiteValue.value
                && defaultSiteValue.label !== ''
              ) {
                updateField(row.arrayIndex, column.fieldKey, defaultSiteValue.value);
              }
            });
          });
        }
      });
    }
  }, [
    formStateForTable,
    tableKey,
    rows,
    columns,
    defaultSiteValue.label,
    defaultSiteValue.value,
    updateField,
    getFacilitySiteOptions,
  ]);


  const onFieldChange = (index, fieldKey) => event => {
    updateField(index, fieldKey, event.target.value);
  };

  if (!rows || rows.length < 1) return null;

  const errors = getErrorMessages();
  const hasErrorMessage = errors && Boolean(errors.length);

  if (tableKey === 'packaging') {
    return (
      <PackagingGrid
        hasExample={hasExample}
        columns={columns}
        rows={rows}
        tableKey={tableKey}
        onFieldChange={onFieldChange}
        hasErrorMessage={hasErrorMessage}
        errors={errors}
        addNewRow={addNewRow}
        label={label}
        copyLast={copyLast}
        removeTableRow={removeTableRow}
      />
    );
  }

  if (tableKey === 'equipment' || tableKey === 'barrels') {
    return (
      <EquipmentPage
        addNewRow={addNewRow}
        columns={columns}
        copyLast={copyLast}
        formStateForTable={formStateForTable}
        errors={errors}
        hasErrorMessage={hasErrorMessage}
        hasExample={hasExample}
        label={label}
        onFieldChange={onFieldChange}
        rows={rows}
        removeTableRow={removeTableRow}
        tableKey={tableKey}
      />
    );
  }
  if (tableKey === 'products') {
    return (
      <ProductsGrid
        hasExample={hasExample}
        columns={columns}
        rows={rows}
        tableKey={tableKey}
        onFieldChange={onFieldChange}
        errorState={errorState}
        mappedValuesByField={mappedValuesByField}
        hasErrorMessage={hasErrorMessage}
        errors={errors}
        clearErrorForStep={clearErrorForStep}
        addNewRow={addNewRow}
        label={label}
        copyLast={copyLast}
      />
    );
  }
  return (
    <>
      <Hidden smDown>
        <Table>
          <TableHead>
            <TableRow classes={{ root: classes.tableRowRoot }}>
              {columns && columns.map(column => (
                <TableCell
                  key={column.header}
                  classes={{
                    paddingNone: classes.tableCellAtMd,
                    head: classes.tableCellHead,
                  }}
                  padding="none"
                >
                  {column.header}
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {hasExample && columns && (
            <>
              <TableRow className={classes.exampleHeaderRow}>
                <TableCell
                  size="small"
                  colSpan={columns.length + 1}
                  className={`${classes.exampleHeaderCell} ${classes.tableCellAtMd}`}
                >
                  <Typography variant="caption">
                    Example
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow className={classes.example}>
                {columns.map(column => (
                  <TableCell
                    key={`example-${column.fieldKey}`}
                    className={`${classes.exampleCell} ${classes.tableCellAtMd}`}
                  >
                    {column.example}
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </>
            )}
            {rows.map(row => {
              return (
                <Fragment key={row.arrayIndex}>
                  <TableRow
                    key={row.arrayIndex}
                    style={props}
                  >
                    {columns && columns.map(column => {
                      const {
                        key: columnKey,
                        fieldKey,
                        options,
                        ...restColumn
                      } = column;

                      const getOptions = () => {
                        if (tableKey === 'storageLocations' && fieldKey === 'site') {
                          return siteOptions.map(site => ({
                            label: site.siteName,
                            value: site.siteId,
                          }));
                        }

                        return options;
                      };
                      return (
                        <Fragment key={fieldKey}>
                          <TableCell
                            key={fieldKey}
                            padding="none"
                            classes={{
                              paddingNone: classes.tableCellAtMd,
                            }}
                          >
                            <Field
                              arrayKey={tableKey}
                              arrayIndex={row.arrayIndex}
                              fieldKey={fieldKey}
                              onChange={onFieldChange(
                                row.arrayIndex,
                                column.fieldKey,
                              )}
                              options={getOptions()}
                              {...restColumn}
                            />
                          </TableCell>
                        </Fragment>
                      );
                    })}
                    <TableCell
                      align="right"
                      padding="none"
                    >
                      <IconButton
                        onClick={removeTableRow(
                          row.arrayIndex,
                        )}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      </Hidden>
      <Hidden mdUp>
        {hasExample && columns && (
        <Grid
          container
          spacing={1}
          className={classes.example}
        >
          <Grid
            item
            xs={12}
          >
            <Typography variant="caption">Example</Typography>
          </Grid>
          {columns.map(column => (
            <Grid
              item
              xs={6}
              key={`example-${column.fieldKey}`}
            >
              <TextField
                disabled
                label={column.header}
                value={column.example}
              />
            </Grid>
          ))}
        </Grid>
        )}
        {rows.map(row => (
          <Grid
            container
            spacing={1}
            style={props}
            key={row.arrayIndex}
          >
            {columns && columns.map(column => {
              const {
                key: columnKey,
                fieldKey,
                ...restColumn
              } = column;


              return (
                <Grid
                  item
                  xs={6}
                  key={fieldKey}
                >
                  <Field
                    label={column.header}
                    arrayKey={tableKey}
                    arrayIndex={row.arrayIndex}
                    fieldKey={fieldKey}
                    onChange={onFieldChange(
                      row.arrayIndex,
                      column.fieldKey,
                    )}
                    {...restColumn}
                  />
                </Grid>
              );
            })}
            <Grid
              item
              xs={12}
            >
              <Button
                variant="text"
                fullWidth
                onClick={removeTableRow(row.arrayIndex)}
              >
                <Delete className={classes.deleteIcon} />
                Remove
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Divider />
            </Grid>
          </Grid>
        ))}
      </Hidden>
      <Grid container>
        {hasErrorMessage && (
        <Grid
          container
          justify="center"
          className={classes.errorMessage}
        >
          <Grid item>
            {errors.map(msg => (
              <Typography
                color="error"
                key={msg}
              >
                {msg}
              </Typography>
            ))}
          </Grid>
        </Grid>
        )}
        <Grid
          item
          xs={12}
          className={classes.addRow}
        >
          <Button
            className={classes.addButton}
            onClick={addNewRow}
            disabled={hasErrorMessage}
          >
            <AddCircleOutline className={classes.addIcon} />
            Add {label}
          </Button>
          <Button
            className={classes.addButton}
            onClick={copyLast}
            disabled={hasErrorMessage}
          >
            <CopyIcon className={classes.copyIcon} />
            Copy Last
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const styles = theme => ({
  addRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: '#008EFF',
  },
  addButton: {
    textTransform: 'none',
    color: '#008EFF',
    marginRight: 25,
  },
  addIcon: {
    marginRight: '6px',
  },
  copyIcon: {
    height: '1em',
    width: '1em',
    fontSize: '1.5rem',
    marginRight: '6px',
  },
  deleteIcon: {
    marginRight: '6px',
  },
  example: {
    padding: '0.4rem',
    borderRadius: '4px',
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
  },
  exampleCell: {
    fontSize: '1rem',
  },
  exampleHeaderRow: {
    padding: '0.4rem',
    borderRadius: '4px',
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
    height: '1rem',
  },
  exampleHeaderCell: {
    border: 'none',
  },
  errorMessage: {
    margin: '1rem 0',
  },
  tableCellAtMd: {
    padding: '4px 12px',
  },
  tableCellHead: {
    color: theme.cidermaker_contrast_text,
    fontSize: '0.75rem',
    opacity: 0.54,
  },
  tableRowRoot: {
    height: 56,
  },
});

FieldTable.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  label: PropTypes.string,
  rows: PropTypes.arrayOf(PropTypes.shape({})),
  tableKey: PropTypes.string,
  addRow: PropTypes.func,
  removeRow: PropTypes.func,
  updateField: PropTypes.func,
  hasExample: PropTypes.bool,
  setErrorForStep: PropTypes.func,
  clearErrorForStep: PropTypes.func,
  formStateForTable: PropTypes.arrayOf(PropTypes.shape()),
  removePackagingType: PropTypes.func,
};

FieldTable.defaultProps = {
  classes: {},
  columns: undefined,
  label: '',
  rows: undefined,
  tableKey: '',
  addRow: undefined,
  removeRow: undefined,
  updateField: undefined,
  hasExample: false,
  setErrorForStep: undefined,
  clearErrorForStep: undefined,
  formStateForTable: [],
  removePackagingType: undefined,
};

const mapStateToProps = (state, ownProps) => {
  const { form } = state;

  const formStateForTable = form && ownProps.tableKey
    ? form[ownProps.tableKey]
    : null;

  const rows = form[ownProps.tableKey]
        && form[ownProps.tableKey].map((row, index) => {
          return { ...row, arrayIndex: index };
        });

  return {
    rows,
    formStateForTable,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateField: (index, fieldKey, value) => dispatch(
    actions.changeFieldArray(ownProps.tableKey, index, fieldKey, value),
  ),
  addRow: initialFields => dispatch(actions.addRowToArray(ownProps.tableKey, initialFields)),
  removeRow: index => dispatch(actions.removeRowFromArray(ownProps.tableKey, index)),
  removePackagingType: index => dispatch(actions.deletePackagingType(index)),
  setErrorForStep: (fieldKey, errorMessage) => dispatch(
    actions.setStepValidationError(
      ownProps.tableKey,
      fieldKey,
      errorMessage,
    ),
  ),
  clearErrorForStep: fieldKey => dispatch(
    actions.clearStepValidationError(
      ownProps.tableKey,
      fieldKey,
    ),
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(FieldTable));
