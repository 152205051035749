import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uuid from 'react-uuid';

import {
  Grid,
  Typography,
  Button,
  Hidden,
  withStyles,
} from '@material-ui/core';
import { Delete, AddCircleOutline } from '@material-ui/icons';
import { lighten } from '@material-ui/core/styles/colorManipulator';

import Field from '../Form/Field';

import * as actions from '../Form/actions';
import * as errorMessages from '../config/errorMessages';

import IconButton from '../Components/IconButton';
import ProductsPackagingTypes from './ProductsPackagingTypes';

import CopyIcon from '../icons/CopyIcon';

const ProductsGrid = ({
  classes,
  hasExample,
  columns,
  rows,
  tableKey,
  onFieldChange,
  hasErrorMessage,
  errors,
  addNewRow,
  removeTableRow,
  copyRow,
  availablePackagingTypes,
  setErrorForStep,
  errorState,
  mappedValuesByField,
  clearErrorForStep,
}) => {
  const [productsBeingEdited, setProductsBeingEdited] = useState([]);

  const removeProductRow = arrayIndex => {
    removeTableRow(arrayIndex);

    const errorForTable = errorState[tableKey];

    if (errorForTable) {
      columns.forEach(column => {
        const { fieldKey } = column;
        if (fieldKey && errorForTable[fieldKey] && mappedValuesByField[fieldKey]) {
          clearErrorForStep(fieldKey);
        }
      });
    }
  };

  const copyLast = () => {
    const rowToCopy = rows && rows.length > 0 && rows[rows.length - 1];
    let copiedRow = {};

    if (rowToCopy) {
      // set the initial fields of the new row
      copiedRow = {
        value: rowToCopy.value,
        productClass: rowToCopy.productClass,
        uom: rowToCopy.uom,
        productPackagingTypes: [],
        tempId: uuid(),
      };

      /**
             * if there is a value set for the product name, we can just go ahead and call this function
             * we know that there will need to be an error thrown since the new row and the row being copied
             * will have the same product name
             */
      if (copiedRow.value) {
        setErrorForStep('value', errorMessages.productNameDuplicates);
      }
    }

    copyRow(copiedRow);
  };

  const productNameColumn = columns[0];
  const productClassColumn = columns[1];
  const uomColumn = columns[2];
  const packagingExampleColumn = columns[3];

  const updateProductsBeingEdited = (productId, isBeingEdited) => {
    const updatedProductsBeingEdited = [...productsBeingEdited];
    const productIdx = updatedProductsBeingEdited.findIndex(product => product === productId);

    if (!isBeingEdited && productIdx > -1) {
      updatedProductsBeingEdited.splice(productIdx, 1);
    } else {
      updatedProductsBeingEdited.push(productId);
    }

    setProductsBeingEdited(updatedProductsBeingEdited);
  };

  return (
    <>
      {hasExample && columns
                && (
                <Grid
                  container
                  className={classes.example}
                >
                  <Grid
                    item
                    xs={12}
                    className={classes.exampleLabel}
                  >
                    <Typography variant="caption">Example</Typography>
                  </Grid>

                  <Grid
                    item
                    xs={5}
                    className={classes.gridItem}
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                      >
                        <Typography variant="caption">Product Name</Typography>
                        <Typography>{productNameColumn.example}</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                      >
                        <Typography variant="caption">Product Class</Typography>
                        <Typography>{productClassColumn.example}</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                      >
                        <Typography variant="caption">Unit of Measure</Typography>
                        <Typography>{uomColumn.example}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={6}
                  >
                    <Typography variant="caption">Packaging Types</Typography>
                    {
                      packagingExampleColumn.example.map(example => (
                        <Typography key={example}>{example}</Typography>
                      ))
                    }
                  </Grid>
                </Grid>
                )}

      {rows.map(row => {
        return (
          <Grid
            container
            key={row.arrayIndex}
            className={classes.productRow}
            spacing={2}
          >
            <Grid
              item
              xs={12}
              md={5}
            >
              <Grid
                container
                spacing={1}
              >
                <Grid
                  item
                  xs={12}
                >
                  <Field
                    arrayKey={tableKey}
                    arrayIndex={row.arrayIndex}
                    fieldKey={productNameColumn.fieldKey}
                    onChange={onFieldChange(
                      row.arrayIndex,
                      productNameColumn.fieldKey,
                    )}
                    {...productNameColumn}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                >
                  <Field
                    arrayKey={tableKey}
                    arrayIndex={row.arrayIndex}
                    fieldKey={productClassColumn.fieldKey}
                    onChange={onFieldChange(
                      row.arrayIndex,
                      productClassColumn.fieldKey,
                    )}
                    {...productClassColumn}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                >
                  <Field
                    arrayKey={tableKey}
                    arrayIndex={row.arrayIndex}
                    fieldKey={uomColumn.fieldKey}
                    onChange={onFieldChange(
                      row.arrayIndex,
                      uomColumn.fieldKey,
                    )}
                    {...uomColumn}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
            >
              <ProductsPackagingTypes
                arrayKey={tableKey}
                arrayIndex={row.arrayIndex}
                fieldKey={packagingExampleColumn.fieldKey}
                availablePackagingTypes={availablePackagingTypes}
                editModeCallback={updateProductsBeingEdited}
                {...packagingExampleColumn}
              />
            </Grid>

            <Hidden smDown>
              <Grid
                item
                xs={1}
                className={classes.deleteIconContainer}
              >
                <IconButton
                  onClick={() => removeProductRow(row.arrayIndex)}
                  disabled={productsBeingEdited.includes(row.tempId)}
                >
                  <Delete />
                </IconButton>
              </Grid>
            </Hidden>

            <Hidden mdUp>
              <Grid
                item
                xs={12}
              >
                <Button
                  variant="text"
                  fullWidth
                  onClick={() => removeProductRow(row.arrayIndex)}
                >
                  <Delete className={classes.deleteIcon} />
                  Remove
                </Button>
              </Grid>
            </Hidden>
          </Grid>
        );
      })}

      <Grid container>
        {hasErrorMessage && (
        <Grid
          container
          justify="center"
          className={classes.errorMessage}
        >
          <Grid item>
            {errors.map(msg => (
              <Typography
                color="error"
                key={msg}
              >
                {msg}
              </Typography>
            ))}
          </Grid>
        </Grid>
        )}

        <Grid
          item
          xs={12}
          className={classes.addRow}
        >
          <Button
            className={classes.addButton}
            onClick={addNewRow}
            disabled={hasErrorMessage}
          >
            <AddCircleOutline className={classes.addIcon} />
            Add Product
          </Button>
          <Button
            className={classes.addButton}
            onClick={copyLast}
            disabled={hasErrorMessage}
          >
            <CopyIcon className={classes.copyIcon} />
            Copy Last
          </Button>
        </Grid>
      </Grid>
    </>
  );
};


const styles = theme => ({
  addRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: '#008EFF',
  },
  addButton: {
    textTransform: 'none',
    color: '#008EFF',
    marginRight: 25,
  },
  addIcon: {
    marginRight: '6px',
  },
  copyIcon: {
    height: '1em',
    width: '1em',
    fontSize: '1.5rem',
    marginRight: '6px',
  },
  deleteIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 5,
  },
  example: {
    padding: '0.4rem',
    borderRadius: '4px',
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
    width: 'calc(100% + 8px)',
    paddingLeft: 0,
  },
  exampleColumn: {
    marginTop: 15,
  },
  gridItem: {
    padding: '0 8px',
  },
  productRow: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    marginLeft: 0,
    marginBottom: 5,
  },
  packagingComponentsContainer: {
    [theme.breakpoints.up('sm')]: {
      marginTop: 15,
    },
  },
  divider: {
    marginLeft: 8,
    width: 'calc(100% - 8px)',
    marginBottom: 8,
    marginTop: 8,
  },
  exampleLabel: {
    padding: '0 8px 8px',
  },
});

ProductsGrid.propTypes = {
  classes: PropTypes.shape(),
  hasExample: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape(),
  ),
  rows: PropTypes.arrayOf(
    PropTypes.shape(),
  ),
  tableKey: PropTypes.string,
  onFieldChange: PropTypes.func,
  removeTableRow: PropTypes.func,
  hasErrorMessage: PropTypes.bool,
  errors: PropTypes.arrayOf(
    PropTypes.string,
  ),
  addNewRow: PropTypes.func,
  label: PropTypes.string,
  copyLast: PropTypes.func,
  copyRow: PropTypes.func,
  availablePackagingTypes: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.array,
    value: PropTypes.string,
    components: PropTypes.array,
  })),
  setErrorForStep: PropTypes.func,
  errorState: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.shape({}),
  ])),
  mappedValuesByField: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.shape({}),
  ])),
  clearErrorForStep: PropTypes.func,
};

ProductsGrid.defaultProps = {
  classes: {},
  hasExample: false,
  columns: [],
  rows: [],
  tableKey: '',
  onFieldChange: () => {},
  removeTableRow: () => {},
  hasErrorMessage: false,
  errors: [],
  addNewRow: () => {},
  label: '',
  copyLast: () => {},
  copyRow: () => {},
  availablePackagingTypes: [],
  setErrorForStep: () => {},
  errorState: {},
  mappedValuesByField: [],
  clearErrorForStep: () => {},
};

const mapStateToProps = state => {
  const { form } = state;

  let availablePackagingTypes = [];

  if (form.packaging && form.packaging.length) {
    availablePackagingTypes = form.packaging.reduce((acc, packaging) => {
      if (packaging.packagingTypes) {
        acc.push({
          label: packaging.packagingTypes,
          value: packaging.packagingTypeRowguid,
          components: packaging.packagingComponents,
        });
      }

      return acc;
    }, []);
  }

  return {
    availablePackagingTypes,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  removeTableRow: index => dispatch(actions.removeRowFromArray(ownProps.tableKey, index)),
  copyRow: initialFields => dispatch(actions.addRowToArray(ownProps.tableKey, initialFields)),
  setErrorForStep: (fieldKey, errorMessage) => dispatch(
    actions.setStepValidationError(
      ownProps.tableKey,
      fieldKey,
      errorMessage,
    ),
  ),
});

const styledProductsGrid = withStyles(styles)(ProductsGrid);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(styledProductsGrid);
