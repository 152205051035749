export const CHANGE_FIELD = 'CHANGE_FIELD';
export const CHANGE_FIELD_ARRAY = 'CHANGE_FIELD_ARRAY';
export const ADD_FIELD_ARRAY = 'ADD_FIELD_ARRAY';
export const REMOVE_FIELD_ARRAY = 'REMOVE_FIELD_ARRAY';
export const UPDATE_ROW_IN_ARRAY = 'UPDATE_ROW_IN_ARRAY';
export const UPDATE_ENTIRE_ARRAY = 'UPDATE_ENTIRE_ARRAY';
export const UPDATE_PRODUCTS = 'UPDATE_PRODUCTS';
export const UPDATE_PACKAGING_TYPES = 'UPDATE_PACKAGING_TYPES';
export const UPDATE_PACKAGING_COMPONENTS = 'UPDATE_PACKAGING_COMPONENTS';
export const DELETE_PACKAGING_TYPE = 'DELETE_PACKAGING_TYPE';
export const UPDATE_BRAND = 'UPDATE_BRAND';
export const EXCEL_STARTED = 'EXCEL_STARTED';
export const EXCEL_COMPLETED = 'EXCEL_COMPLETED';
export const LOGO_UPDATED = 'LOGO_UPDATED';
export const FLOORPLAN_UPDATED = 'FLOORPLAN_UPDATED';
export const SENDING_EMAIL = 'SENDING_EMAIL';
export const EMAIL_SENT = 'EMAIL_SENT';

export const SET_VALIDATION_ERROR = 'SET_VALIDATION_ERROR';
export const CLEAR_VALIDATION_ERROR = 'CLEAR_VALIDATION_ERROR';
export const TOGGLE_REQUIRED_ERRORS = 'TOGGLE_REQUIRED_ERROS';
