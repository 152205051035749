import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Grid,
  Snackbar,
  SnackbarContent,
  withStyles,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Warning from '@material-ui/icons/Warning';

import Dropzone from '../Dropzone';
import { uploadLogo, uploadFloorplan } from '../../Form/actions';
import { fileIsTooLargeToUpload } from '../../config/errorMessages';

import StepHeading from './StepHeading';
import DropzoneInstructions from '../DropzoneInstructions';
import InlineErrorMessage from '../InlineErrorMessage';


const FacilityInformationUploader = ({
  classes,
  type,
  fileName,
  handleLogo,
  handleFloorplan,
  showRequiredFieldErrors,
}) => {
  const step = {
    heading: type === 'logo' ? 'Logo *' : 'Floorplan *',
  };

  const [fileError, setFileError] = useState(false);

  const handleRejectedFile = isFileTooLarge => {
    if (isFileTooLarge) {
      setFileError(true);
    }
  };

  const closeSnackBar = () => {
    setFileError(false);
  };

  return (
    <Grid
      item
      xs={12}
      md={6}
    >
      <StepHeading step={step} />
      <Grid container>
        <Grid
          item
          xs={12}
          className={classes.errorContainer}
        >
          {!fileName && showRequiredFieldErrors
            && (
              <InlineErrorMessage variant="body2">
                <span className={classes.typeLabel}>{type}</span> is required
              </InlineErrorMessage>
            )}
        </Grid>
      </Grid>
      <Dropzone
        hasError={Boolean(showRequiredFieldErrors && !fileName) || fileError}
        instructions={(
          <DropzoneInstructions
            type={type}
            acceptedFileTypes={type === 'logo' ? 'jpeg or png' : 'jpeg, png, or pdf'}
            fileName={fileName}
          />
        )}
        accept={type === 'logo' ? 'image/jpeg, image/png' : 'application/pdf, image/jpeg, image/png'}
        handleFile={type === 'logo' ? handleLogo : handleFloorplan}
        handleRejectedFile={handleRejectedFile}
        minSize={0}
        maxSize={20000000}
      />

      {fileError && (
      <Snackbar
        open={fileError}
        onClose={closeSnackBar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <SnackbarContent
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              onClick={closeSnackBar}
              classes={{ root: classes.snackbarIconRoot }}
            >
              <CloseIcon />
            </IconButton>,
          ]}
          className={classes.snackbarContent}
          classes={{
            message: classes.snackbarContentMessage,
            action: classes.snackbarContentAction,
          }}
          message={(
            <>
              <IconButton
                key="warning"
                classes={{ root: classes.snackbarIconRoot }}
              >
                <Warning />
              </IconButton>
              {fileIsTooLargeToUpload}
            </>
          )}
        />
      </Snackbar>
      )}
    </Grid>
  );
};

const styles = {
  errorContainer: {
    height: 28,
    paddingBottom: 8,
  },
  typeLabel: {
    textTransform: 'capitalize',
  },
  snackbarContent: {
    backgroundColor: '#f16649',
  },
  snackbarContentMessage: {
    padding: 0,
    color: '#ffffff',
    fontFamily: 'Europa,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    letterSpacing: '.02rem',
  },
  snackbarIconRoot: {
    color: '#ffffff',
  },
};

FacilityInformationUploader.propTypes = {
  type: PropTypes.string,
  handleLogo: PropTypes.func.isRequired,
  handleFloorplan: PropTypes.func,
  classes: PropTypes.objectOf(PropTypes.string),
  fileName: PropTypes.string,
  showRequiredFieldErrors: PropTypes.bool,
};

FacilityInformationUploader.defaultProps = {
  type: '',
  handleFloorplan: () => { },
  classes: {},
  fileName: '',
  showRequiredFieldErrors: false,
};

const mapStateToProps = (state, ownProps) => {
  const { logo, floorplan, showRequiredFieldErrors } = state;
  const { type } = ownProps;

  const fileName = type === 'logo' ? logo.fileName : floorplan.fileName;

  return {
    fileName,
    showRequiredFieldErrors,
  };
};

const mapDispatchToProps = dispatch => ({
  handleLogo: logoFile => dispatch(uploadLogo(logoFile)),
  handleFloorplan: floorplanFile => dispatch(uploadFloorplan(floorplanFile)),
});

const styledFacilityInformationUploader = withStyles(styles)(FacilityInformationUploader);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(styledFacilityInformationUploader);
