import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import { connect, useSelector } from 'react-redux';

import {
  Grid, Typography, ListItem, withStyles,
} from '@material-ui/core';
import { AddCircleOutline, Delete } from '@material-ui/icons';

import * as actions from '../../Form/actions';
import * as selectors from '../../Form/selectors';
import getMappedValuesByField from '../../Hooks/getMappedValuesByField';

import Field from '../../Form/Field';
import IconButton from '../IconButton';
import InlineErrorMessage from '../InlineErrorMessage';
import StepHeading from './StepHeading';

const FacilityInformationSites = ({
  classes,
  table,
  tableKey,
  // props from redux
  rows,
  formStateForTable,
  showRequiredFieldErrors,
  updateField,
  addRow,
  removeRow,
  clearErrorForStep,
}) => {
  const { columns } = table;

  // ERROR HANDLING
  const errorState = useSelector(selectors.getErrorState);

  const getErrorMessages = () => {
    const errorMapForStep = errorState[tableKey];
    return errorMapForStep && Object.keys(errorMapForStep).map(errorField => (
      errorMapForStep[errorField]
    ));
  };

  const mappedValuesByField = getMappedValuesByField(formStateForTable);

  const errors = getErrorMessages();
  const hasErrorMessage = errors && Boolean(errors.length);

  // ADDING NEW SITE
  const addNewSite = useCallback(() => {
    const initialFields = {
      siteName: '',
      siteId: uuid(),
    };

    addRow(initialFields);
  }, [addRow]);

  useEffect(() => {
    if (!rows || (rows.length === 0 && columns && columns.length > 0)) {
      addNewSite();
    }
  }, [addNewSite, updateField, rows, columns]);

  // UPDATING SITE
  const onFieldChange = (index, fieldKey) => event => (
    updateField(index, fieldKey, event.target.value)
  );

  // DELETING SITE
  const removeSite = idx => {
    // want to prevent the row from being deleted if it's the only row
    // we do this because if there is only one row in a table, without a select field,
    // for some reason it appears to 'jump' or 'flicker' on the screen
    if (idx !== 0 || (idx === 0 && rows.length > 1)) {
      removeRow(idx);
    } else {
      updateField(0, 'siteName', '');
      updateField(0, 'siteId', uuid());
    }

    const errorForTable = errorState[tableKey];

    if (errorForTable) {
      columns.forEach(column => {
        const { fieldKey } = column;
        if (fieldKey && errorForTable[fieldKey] && mappedValuesByField[fieldKey]) {
          clearErrorForStep(fieldKey);
        }
      });
    }
  };

  const getRequiredSiteError = () => {
    if (!showRequiredFieldErrors) return null;

    const completedSite = rows.find(row => row.siteName);
    if (completedSite) return null;

    return true;
  };

  return (
    <Grid
      container
      spacing={1}
    >
      <StepHeading step={table} />

      {rows && rows.map(row => (
        <Grid
          item
          xs={12}
          key={row.arrayIndex}
        >

          {columns && columns.map(column => {
            const {
              key: columnKey,
              fieldKey,
              ...restColumn
            } = column;

            if (column.type === 'hidden') {
              return null;
            }

            return (
              <Grid
                container
                key={column.fieldKey}
                spacing={1}
                alignItems="center"
              >
                <Grid
                  item
                  xs={11}
                >
                  <Field
                    arrayKey={tableKey}
                    arrayIndex={row.arrayIndex}
                    fieldKey={column.fieldKey}
                    onChange={onFieldChange(row.arrayIndex, column.fieldKey)}
                    error={Boolean(getRequiredSiteError())}
                    {...restColumn}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  className={classes.deleteGridItem}
                >
                  <IconButton onClick={() => removeSite(row.arrayIndex)}>
                    <Delete />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}

        </Grid>
      ))}

      {getRequiredSiteError()
      && (
        <Grid
          item
          xs={12}
        >
          <InlineErrorMessage>
            At least one site name is required
          </InlineErrorMessage>
        </Grid>
      )}

      {hasErrorMessage && (
        <Grid
          item
          xs={12}
          className={classes.errorMessage}
        >
          {errors.map(msg => (
            <Typography
              color="error"
              key={msg}
            >
              {msg}
            </Typography>
          ))}
        </Grid>
      )}

      <Grid
        item
        xs={12}
      >
        <ListItem
          button
          className={classes.addListItem}
          disabled={hasErrorMessage}
          onClick={addNewSite}
        >
          <AddCircleOutline className={classes.addIcon} />
          <Typography className={classes.addText}>Add Site</Typography>
        </ListItem>
      </Grid>


    </Grid>
  );
};

FacilityInformationSites.propTypes = {
  tableKey: PropTypes.string,
  table: PropTypes.shape(),
  rows: PropTypes.arrayOf(PropTypes.shape()),
  formStateForTable: PropTypes.arrayOf(PropTypes.shape()),
  addRow: PropTypes.func.isRequired,
  updateField: PropTypes.func.isRequired,
  removeRow: PropTypes.func.isRequired,
  clearErrorForStep: PropTypes.func.isRequired,
  showRequiredFieldErrors: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string),
};

FacilityInformationSites.defaultProps = {
  tableKey: '',
  table: {},
  rows: [],
  formStateForTable: [],
  showRequiredFieldErrors: false,
  classes: {},
};

const styles = {
  deleteGridItem: {
    display: 'flex',
    justifyContent: 'center',
  },
  addListItem: {
    padding: '8px 0',
  },
  addIcon: {
    marginRight: 8,
    color: '#008EFF',
  },
  addText: {
    color: '#008EFF',
  },
  errorMessage: {
    margin: '8px 0',
    display: 'flex',
    justifyContent: 'center',
  },
};

const mapStateToProps = state => {
  const { form, showRequiredFieldErrors } = state;

  const formStateForTable = form ? form.sites : null;

  const rows = form.sites
    && form.sites.map((row, idx) => {
      return { ...row, arrayIndex: idx };
    });

  return {
    rows,
    formStateForTable,
    showRequiredFieldErrors,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  addRow: initialFields => dispatch(actions.addRowToArray(ownProps.tableKey, initialFields)),
  updateField: (index, fieldKey, value) => dispatch(
    actions.changeFieldArray(ownProps.tableKey, index, fieldKey, value),
  ),
  removeRow: index => dispatch(actions.removeRowFromArray(ownProps.tableKey, index)),
  clearErrorForStep: fieldKey => dispatch(
    actions.clearStepValidationError(
      ownProps.tableKey,
      fieldKey,
    ),
  ),
});

const styledFacilityInformationSite = withStyles(styles)(FacilityInformationSites);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(styledFacilityInformationSite);
