const winePackagingTypes = [
  {
    label: 'Single - 750mL - Bottle',
    rowguid: 'B7B4D0F8-1BF3-E311-A60A-D4AE5266E0C4',
    category: 'BOTTLE',
    defaultComponents: [
      {
        label: '750mL Bottle',
        defaultQuantity: 1,
        tempId: '863bbc4-2bef-00c6-ce55-727eaa88ca3c',
      },
      {
        label: 'Cork',
        defaultQuantity: 1,
        tempId: 'c2ff826-c58e-ec3b-1e43-fa8fc46c8a5',
      },
    ],
  },
  {
    label: 'Case - 6x - 750mL - Bottle',
    rowguid: '8C9DA262-AD3A-E411-9329-D4AE5266E0C4',
    category: 'BOTTLE',
    defaultComponents: [
      {
        label: '750mL Bottle',
        defaultQuantity: 6,
        tempId: '863bbc4-2bef-00c6-ce55-727eaa88ca3c',
      },
      {
        label: 'Cork',
        defaultQuantity: 6,
        tempId: 'c2ff826-c58e-ec3b-1e43-fa8fc46c8a5',
      },
    ],
  },
  {
    label: 'Case - 12x - 750mL - Bottle',
    rowguid: '4ABD3254-1CF3-E311-A60A-D4AE5266E0C4',
    category: 'BOTTLE',
    defaultComponents: [
      {
        label: '750mL Bottle',
        defaultQuantity: 12,
        tempId: '863bbc4-2bef-00c6-ce55-727eaa88ca3c',
      },
      {
        label: 'Cork',
        defaultQuantity: 12,
        tempId: 'c2ff826-c58e-ec3b-1e43-fa8fc46c8a5',
      },
    ],
  },
  {
    label: 'Single - 375mL - Bottle',
    rowguid: '660188C1-A028-E411-818F-D4AE5266E0C4',
    category: 'BOTTLE',
    defaultComponents: [
      {
        label: '375mL Bottle',
        defaultQuantity: 1,
        tempId: '34e0546-fa8-1c6e-ecfb-b8023daabe',
      },
      {
        label: 'Cork',
        defaultQuantity: 1,
        tempId: 'c2ff826-c58e-ec3b-1e43-fa8fc46c8a5',
      },
    ],
  },
  {
    label: 'Case - 6x - 375mL - Bottle',
    rowguid: '55CD16A9-AD3A-E411-9329-D4AE5266E0C4',
    category: 'BOTTLE',
    defaultComponents: [
      {
        label: '375mL Bottle',
        defaultQuantity: 6,
        tempId: '34e0546-fa8-1c6e-ecfb-b8023daabe',
      },
      {
        label: 'Cork',
        defaultQuantity: 6,
        tempId: 'c2ff826-c58e-ec3b-1e43-fa8fc46c8a5',
      },
    ],
  },
  {
    label: 'Case - 12x - 375mL - Bottle',
    rowguid: '261A6F86-AD3A-E411-9329-D4AE5266E0C4',
    category: 'BOTTLE',
    defaultComponents: [
      {
        label: '375mL Bottle',
        defaultQuantity: 12,
        tempId: '34e0546-fa8-1c6e-ecfb-b8023daabe',
      },
      {
        label: 'Cork',
        defaultQuantity: 12,
        tempId: 'c2ff826-c58e-ec3b-1e43-fa8fc46c8a5',
      },
    ],
  },
  {
    label: 'Single - 1L - Bottle',
    rowguid: '2131657E-66EF-E411-94E5-E4C8A6DECC7D',
    category: 'BOTTLE',
    defaultComponents: [
      {
        label: '1L Bottle',
        defaultQuantity: 1,
        tempId: '8bf07c-5e2e-e8c7-638c-515ccf7c7042',
      },
      {
        label: 'Cork',
        defaultQuantity: 1,
        tempId: 'c2ff826-c58e-ec3b-1e43-fa8fc46c8a5',
      },
    ],
  },
  {
    label: 'Case - 6x - 1L - Bottle',
    rowguid: '7AEA9445-B06E-EA11-A9CF-0AC016C0647C',
    category: 'BOTTLE',
    defaultComponents: [
      {
        label: '1L Bottle',
        defaultQuantity: 6,
        tempId: '8bf07c-5e2e-e8c7-638c-515ccf7c7042',
      },
      {
        label: 'Cork',
        defaultQuantity: 6,
        tempId: 'c2ff826-c58e-ec3b-1e43-fa8fc46c8a5',
      },
    ],
  },
  {
    label: 'Single - 12oz - Can',
    rowguid: 'FF596B40-C432-E411-A676-D4AE5266E0C4',
    category: 'CAN',
    defaultComponents: [
      {
        label: '12oz Can',
        defaultQuantity: 1,
        tempId: 'ce23d7-2f53-8b2-fd7f-7bf35dc82c1',
      },
      {
        label: 'Can Lid',
        defaultQuantity: 1,
        tempId: '6320d1a-25f-f6fa-26c3-8f31517164b4',
      },
    ],
  },
  {
    label: 'Case - 4x - 12oz - Can',
    rowguid: 'D518F76B-2FC8-E411-94E5-E4C8A6DECC7D',
    category: 'CAN',
    defaultComponents: [
      {
        label: '12oz Can',
        defaultQuantity: 4,
        tempId: 'ce23d7-2f53-8b2-fd7f-7bf35dc82c1',
      },
      {
        label: 'Can Lid',
        defaultQuantity: 4,
        tempId: '6320d1a-25f-f6fa-26c3-8f31517164b4',
      },
    ],
  },
  {
    label: 'Case - 6x - 12oz - Can',
    rowguid: 'A2790448-6537-E411-9329-D4AE5266E0C4',
    category: 'CAN',
    defaultComponents: [
      {
        label: '12oz Can',
        defaultQuantity: 6,
        tempId: 'ce23d7-2f53-8b2-fd7f-7bf35dc82c1',
      },
      {
        label: 'Can Lid',
        defaultQuantity: 6,
        tempId: '6320d1a-25f-f6fa-26c3-8f31517164b4',
      },
    ],
  },
  {
    label: 'Case - 4x6 - 12oz - Can',
    rowguid: '71AB6CA7-949D-E411-94E5-E4C8A6DECC7D',
    category: 'CAN',
    defaultComponents: [
      {
        label: '12oz Can',
        defaultQuantity: 24,
        tempId: 'ce23d7-2f53-8b2-fd7f-7bf35dc82c1',
      },
      {
        label: 'Can Lid',
        defaultQuantity: 24,
        tempId: '6320d1a-25f-f6fa-26c3-8f31517164b4',
      },
    ],
  },
  {
    label: 'Case - 6x4 - 12oz - Can',
    rowguid: 'D64A5E91-2FC8-E411-94E5-E4C8A6DECC7D',
    category: 'CAN',
    defaultComponents: [
      {
        label: '12oz Can',
        defaultQuantity: 24,
        tempId: 'ce23d7-2f53-8b2-fd7f-7bf35dc82c1',
      },
      {
        label: 'Can Lid',
        defaultQuantity: 24,
        tempId: '6320d1a-25f-f6fa-26c3-8f31517164b4',
      },
    ],
  },
  {
    label: 'Keg - 1/2bbl',
    rowguid: 'E7521AF6-9487-E311-AB85-D4AE5266E0C4',
    category: 'KEG',
    defaultComponents: [
      {
        label: 'Keg Shell - 1/2bbl',
        defaultQuantity: 1,
        tempId: '427ec6-40c-e4a-fb27-224acf5fcad',
      },
      {
        label: 'Keg Cap',
        defaultQuantity: 1,
        tempId: '7b143ec-5ad-2dd-172a-c5106d0f6d4',
      },
      {
        label: 'Keg Collar',
        defaultQuantity: 1,
        tempId: '128352-36b5-386-c82c-bfdfadc55c',
      },
    ],
  },
  {
    label: 'Keg - 1/6bbl',
    rowguid: 'EA2A8914-4ED1-E311-A60A-D4AE5266E0C4',
    category: 'KEG',
    defaultComponents: [
      {
        label: 'Keg Shell - 1/6bbl',
        defaultQuantity: 1,
        tempId: 'be38b32-bdd-816e-f4ff-8220ff53ad01',
      },
      {
        label: 'Keg Cap',
        defaultQuantity: 1,
        tempId: '7b143ec-5ad-2dd-172a-c5106d0f6d4',
      },
      {
        label: 'Keg Collar',
        defaultQuantity: 1,
        tempId: '128352-36b5-386-c82c-bfdfadc55c',
      },
    ],
  },
  {
    label: 'Keg - 20L',
    rowguid: '3DC0E3DE-B780-E411-BF10-EFBA34DDFD35',
    category: 'KEG',
    defaultComponents: [
      {
        label: 'Keg Shell - 20L',
        defaultQuantity: 1,
        tempId: 'b688c80-47dd-7b60-2bd-08534d4cb2',
      },
      {
        label: 'Keg Cap',
        defaultQuantity: 1,
        tempId: '7b143ec-5ad-2dd-172a-c5106d0f6d4',
      },
      {
        label: 'Keg Collar',
        defaultQuantity: 1,
        tempId: '128352-36b5-386-c82c-bfdfadc55c',
      },
    ],
  },
  {
    label: 'Keg - 30L',
    rowguid: '7D921DD4-B780-E411-BF10-EFBA34DDFD35',
    category: 'KEG',
    defaultComponents: [
      {
        label: 'Keg Shell - 30L',
        defaultQuantity: 1,
        tempId: 'd534776-ddbd-e78-7ce-fa647ec5c42f',
      },
      {
        label: 'Keg Cap',
        defaultQuantity: 1,
        tempId: '7b143ec-5ad-2dd-172a-c5106d0f6d4',
      },
      {
        label: 'Keg Collar',
        defaultQuantity: 1,
        tempId: '128352-36b5-386-c82c-bfdfadc55c',
      },
    ],
  },
  {
    label: 'Keg - 50L',
    rowguid: '40C6171A-1B22-E411-818F-D4AE5266E0C4',
    category: 'KEG',
    defaultComponents: [
      {
        label: 'Keg Shell - 50L',
        defaultQuantity: 1,
        tempId: 'a760635-bf41-17ea-eab3-8e8504be27',
      },
      {
        label: 'Keg Cap',
        defaultQuantity: 1,
        tempId: '7b143ec-5ad-2dd-172a-c5106d0f6d4',
      },
      {
        label: 'Keg Collar',
        defaultQuantity: 1,
        tempId: '128352-36b5-386-c82c-bfdfadc55c',
      },
    ],
  },
];

export default winePackagingTypes;
