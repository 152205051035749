import React from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core';

import StepHeading from './StepHeading';

import Field from '../../Form/Field';
import FieldTable from '../../Form/FieldTable';

const EkosStep = ({ classes, step }) => {
  const { fields, tables, stepKey } = step;

  return (
    <Grid
      container
      justify="center"
    >
      <StepHeading step={step} />
      <Grid
        item
        xs={12}
      >
        {fields && fields.length > 0 && (
        <Grid
          container
          justify="center"
        >
          {/* TODO: if time allows, add animations back in here */}
          {fields.map(field => (
            <Grid
              item
              xs={12}
              key={field.fieldKey}
            >
              <Field
                {...field}
                stepKey={stepKey}
              />
            </Grid>
          ))}
        </Grid>
        )}
      </Grid>
      <Grid
        item
        xs={12}
      >
        {tables
          && tables.length > 0
          && tables.map((table, idx) => (
            <Grid
              container
              justify="center"
              key={table.tableKey}
            >
              {tables.length > 1 && (
              <Grid
                item
                xs={12}
                className={classes.tableLabel}
              >
                <Typography variant="h6">
                  {table.label}
                </Typography>
              </Grid>
              )}
              <Grid
                item
                xs={12}
                key={table.tableKey}
              >
                <FieldTable
                  stepKey={stepKey}
                  {...table}
                />
              </Grid>
              {tables.length > 1 && idx !== tables.length - 1 && (
              <Grid
                item
                xs={12}
              >
                <Divider className={classes.divider} />
              </Grid>
              )}
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
};

const styles = {
  divider: {
    marginTop: '2rem',
  },
  tableLabel: {
    marginTop: '2rem',
  },
};

EkosStep.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  step: PropTypes.shape().isRequired,
};

EkosStep.defaultProps = {
  classes: {},
};

export default withStyles(styles)(EkosStep);
